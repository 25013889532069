<template>
  <!-- In case we need scroll here, just add class 'max-h-[30em] studio-scrollbar-4' to the div below -->
  <div class="wrapper h-auto">
    <sortable
      :key="sortableKey"
      :list="inputLinks"
      itemKey="order"
      :options="options"
      @update="onUpdateOrderFiles"
    >
      <template #item="{ element, index }">
        <div class="draggable">
          <input-link
            :key="index"
            :isDisabled="isDisabled"
            :linkTitle="element.title"
            :linkUrl="element.url"
            :isDefault="element.isDefault"
            :linkId="element.linkId"
            :linkIndex="index"
            :errorUrlMsg="element.errorUrlMsg"
            :errorTitleMsg="element.errorTitleMsg"
            :linkTitleMaxLen="linkTitleMaxLen"
            class="mt-8"
            keepAlive
            @removeLink="onRemoveLink"
            @changeUrlError="onChangeUrlError"
            @changeTitleError="onChangeTitleError"
          />
        </div>
      </template>
    </sortable>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import type { SortableEvent, SortableOptions } from 'sortablejs';
import type { AutoScrollOptions } from 'sortablejs/plugins';
import { Sortable } from 'sortablejs-vue3';
import { useFieldValue, useSetFieldValue } from 'vee-validate';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import InputLink from '@/components/product-page/input-link.vue';
import { CS_STOVE_LINK } from '@/constants/common.const';
import { LanguageCode } from '@/enums/language-code.enum';
import { useUserStore } from '@/stores/user.store';
import type { ProductLinkType, ProductPageLanguage } from '@/types/product-page.type';
import { generateUUID } from '@/utils/uuid.util';

interface NewLink {
  title: string;
  url: string;
  linkId: string;
}

const props = defineProps<{
  modelValue?: string[];
  numberOfLinks: number;
  maxLinkNumber?: number;
  isDisabled?: boolean;
}>();

const { t } = useI18n();

const userStore = useUserStore();
const { selectedGroupInfo } = storeToRefs(userStore);

const inputLinks = useFieldValue<ProductLinkType[]>('links');
const setInputLinks = useSetFieldValue<ProductLinkType[]>('links');
let sortableKey = 0;

const translatedContents =
  useFieldValue<ProductPageLanguage<ProductLinkType[]>[]>('translatedContents');
const setTranslatedContent =
  useSetFieldValue<ProductPageLanguage<ProductLinkType[]>[]>('translatedContents');

const linkTitleMaxLen = selectedGroupInfo.value?.languageCd === LanguageCode.En ? 35 : 15;

const options = computed<SortableOptions | AutoScrollOptions>(() => {
  return {
    draggable: '.draggable',
    animation: 150,
    easing: 'cubic-bezier(1, 0, 0, 1)',
    ghostClass: 'ghost',
    dragClass: 'drag',
    scroll: true,
    forceFallback: true,
    bubbleScroll: true,
    swapThreshold: 0.65,
    direction: 'vertical',
    disabled: props.isDisabled
  };
});

const maxLinks = computed(() => props.maxLinkNumber || 5);

const addMoreLink = () => {
  if (inputLinks.value.length >= maxLinks.value) {
    return;
  }
  const links = inputLinks.value.map((link: ProductLinkType) => {
    return {
      ...link,
      order: (link.order || 1) + 1
    };
  });
  links.unshift({
    linkId: generateUUID(),
    title: '',
    url: '',
    isDisabled: props.isDisabled,
    order: 1
  });
  setInputLinks(links);
};

const removeLinkInTranslatedContents = (linkId: string) => {
  setTranslatedContent(
    translatedContents.value.map((content: ProductPageLanguage<ProductLinkType[]>) => {
      return {
        ...content,
        links: content.links.filter((link: ProductLinkType) => link.linkId !== linkId)
      };
    })
  );
};

const changeOrderLinksInTranslatedContents = (oldIndex: number, newIndex: number) => {
  const updatedTranslatedContents = translatedContents.value.map(
    (content: ProductPageLanguage<ProductLinkType[]>) => {
      const temp = content.links[oldIndex];
      content.links[oldIndex] = content.links[newIndex];
      content.links[newIndex] = temp;
      content.links.forEach((link: ProductLinkType, index: number) => {
        link.order = index + 1;
      });
      return content;
    }
  );
  setTranslatedContent(updatedTranslatedContents);
};

const onRemoveLink = (linkId: string) => {
  removeLinkInTranslatedContents(linkId);
  const links = inputLinks.value.filter((link: ProductLinkType) => link.linkId !== linkId);
  links.forEach((link: ProductLinkType, index: number) => {
    link.order = index + 1;
  });
  setInputLinks(links);
};

const onUpdateOrderFiles = async (event: SortableEvent) => {
  const oldIndex = event.oldIndex ?? 0;
  const newIndex = event.newIndex ?? 0;
  if (oldIndex === newIndex) {
    return;
  }
  const tempArr = [...inputLinks.value];
  const temp = tempArr[oldIndex];
  tempArr[oldIndex] = tempArr[newIndex];
  tempArr[newIndex] = temp;
  tempArr.forEach((link: ProductLinkType, index: number) => {
    link.order = index + 1;
  });
  sortableKey += 1;
  setInputLinks(tempArr);
  changeOrderLinksInTranslatedContents(oldIndex, newIndex);
};

const onChangeUrlError = (error: string, linkId: string) => {
  const link = inputLinks.value.find((link: ProductLinkType) => link.linkId === linkId);
  if (!link) {
    return;
  }

  link.errorUrlMsg = error;
};

const onChangeTitleError = (error: string, linkId: string) => {
  const link = inputLinks.value.find((link: ProductLinkType) => link.linkId === linkId);
  if (!link) {
    return;
  }

  link.errorTitleMsg = error;
};

const checkIfLinkIsAdded = (
  content: ProductPageLanguage<ProductLinkType[]>,
  newElement: NewLink
) => {
  const addedLink = content.links.find(
    (link: ProductLinkType) => link.linkId === newElement.linkId
  );

  if (addedLink) {
    addedLink.url = newElement.url;
    return content;
  }
};

const updatedTranslatedContents = (newElement: NewLink, newLink?: ProductLinkType) => {
  // Add new link to translated contents
  const updatedTranslatedContents = translatedContents.value.map(
    (content: ProductPageLanguage<ProductLinkType[]>) => {
      const isAdded = checkIfLinkIsAdded(content, newElement);
      if (isAdded) {
        return isAdded;
      }
      const links = [
        {
          ...newElement,
          title: content.default ? newElement.title : '',
          order: Number(content.links.length + 1)
        },
        ...content.links
      ];
      links.forEach((link: ProductLinkType, index: number) => {
        link.order = index + 1;
      });
      return {
        ...content,
        links
      };
    }
  );
  // Remove the error link if it exists
  if (newLink?.errorUrlMsg) {
    updatedTranslatedContents.forEach((content: ProductPageLanguage<ProductLinkType[]>) => {
      content.links = content.links.filter(
        (link: ProductLinkType) => link.linkId !== newLink?.linkId
      );
    });
  }

  setTranslatedContent(updatedTranslatedContents);
};

const getAlreadyAddedLink = (newElement: NewLink) => {
  const alreadyAddedLink = translatedContents.value.some(
    (content: ProductPageLanguage<ProductLinkType[]>) => {
      return content.links.some((link: ProductLinkType) => link.linkId === newElement.linkId);
    }
  );

  return alreadyAddedLink;
};

const getNewLinkInList = (newElement: NewLink) => {
  const newLink = inputLinks.value.find(
    (link: ProductLinkType) => link.linkId === newElement.linkId
  );

  return newLink;
};

const getValuesToCheck = () => {
  const notDefaultLanguage = translatedContents.value.filter(
    (content: ProductPageLanguage<ProductLinkType[]>) => content.default === false
  );

  const isTranslated = notDefaultLanguage.some(
    (content: ProductPageLanguage<ProductLinkType[]>) => {
      return content.links.some(
        (link: ProductLinkType) =>
          link.title !== '' &&
          link.url !== CS_STOVE_LINK &&
          link.title !== t('studio.prj_prod.this_prod.edit_additional_links_stove_cs')
      );
    }
  );

  const isFirstTimeRender = notDefaultLanguage.every(
    (content: ProductPageLanguage<ProductLinkType[]>) => {
      return (
        content.links.length === 1 &&
        content.links.every(
          (link: ProductLinkType) => link.title === '' && link.url === CS_STOVE_LINK
        )
      );
    }
  );

  return { isTranslated, isFirstTimeRender };
};

const getValuesToCheckFirstTimeRender = () => {
  const defaultTranslated = translatedContents.value.find(
    (content: ProductPageLanguage<ProductLinkType[]>) => content.default
  );

  // Only the default link is added
  const isDefaultLink =
    inputLinks.value.length === 1 &&
    inputLinks.value[0].title === t('studio.prj_prod.this_prod.edit_additional_links_stove_cs') &&
    inputLinks.value[0].url === CS_STOVE_LINK;

  // Only the default link is added to the translated contents
  const isDefaultTranslatedLink = defaultTranslated?.links.length === 1;

  return { isDefaultLink, isDefaultTranslatedLink };
};

watch(
  () =>
    inputLinks.value.map((link: ProductLinkType) => ({
      title: link.title,
      url: link.url,
      linkId: link.linkId
    })),
  (newLinks: NewLink[]) => {
    const isNewLinksAvailable = newLinks && newLinks.length > 0;
    const newElement = newLinks[0];
    if (!newElement || !translatedContents.value) {
      return;
    }
    if (isNewLinksAvailable) {
      const alreadyAddedLink = getAlreadyAddedLink(newElement);
      const newLink = getNewLinkInList(newElement);

      // If the link is already added or has an error (not a valid URL) do not add it to the translated contents
      if (alreadyAddedLink || newLink?.errorUrlMsg) {
        return;
      }

      // The links are already translated
      const { isTranslated, isFirstTimeRender } = getValuesToCheck();
      if (isTranslated) {
        updatedTranslatedContents(newElement, newLink);

        return;
      }

      // First time render, no links are added/translated
      const { isDefaultLink, isDefaultTranslatedLink } = getValuesToCheckFirstTimeRender();
      if (isFirstTimeRender && isDefaultLink && isDefaultTranslatedLink) {
        return;
      }

      updatedTranslatedContents(newElement);
    }
  },
  { deep: true }
);

defineExpose({
  addMoreLink,
  inputLinks
});
</script>

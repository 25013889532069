<template>
  <div
    class="studio-page-editor relative shrink-0 bg-background-variant-1"
    :class="isOpenedEditor ? 'w-[96rem]' : 'w-[42rem]'"
  >
    <button
      type="button"
      class="absolute top-20 left-[-5.2rem] inline-flex h-32 w-32 rounded-full bg-[#E7EEFF]"
      @click="isOpenedEditor = !isOpenedEditor"
    >
      <s-icon
        v-if="isOpenedEditor"
        size="xl"
        icon="ic-v2-control-top-line"
        class="inline-flex h-full w-full rotate-90 items-center justify-center text-on-surface-elevation-2"
      />
      <s-tooltip
        v-else
        arrow
        :content="`<p class='text-center'>${$t('studio.prj_prod.edit_preview_toggle_msg')}</p>`"
        :duration="0"
        useFlip
        flipOnUpdate
        placement="bottom-end"
        trigger="mouseenter focus"
        :theme="'studio-tooltip'"
        :zIndex="2500"
        :allowHTML="true"
        :offset="[0, 8]"
        class="inline-flex h-full w-full"
      >
        <template #target>
          <s-icon
            size="xl"
            icon="ic-v2-control-top-line"
            class="inline-flex h-full w-full -rotate-90 items-center justify-center text-on-surface-elevation-2"
          />
        </template>
      </s-tooltip>
    </button>

    <div
      id="product-page-editor"
      class="studio-scrollbar-4 absolute inset-0 flex min-h-full flex-col py-20 pl-20"
    >
      <div class="flex items-center gap-16 px-[1rem]">
        <h3 class="text-3xl font-bold leading-2xl text-on-surface-elevation-1">
          {{ $t('studio.prj_prod.this_prod.edit_sidebar_title') }}
        </h3>
        <s-switch
          id="chk-required"
          v-model="showOnlyRequiredFields"
          size="sm"
          :labelText="$t('studio.prj_prod.this_prod.edit_sidebar_msg1')"
          class="ml-auto inline-flex shrink-0"
          isLabelVisible
        />
      </div>

      <div class="mt-20 flex flex-col gap-16">
        <product-page-editor-box
          :productPageEditorTitle="$t('studio.prj_prod.this_prod.edit_display_prod_pg')"
        >
          <product-page-exposure
            :isDisabled="isDisabled"
            @onTabChange="$emit('onTabChange', $event)"
          />
        </product-page-editor-box>

        <product-page-editor-box
          :productPageEditorTitle="$t('studio.prj_prod.this_prod.edit_gamepreview_title')"
        >
          <product-page-files :isDisabled="isDisabled" />
        </product-page-editor-box>

        <product-page-editor-box
          :productPageEditorTitle="$t('studio.prj_prod.this_prod.edit_texts')"
        >
          <product-page-text :isDisabled="isDisabled" />
        </product-page-editor-box>

        <product-page-editor-box
          :productPageEditorTitle="$t('studio.prj_prod.this_prod.edit_additional_info_title')"
        >
          <product-page-additional-info :isDisabled="isDisabled" />
        </product-page-editor-box>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';

import ProductPageAdditionalInfo from '@/components/product-page/product-page-additional-info.vue';
import ProductPageEditorBox from '@/components/product-page/product-page-editor-box.vue';
import ProductPageExposure from '@/components/product-page/product-page-exposure.vue';
import ProductPageFiles from '@/components/product-page/product-page-files.vue';
import ProductPageText from '@/components/product-page/product-page-text.vue';
import { useProductPageStore } from '@/stores/product-page.store';

defineProps<{
  isDisabled: boolean;
}>();

defineEmits<{
  onTabChange: [v: string];
}>();

const productPageStore = useProductPageStore();
const { showOnlyRequiredFields, isOpenedEditor } = storeToRefs(productPageStore);
</script>

<template>
  <div class="rounded-[1rem] px-[1.6rem] py-[.6rem] text-sm w-max" :class="tagClass">
    <slot>
      {{ text }}
    </slot>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

type TagVariant =
  | 'default'
  | 'black'
  | 'success'
  | 'secondary'
  | 'ghost'
  | 'outline'
  | 'blue-outline';
type TagSize = 'default' | 'sm' | 'lg';

const props = withDefaults(defineProps<{ text?: string; variant?: TagVariant; size?: TagSize }>(), {
  variant: 'default',
  size: 'default',
  text: ''
});

const tagClass = computed(() => {
  let style = 'text-[#818181] bg-white border-solid border-[#E5E5E5] border-[1px]';

  switch (props.variant) {
    case 'black':
      style = 'text-white bg-[#454545]';
      break;
    case 'success':
      style = 'text-white bg-[#0085FF]';
      break;
    case 'secondary':
      style = 'bg-gray100';
      break;
    case 'ghost':
      style = '!border-none !bg-transparent !text-[#818181]';
      break;
    case 'blue-outline':
      style = 'text-[#0085FF] bg-white border-solid border-[#0085FF] border-[1px]';
      break;
  }

  switch (props.size) {
    case 'sm':
      style += ' !text-[.8rem] px-[.4rem] py-[.4rem]';
      break;
    case 'lg':
      style += ' !text-[1.8rem]';
      break;
  }

  return style;
});
</script>

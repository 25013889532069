<template>
  <div>
    <s-dialog :open="true" size="md" to="stds-dialog-multilingual-settings">
      <s-dialog-overlay />
      <s-dialog-panel class="!w-[93.2rem]">
        <st-dialog-header @clickClose="onClose">
          <span class="!font-bold">{{ $t('studio.ai_translation.title1_settings') }}</span>
        </st-dialog-header>
        <s-dialog-content>
          <s-dialog-content-body>
            <div v-show="!isLoading">
              <p class="text-sm leading-md text-on-surface-elevation-3 -mt-4">
                <safe-html :html="$t('studio.ai_translation.guide1')" />
              </p>
              <div class="flex items-end mt-12 pb-20">
                <div class="w-[calc(50%+2.4rem)]">
                  <div class="flex items-center pr-48">
                    <p class="text-md font-medium leading-sm text-on-surface-elevation-2">
                      {{ $t('studio.ai_translation.base_lang') }}
                    </p>
                    <span
                      class="ml-8 rounded-full bg-[var(--stds-glob-color-gray60)] px-[.6rem] h-[2.2rem] flex items-center text-2xs font-medium leading-xs text-gray500"
                    >{{ $t(`${getTabName(defaultProductLanguageItem.langCode)}`) }}</span>
                    <s-tooltip
                      arrow
                      :content="$t('studio.group.home.language_tool_tip')"
                      :duration="0"
                      :distance="4"
                      useFlip
                      flipOnUpdate
                      placement="bottom"
                      trigger="mouseenter focus"
                      :theme="'studio-tooltip'"
                      :zIndex="2501"
                      :allowHTML="true"
                    >
                      <template #target>
                        <s-icon
                          icon="ic-v2-state-info-circle-line"
                          size="xl"
                          class="text-on-surface-elevation-4 flex ml-4"
                        />
                      </template>
                    </s-tooltip>
                  </div>

                  <div class="mt-8 flex flex-col gap-20 mb-8">
                    <div class="flex flex-col gap-4">
                      <p class="text-md font-bold leading-lg text-on-surface-elevation-2">
                        {{ $t('studio.ai_translation.title5_tagline') }}
                      </p>
                      <div class="flex items-start">
                        <div class="flex-1">
                          <st-textarea
                            v-model="defaultProductLanguageItem.oneLineIntroduction"
                            containerClass="h-[11rem] rounded-xl"
                            disabled
                          />
                        </div>
                        <s-tooltip
                          arrow
                          :content="$t('studio.ai_translation.badge')"
                          :duration="0"
                          :distance="4"
                          useFlip
                          flipOnUpdate
                          placement="top"
                          trigger="mouseenter focus"
                          :theme="'studio-tooltip'"
                          :zIndex="2501"
                          :allowHTML="true"
                          :class="{ 'pointer-events-none': isDisabled }"
                        >
                          <template #target>
                            <button
                              type="button"
                              class="mx-4 flex h-44 w-44 shrink-0 items-center justify-center rounded-full hover:bg-interaction-hover text-on-surface-elevation-1 disabled:bg-disabled-variant-3 disabled:text-disabled-variant-1"
                              :disabled="isDisabled"
                              @click="
                                translateSelectedField(
                                  'oneLineIntroduction',
                                  defaultProductLanguageItem.oneLineIntroduction,
                                  TranslationFormatType.TEXT
                                )
                              "
                            >
                              <s-icon size="3xl" icon="ic-v2-control-long-arrow-right-line" />
                            </button>
                          </template>
                        </s-tooltip>
                      </div>
                    </div>
                    <div v-if="productDescription" class="flex flex-col gap-4">
                      <p class="text-md font-bold leading-lg text-on-surface-elevation-2">
                        {{ $t('studio.prj_prod.this_prod.edit_summary_title') }}
                      </p>
                      <div class="flex items-start">
                        <div class="flex-1 max-w-[40.4rem]">
                          <text-editor
                            v-model="productDescription"
                            :config="showEditorConfig"
                            class="product-description h-[52rem]"
                            disabled
                          />
                        </div>
                        <s-tooltip
                          arrow
                          :content="$t('studio.ai_translation.badge')"
                          :duration="0"
                          :distance="4"
                          useFlip
                          flipOnUpdate
                          placement="top"
                          trigger="mouseenter focus"
                          :theme="'studio-tooltip'"
                          :zIndex="2501"
                          :allowHTML="true"
                          :class="{ 'pointer-events-none': isDisabled }"
                        >
                          <template #target>
                            <button
                              type="button"
                              class="mx-4 flex h-44 w-44 shrink-0 items-center justify-center rounded-full hover:bg-interaction-hover text-on-surface-elevation-1 disabled:bg-disabled-variant-3 disabled:text-disabled-variant-1"
                              :disabled="isDisabled"
                              @click="
                                translateSelectedField(
                                  'productDescriptions',
                                  defaultProductLanguageItem.productDescriptions,
                                  TranslationFormatType.HTML
                                )
                              "
                            >
                              <s-icon size="3xl" icon="ic-v2-control-long-arrow-right-line" />
                            </button>
                          </template>
                        </s-tooltip>
                      </div>
                    </div>
                    <!-- <div v-if="defaultProductLanguageItem.subtitle" class="flex flex-col gap-4">
                      <p class="text-md font-bold leading-lg text-on-surface-elevation-2">
                        {{ $t('studio.prj_prod.this_prod.edit_texts_subtitle_title') }}
                      </p>
                      <div class="flex items-start">
                        <div class="flex-1">
                          <input-text :modelValue="defaultProductLanguageItem.subtitle" disabled />
                        </div>
                        <s-tooltip
                          arrow
                          :content="$t('studio.ai_translation.badge')"
                          :duration="0"
                          :distance="4"
                          useFlip
                          flipOnUpdate
                          placement="top"
                          trigger="mouseenter focus"
                          :theme="'studio-tooltip'"
                          :zIndex="2501"
                          :allowHTML="true"
                          :class="{ 'pointer-events-none': isDisabled }"
                        >
                          <template #target>
                            <button
                              type="button"
                              class="mx-4 flex h-44 w-44 shrink-0 items-center justify-center rounded-full hover:bg-interaction-hover text-on-surface-elevation-1 disabled:bg-disabled-variant-3 disabled:text-disabled-variant-1"
                              :disabled="isDisabled"
                              @click="
                                translateSelectedField(
                                  'subtitle',
                                  defaultProductLanguageItem.subtitle,
                                  TranslationFormatType.TEXT
                                )
                              "
                            >
                              <s-icon size="3xl" icon="ic-v2-control-long-arrow-right-line" />
                            </button>
                          </template>
                        </s-tooltip>
                      </div>
                    </div> -->
                    <div v-if="links && links.length" class="flex flex-col gap-4">
                      <p class="text-md font-bold leading-lg text-on-surface-elevation-2">
                        {{ $t('studio.ai_translation.title8_link') }}
                      </p>
                      <div class="flex flex-col gap-20">
                        <div
                          v-for="(_, index) in defaultProductLanguageItem.links"
                          :key="`link-${index}`"
                          class="flex items-start"
                        >
                          <div class="flex-1">
                            <input-text
                              :modelValue="defaultProductLanguageItem.links[index].title"
                              disabled
                            />
                          </div>
                          <s-tooltip
                            arrow
                            :content="$t('studio.ai_translation.badge')"
                            :duration="0"
                            :distance="4"
                            useFlip
                            flipOnUpdate
                            placement="top"
                            trigger="mouseenter focus"
                            :theme="'studio-tooltip'"
                            :zIndex="2501"
                            :allowHTML="true"
                            :class="{ 'pointer-events-none': isDisabled }"
                          >
                            <template #target>
                              <button
                                type="button"
                                class="mx-4 flex h-44 w-44 shrink-0 items-center justify-center rounded-full hover:bg-interaction-hover text-on-surface-elevation-1 disabled:bg-disabled-variant-3 disabled:text-disabled-variant-1"
                                :disabled="isDisabled"
                                @click="
                                  translateSelectedField(
                                    `links`,
                                    defaultProductLanguageItem.links[index].title,
                                    TranslationFormatType.TEXT,
                                    index
                                  )
                                "
                              >
                                <s-icon size="3xl" icon="ic-v2-control-long-arrow-right-line" />
                              </button>
                            </template>
                          </s-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-[calc(50%-2.4rem)]">
                  <s-tabs
                    v-model="selectedTab"
                    variant="line-fixed"
                    size="sm"
                    class="stove-studio-tab-line"
                    :defaultIndex="0"
                  >
                    <s-tab-list>
                      <s-tab-item
                        v-for="(lang, index) in productLanguages"
                        :key="lang.langCode"
                        :value="lang.langCode"
                        @click="() => onSelectLanguageTab(index)"
                      >
                        <span class="break-words"> {{ $t(getTabName(lang.langCode)) }}</span>
                        <s-icon
                          v-if="showErrorTab(index)"
                          size="xl"
                          icon="ic-v2-state-warning-circle-fill"
                          class="text-error ml-2"
                        />
                      </s-tab-item>
                    </s-tab-list>
                    <s-tab-panels>
                      <s-tab-panel
                        v-for="(lang, index) in productLanguages"
                        :key="lang.langCode"
                        :value="lang.langCode"
                      >
                        <div class="mt-36 flex flex-col gap-20 mb-4">
                          <div class="w-full relative">
                            <st-textarea
                              v-model="productLanguages[index].oneLineIntroduction"
                              :name="`productLanguages[${index}].oneLineIntroduction`"
                              maxLength="700"
                              :disabled="isDisabled"
                              variant="outline"
                              containerClass="h-[11rem] rounded-xl"
                              :rules="{
                                regex: {
                                  regex: REGEX_PRODUCT_NAME,
                                  message: $t('studio.prj_prod.this_prod.home_product_setting_basic_msg4_invalid')
                                },
                                max_length: {
                                  length: 700,
                                  message: $t('studio.common.def_key.exceed', { length: 700 })
                                },
                                required: $t('studio.prj_prod.this_prod.edit_texts_headline_alert1')
                              }"
                              :absoluteErrorMsg="true"
                            />
                          </div>
                          <di
                            v-if="productDescription"
                            class="w-full"
                            :class="{ 'mt-28': isDisabled }"
                          >
                            <div v-if="!isDisabled" class="mb-4 text-right">
                              <s-button
                                variant="outline"
                                icon="ic-v2-media-av-fullscreen-fill"
                                size="xs"
                                :isDisabled="isCodeView"
                                @click="handleFullScreen"
                              >
                                {{
                                  $t(
                                    'studio.prj_prod_mngmt.prod_home.create_term.btn_input_fullscr'
                                  )
                                }}
                              </s-button>
                            </div>
                            <text-editor
                              v-model="productLanguages[index].productDescriptions"
                              :name="`productLanguages[${index}].productDescriptions`"
                              :toolbarButtons="TOOLBAR_BUTTONS_FROALA"
                              :config="editorConfig"
                              :charCounterMax="PRODUCT_DESC_WORD_COUNT_LIMIT"
                              class="h-[52rem]"
                              :disabled="isDisabled"
                              :rules="{
                                max_char_count: {
                                  length: PRODUCT_DESC_WORD_COUNT_LIMIT,
                                  charCount: productDescriptionCharCount,
                                  message: $t('studio.common.def_key.exceed', {
                                    length: PRODUCT_DESC_WORD_COUNT_LIMIT
                                  })
                                }
                              }"
                              :absoluteErrorMsg="true"
                              @onClickBtnHtmlCodeBlock="isCodeView = $event"
                              @wordCount="onProductDescriptionCharCount"
                            />
                          </di>
                          <!-- <div v-if="defaultProductLanguageItem.subtitle" class="mt-28 w-full">
                            <input-text
                              v-model="productLanguages[index].subtitle"
                              :name="`productLanguages[${index}].subtitle`"
                              :count="0"
                              :disabled="isDisabled"
                              maxLength="30"
                              countable
                              :rules="{
                                max_length: 30
                              }"
                              :absoluteErrorMsg="true"
                            />
                          </div> -->
                          <div
                            v-if="
                              defaultProductLanguageItem.links &&
                                defaultProductLanguageItem.links.length
                            "
                            class="mt-28 w-full"
                          >
                            <div class="flex flex-col gap-20 mb-[0.4rem]">
                              <div
                                v-for="(link, linkIdx) in lang.links"
                                :key="`linkTranslated-${linkIdx}`"
                              >
                                <input-text
                                  v-model="link.title"
                                  :disabled="isDisabled"
                                  :name="`productLanguages[${index}].links[${linkIdx}].title`"
                                  maxLength="35"
                                  countable
                                  :rules="{
                                    max_length: 35
                                  }"
                                  :absoluteErrorMsg="true"
                                  :smallSpace="true"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </s-tab-panel>
                    </s-tab-panels>
                  </s-tabs>
                </div>
              </div>
            </div>
            <div
              v-show="isLoading"
              class="flex flex-col justify-center items-center gap-20 pb-24"
              :style="{ height: loadingHeight }"
            >
              <s-circular-progress size="lg" />
              <p class="text-center text-lg font-medium leading-lg text-on-surface-elevation-3">
                <safe-html :html="$t('studio.ai_translation.progress_wait_msg')" />
              </p>
            </div>
          </s-dialog-content-body>
        </s-dialog-content>
        <s-dialog-footer>
          <s-button
            variant="outline"
            icon="ic-v2-communication-translate-line"
            :isDisabled="isDisabled"
            @click="translateAllField"
          >
            {{ $t('studio.ai_translation.all_btn') }}
          </s-button>
          <s-button :isDisabled="isDisabled" @click="onSubmit">
            {{ $t('studio.common.popup_case_save_btn') }}
          </s-button>
        </s-dialog-footer>
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target name="stds-dialog-multilingual-settings" />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useFieldValue, useForm } from 'vee-validate';
import { computed, nextTick, onMounted, ref, shallowRef, toRaw, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import { translateApi } from '@/apis/translation.api';
import SafeHtml from '@/components/common/safe-html.vue';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import FullScreen from '@/components/early-access/fullscreen.vue';
import InputText from '@/components/validation/input-text.vue';
import TextEditor from '@/components/validation/text-editor.vue';
import StTextarea from '@/components/validation/textarea.vue';
import { showConfirm, showDialog } from '@/composables/useDialog';
import { TRANSLATE_LANGUAGES } from '@/constants/common.const';
import { TOOLBAR_BUTTONS_FROALA } from '@/constants/froala.const';
import { REGEX_PRODUCT_NAME } from '@/constants/regex.const';
import { TranslationFormatType } from '@/enums/common.enum';
import { useProductPageStore } from '@/stores/product-page.store';
import type { LanguageModel } from '@/types/common/common.type';
import type { ProductLinkType, ProductPageLanguage } from '@/types/product-page.type';
import type { Translated } from '@/types/translation/translation.response';
import { getHtmlTextByCompare } from '@/utils/string.util';

const props = defineProps<{
  languages: ProductPageLanguage<ProductLinkType[]>[];
  isDisabled?: boolean;
  subtitle?: string;
  links?: ProductLinkType[];
  translatedContents?: ProductPageLanguage<ProductLinkType[]>[];
}>();

const emit = defineEmits<{
  close: [languages?: ProductPageLanguage<ProductLinkType[]>[]];
}>();

const { t } = useI18n();

const productPageStore = useProductPageStore();
const { productDescription } = storeToRefs(productPageStore);

const { languages, translatedContents } = toRefs(props);

const fullLanguages: LanguageModel[] = TRANSLATE_LANGUAGES;

const PRODUCT_DESC_WORD_COUNT_LIMIT = 70000;
const productDescriptionCharCount = ref<number>(0);

const editorConfig = {
  attribution: false,
  charCounterCount: true,
  wordCounterCount: false,
  placeholderText: ''
};

const showEditorConfig = {
  attribution: false,
  height: '52rem'
};

const isCodeView = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const isClickedSave = ref<boolean>(false);
const currentSelectedLangIndex = ref<number>(0);

const defaultProductLanguageItem = ref<ProductPageLanguage<ProductLinkType[]>>(
  languages.value.find((lang: ProductPageLanguage<ProductLinkType[]>) => lang.default) || {
    langCode: 'ko',
    default: true,
    oneLineIntroduction: '',
    productDescriptions: '',
    links: [
      {
        title: '',
        url: '',
        linkId: ''
      }
    ],
    subtitle: ''
  }
);

const onProductDescriptionCharCount = (count: number) => {
  productDescriptionCharCount.value = count;
};

const createSettingLanguages = (): ProductPageLanguage<ProductLinkType[]>[] => {
  const otherLangs = fullLanguages
    .filter((lang: LanguageModel) => lang.langCode !== defaultProductLanguageItem.value.langCode)
    .map((lang: LanguageModel, index: number) => {
      const language = languages.value.find(
        (product: ProductPageLanguage<ProductLinkType[]>) => product.langCode === lang.langCode
      ) || {
        langCode: lang.langCode,
        default: false,
        oneLineIntroduction: '',
        productDescriptions: '',
        links: [
          {
            title: defaultProductLanguageItem.value.links[index].title,
            url: defaultProductLanguageItem.value.links[index].url,
            linkId: defaultProductLanguageItem.value.links[index].linkId
          }
        ],
        subtitle: ''
      };

      return {
        ...language
      };
    });

  return otherLangs;
};

const { handleSubmit, setValues, errors, resetForm } = useForm<{
  productLanguages: ProductPageLanguage<ProductLinkType[]>[];
}>({
  initialValues: {
    productLanguages: translatedContents.value
      ? translatedContents.value.filter(
        (t: ProductPageLanguage<ProductLinkType[]>) =>
          t.langCode !== defaultProductLanguageItem.value.langCode
      )
      : createSettingLanguages()
  }
});

const tabErrorList = computed(() => {
  return Object.keys(errors.value);
});

const showErrorTab = (index: number) => {
  return tabErrorList.value.some((tab: string) => tab.includes(`productLanguages[${index}]`));
};

const productLanguages =
  useFieldValue<ProductPageLanguage<ProductLinkType[]>[]>('productLanguages');

// Languages that are not the default language
const otherLanguages = computed(() => {
  return fullLanguages.filter(
    (lang: LanguageModel) => lang.langCode !== defaultProductLanguageItem.value.langCode
  );
});

const selectedTab = ref<string>(otherLanguages.value[0].langCode);

const translateSelectedField = async (
  key: keyof ProductPageLanguage<ProductLinkType[]>,
  text: string,
  formatType: TranslationFormatType.HTML | TranslationFormatType.TEXT,
  keyIndex?: number
) => {
  const result = await translate(
    {
      [key]: text
    },
    [selectedTab.value],
    {
      [key]: formatType
    }
  );
  if (!result) {
    return;
  }

  const index = productLanguages.value?.findIndex(
    (lang: ProductPageLanguage<ProductLinkType[]>) => lang.langCode === selectedTab.value
  );

  const translatedText = result.translated[0]?.translatedContents[key];

  const updateProductLanguages = productLanguages.value.map(
    (lang: ProductPageLanguage<ProductLinkType[]>, i: number) => {
      if (index === i) {
        // Links are an array, so we need to update the correct index
        if (Array.isArray(lang[key]) && keyIndex !== undefined) {
          (lang[key][keyIndex] as ProductLinkType).title = translatedText;
        } else {
          lang[key] = translatedText;
        }
      }
      return lang;
    }
  );

  if (!isClickedSave.value) {
    resetForm({
      values: {
        productLanguages: updateProductLanguages
      }
    });
  } else {
    setValues({ productLanguages: updateProductLanguages });
  }
};

const translate = async (
  contents: Record<string, string | boolean | string[]>,
  targetLangs: string[],
  formats: {
    [key: string]: TranslationFormatType.HTML | TranslationFormatType.TEXT;
  }
) => {
  isLoading.value = true;
  const result = await translateApi({
    sourceLang: defaultProductLanguageItem.value.langCode,
    targetLangs,
    contents,
    formats
  });
  isLoading.value = false;

  return result;
};

const hasLanguageAlreadyTranslated = (): boolean => {
  const len = productLanguages.value.length;
  for (let i = 0; i < len; i++) {
    if (productLanguages.value[i].oneLineIntroduction) {
      return true;
    }
  }
  return false;
};

const translateAllField = async () => {
  const hasTranslatedLanguage = hasLanguageAlreadyTranslated();
  if (hasTranslatedLanguage) {
    const confirm = await showConfirm({
      content: t('studio.common.popup_case_ai_overwrite'),
      confirmLabel: t('studio.common.popup_case_translation_in_progress_btn'),
      cancelLabel: t('studio.common.popup_case_cancel_btn'),
      cancelVariant: 'outline'
    });
    if (!confirm) {
      return;
    }
  }

  const keys = Object.keys(defaultProductLanguageItem.value).filter(
    (key: string) => key !== 'langCode' && key !== 'default'
  );
  const contents = keys.reduce((acc: Record<string, string | boolean | string[]>, key: string) => {
    if (Array.isArray(defaultProductLanguageItem.value[key])) {
      (defaultProductLanguageItem.value[key] as ProductLinkType[]).forEach(
        (item: ProductLinkType, index: number) => {
          acc[`${key}[${index}]`] = item.title;
        }
      );
    } else {
      acc[key] = defaultProductLanguageItem.value[key];
    }
    return acc;
  }, {});

  const formats = Object.keys(contents).reduce(
    (acc: Record<string, TranslationFormatType.HTML | TranslationFormatType.TEXT>, key: string) => {
      if (key === 'productDescriptions') {
        acc[key] = TranslationFormatType.HTML;

        return acc;
      }

      acc[key] = TranslationFormatType.TEXT;
      return acc;
    },
    {}
  );

  const targetLangs = productLanguages.value.map(
    (lang: ProductPageLanguage<ProductLinkType[]>) => lang.langCode
  );
  const result = await translate(contents, targetLangs, formats);
  if (!result) {
    return;
  }

  const translatedLanguages = productLanguages.value.map(
    (lang: ProductPageLanguage<ProductLinkType[]>) => {
      const translatedKeys: Record<string, string | string[] | ProductLinkType[]> = {
        ...result.translated.find((item: Translated) => item.lang === lang.langCode)
          ?.translatedContents
      };
      let newKeys = Object.keys(translatedKeys);
      if (newKeys) {
        Object.keys(translatedKeys)
          .filter((key: string) => key.includes('[') && key.includes(']'))
          .forEach((key: string) => {
            newKeys = Object.keys(translatedKeys);
            const [field, index]: string[] = key.split('[');
            const fieldIndex = Number(index.replace(']', ''));
            if (!newKeys.includes(field)) {
              translatedKeys[field] = [];
            }
            if (Array.isArray(translatedKeys[field])) {
              if (Array.isArray(lang[field])) {
                (lang[field] as ProductLinkType[])[fieldIndex].title = translatedKeys[
                  key
                ] as string;
              }
            }
            delete translatedKeys[key];
          });
      }

      for (const [key, value] of Object.entries(lang)) {
        if (Array.isArray(value)) {
          translatedKeys[key] = (value as ProductLinkType[]).map(
            (item: ProductLinkType, index: number) => {
              return {
                ...item,
                title: (lang[key] as ProductLinkType[])[index].title
              };
            }
          );
        }
      }
      const data = {
        ...lang,
        ...translatedKeys
      };

      return data;
    }
  );

  setValues({ productLanguages: translatedLanguages });
};

const save = handleSubmit(
  async ({ productLanguages }: { productLanguages: ProductPageLanguage<ProductLinkType[]>[] }) => {
    emit('close', [toRaw(defaultProductLanguageItem.value), ...productLanguages]);
  }
);

const onSubmit = async () => {
  isClickedSave.value = true;
  await save();
};

const onSelectLanguageTab = (langIndex: number) => {
  currentSelectedLangIndex.value = langIndex;
};
const getTabName = (lang: string) => {
  if (lang.toLowerCase() === 'ko') {
    return 'studio.ai_translation.base_lang_ko';
  }
  return `studio.ai_translation.base_lang_${lang.toLowerCase().replace('-', '_')}`;
};

const loadingHeight = ref<string>('auto');

const getHeight = () => {
  loadingHeight.value = `${
    document.querySelector('.stds-dialog-content-body')?.clientHeight ?? 0
  }px`;
};

const handleFullScreen = async () => {
  const { valueHtml, valueRaw } = await showDialog<{ valueRaw: string; valueHtml: string }>({
    component: shallowRef(FullScreen),
    props: {
      content: productLanguages.value[currentSelectedLangIndex.value].productDescriptions,
      toolbarButtons: TOOLBAR_BUTTONS_FROALA,
      wordCountLimit: PRODUCT_DESC_WORD_COUNT_LIMIT,
      title: t('studio.prj_prod.this_prod.edit_summary_title')
    },
    severity: 'info'
  });

  const content = getHtmlTextByCompare(valueHtml, valueRaw);
  productLanguages.value[currentSelectedLangIndex.value].productDescriptions = content;
};

const onClose = () => {
  if (isLoading.value) {
    return;
  }

  emit('close');
};

onMounted(() => {
  nextTick(() => {
    getHeight();
  });
});
</script>

<style lang="scss">
.product-description {
  .fr-wrapper {
    border: none !important;
  }
}
</style>

import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { useNuxtApp } from 'nuxt/app';

import { CS_STOVE_LINK } from '@/constants/common.const';
import { DEFAULT_LOCALE_UPPERCASE } from '@/constants/locale.const';
import { CORRECT_REGION_ORDER, COUNTRY_LIST } from '@/constants/national-list.const';
import { SCREEN_TYPES, SUPPORTED_LANGUAGES_LIST } from '@/constants/product-page.const';
import { Confirmation } from '@/enums/common.enum';
import {
  COUNTRY_EXPOSURE,
  FILE_TYPE_RESPONSE,
  PRODUCT_PAGE_RESOURCES,
  PRODUCT_PAGE_RESOURCES_RESPONSE,
  SettingLabels,
  START_DATE_OPTS
} from '@/enums/product-page.enum';
import { useProductPageStore } from '@/stores/product-page.store';
import type { CommonCountry } from '@/types/common/common.type';
import type { ImageLanguageType } from '@/types/common/file.type';
import type { FormOption } from '@/types/common/form.type';
import type {
  CountryType,
  ProductLinkType,
  ProductPageFormType,
  ProductPageLanguage,
  ProductSettingType,
  RegionType,
  SupportedLanguageSettingType
} from '@/types/product-page.type';
import type {
  LinkRequestType,
  SupportLanguageRequestType,
  SystemSpecRequestType
} from '@/types/product-page/product-page-model.type';
import type { File, ProductPageRequest } from '@/types/product-page/product-page-request.type';
import type { ProductPageDetailResponse } from '@/types/product-page/product-page-response.type';
import { camelToSnake } from '@/utils/api.util';

const MULTILINGUAL_IMAGE_FIELDS = [
  PRODUCT_PAGE_RESOURCES.INTRODUCE_SCREENSHOT,
  PRODUCT_PAGE_RESOURCES.COVER_TITLE,
  PRODUCT_PAGE_RESOURCES.COVER_TITLE_LIST_DEFAULT,
  PRODUCT_PAGE_RESOURCES.INTRODUCE_ONE_LINE
];
const MULTI_IMAGE_FIELDS = [PRODUCT_PAGE_RESOURCES.AWARD];

const LANGUAGE_LIST = SUPPORTED_LANGUAGES_LIST.map((lang: FormOption) => lang.value);

const LANGUAGE_LIST_TRANSLATE = ['KO', 'EN', 'JA', 'ZH_TW', 'ZH_CN'];

const RESOURCE_TYPE = {
  IMAGE: 'IMAGE',
  YOUTUBE: 'YOUTUBE',
  MOVIE: 'MOVIE'
};

export const featureMapper = (feature: string) => {
  switch (feature) {
    case 'userInterface':
      return 'interface';
    case 'audio':
      return 'voice';
    case 'caption':
      return 'subtitle';
  }
};

const settingLabelMapper = (str: string) => {
  if (str === 'os') {
    return 'OS';
  }
  if (str === 'directx') {
    return 'DirectX';
  }

  const lowerStr = str.toLowerCase();
  return lowerStr.charAt(0).toUpperCase() + lowerStr.slice(1);
};

const fileTypeMapper = (type: string, isAward?: boolean) => {
  switch (type) {
    case FILE_TYPE_RESPONSE.IMAGE_SV:
      if (isAward) {
        return 'award';
      }
      return 'image';
    case FILE_TYPE_RESPONSE.YOUTUBE_SV:
      return 'link';
    case FILE_TYPE_RESPONSE.VIDEO_SV:
      return 'video';
  }
};

// Remove empty values from object
const removeEmptyValues = (obj: Record<string, any>) => {
  for (const [key, value] of Object.entries(obj)) {
    if (value === '' || !value || (Array.isArray(value) && value.length === 0)) {
      delete obj[key];
    }
  }
};

const handleResourceType = (file: ImageLanguageType) => {
  if (file.fileType?.includes('image/')) {
    if (file.movieUrl) {
      // If image come with url, it is youtube
      return RESOURCE_TYPE.YOUTUBE;
    }
    // Else it is image
    return RESOURCE_TYPE.IMAGE;
  }

  // Else it is movie (file_type=video/...)
  return RESOURCE_TYPE.MOVIE;
};

const isAllCountrySelected = (countryList: string[]) => {
  if (COUNTRY_LIST.length === countryList.length) {
    return COUNTRY_EXPOSURE.ALL;
  }

  return COUNTRY_EXPOSURE.SELECT;
};

const imageListMapper = (images: ImageLanguageType[], key: string) => {
  const imageList = images.filter((file: ImageLanguageType) => file.linkCdn);

  if (imageList.length === 0) {
    return {};
  }

  if (MULTILINGUAL_IMAGE_FIELDS.includes(key as PRODUCT_PAGE_RESOURCES)) {
    return {
      [key]: imageList.map((file: ImageLanguageType, index: number) => {
        return camelToSnake({
          language: file.lang
            ? file.lang.replace('-', '_').toUpperCase()
            : DEFAULT_LOCALE_UPPERCASE,
          fileType: file.fileType || '',
          fileName: file.name || '',
          fileId: file.fileId || '',
          linkCdn: file.linkCdn || '',
          fileSize: file.fileSize || 0,
          movieUrl: file.movieUrl || '',
          sortNo: (file.order ?? index) + 1,
          type: handleResourceType(file)
        });
      })
    };
  }

  if (MULTI_IMAGE_FIELDS.includes(key as PRODUCT_PAGE_RESOURCES)) {
    const list: ImageLanguageType[] = [];
    LANGUAGE_LIST_TRANSLATE.forEach((lang: string, langIndex: number) => {
      imageList.forEach((file: ImageLanguageType, imageIndex: number) => {
        list.push(
          camelToSnake({
            language: lang,
            fileType: file.fileType || '',
            fileName: file.name || '',
            fileId: file.fileId || '',
            linkCdn: file.linkCdn || '',
            fileSize: file.fileSize || 0,
            movieUrl: file.movieUrl || '',
            sortNo: (file.order ?? langIndex * LANGUAGE_LIST_TRANSLATE.length + imageIndex) + 1,
            type: handleResourceType(file)
          })
        );
      });
    });

    return {
      [key]: list
    };
  }

  return {
    [key]: LANGUAGE_LIST_TRANSLATE.map((lang: string, index: number) => {
      return camelToSnake({
        language: lang,
        fileType: imageList[0].fileType || '',
        fileName: imageList[0].name || '',
        fileId: imageList[0].fileId || '',
        linkCdn: imageList[0].linkCdn || '',
        fileSize: imageList[0].fileSize || 0,
        movieUrl: imageList[0].movieUrl || '',
        sortNo: (imageList[0].order ?? index) + 1,
        type: handleResourceType(imageList[0])
      });
    })
  };
};

export const imageListResponseMapper = (images: File[], multilang?: string, isAward?: boolean) => {
  if (!multilang) {
    return (images || []).map((file: File) => ({
      lang: (file.language || 'KO').replace('_', '-').toLowerCase(),
      fileType: file.fileType || '',
      name: file.fileName || '',
      fileId: file.fileId || '',
      linkCdn: file.linkCdn || '',
      fileSize: file.fileSize || 0,
      movieUrl: file.movieUrl,
      image: file.linkCdn || '',
      type: fileTypeMapper(file.type, isAward)
      // movieThumbnailUrl: {
      //   lang: (file.language || 'KO').replace('_', '-').toLowerCase(),
      //   fileType: 'image',
      //   linkCdn: file.movieThumbnail?.linkCdn || '',
      //   name: file.movieThumbnail?.fileName || '',
      //   fileId: file.movieThumbnail?.fileId || '',
      //   fileSize: file.movieThumbnail?.fileSize || 0
      // }
    }));
  } else {
    const list = (images || []).map((file: File) => ({
      lang: (file.language || 'KO').replace('_', '-').toLowerCase(),
      fileType: file.fileType || '',
      name: file.fileName || '',
      fileId: file.fileId || '',
      linkCdn: file.linkCdn || '',
      fileSize: file.fileSize || 0,
      movieUrl: file.movieUrl,
      image: file.linkCdn || '',
      type: fileTypeMapper(file.type, isAward)
    }));

    const found = list.find((l: { lang: string }) => l.lang === multilang);

    if (found) {
      return list;
    }

    list.unshift({
      lang: multilang,
      fileType: '',
      name: '',
      fileId: '',
      linkCdn: '',
      fileSize: 0,
      movieUrl: '',
      image: '',
      type: RESOURCE_TYPE.IMAGE
    });

    return list;
  }
};

const filterImageListToDefaultLang = (images: ImageLanguageType[], defaultLang: string) => {
  return images.filter(
    (file: ImageLanguageType) => file.lang === defaultLang.replace('_', '-').toLowerCase()
  );
};

export const productPageMapper = (
  page: ProductPageFormType,
  languageCd: string
): ProductPageRequest => {
  const productPageStore = useProductPageStore();

  const { defaultDescription, isEnableRecommendSettings } = productPageStore;

  let nationsByRegion: string[] = [];
  let supportLanguages: SupportLanguageRequestType = {};
  let links: Record<string, LinkRequestType[]> = {};
  let systemSpecs: SystemSpecRequestType = {
    minimum: {
      os: '',
      processor: '',
      memory: '',
      graphic: '',
      directx: '',
      storage: '',
      sound: ''
    },
    recommended: {
      os: '',
      processor: '',
      memory: '',
      graphic: '',
      directx: '',
      storage: '',
      sound: ''
    }
  };
  let resources = {};
  let description: Record<string, string> = {};
  let shortPieces: Record<string, string> = {};
  let nameHeaders: Record<string, string> = {};

  if (page.exposureCountry === COUNTRY_EXPOSURE.SELECT) {
    if (page.countryByRegion && page.countryByRegion.length > 0) {
      for (let i = 0; i < page.countryByRegion.length; i++) {
        const region = page.countryByRegion[i];
        for (let j = 0; j < region.countries.length; j++) {
          const country = region.countries[j];
          if (country.isChecked) {
            nationsByRegion.push(country.code);
          }
        }
      }
    }

    if (page.countryBySearch && page.countryBySearch.length > 0) {
      nationsByRegion = page.countryBySearch;
    }
  } else if (page.exposureCountry === COUNTRY_EXPOSURE.ALL) {
    nationsByRegion = COUNTRY_LIST.map((country: CommonCountry) => country.code);
  }

  if (page.supportedLanguageSettings && page.supportedLanguageSettings.length > 0) {
    supportLanguages = page.supportedLanguageSettings.reduce(
      (acc: SupportLanguageRequestType, cur: SupportedLanguageSettingType) => {
        acc[cur.lang.toLowerCase().replace('_', '-')] = camelToSnake({
          userInterface: cur.settings.includes('interface'),
          audio: cur.settings.includes('voice'),
          caption: cur.settings.includes('subtitle')
        });
        return acc;
      },
      {}
    );
  }

  const translatedContents = page.translatedContents;
  if (translatedContents && translatedContents.length > 0) {
    links = translatedContents.reduce(
      (acc: Record<string, LinkRequestType[]>, cur: ProductPageLanguage<ProductLinkType[]>) => {
        const lang = cur.langCode;
        const curLinks = cur.links;
        const productDescription = cur.productDescriptions;
        const productShortPiece = cur.oneLineIntroduction;
        const productNameHeader = cur.subtitle;

        const langCode = lang.replace('-', '_').toUpperCase();

        if (cur.default) {
          description[langCode] = page.productDescriptions ?? defaultDescription;
          shortPieces[langCode] = page.oneLineIntroduction ?? productShortPiece;
          nameHeaders[langCode] = page.subtitle;
          acc[langCode] = page.links.map((curLink: ProductLinkType, index: number) => {
            return {
              title: curLink.title,
              url: curLink.url,
              order: index + 1
            };
          });
        } else {
          description[langCode] = productDescription;
          shortPieces[langCode] = productShortPiece;
          nameHeaders[langCode] = productNameHeader;

          if (curLinks.length > 0) {
            acc[langCode] = curLinks.map((curLink: ProductLinkType, index: number) => {
              const curUrl = page.links.find((link: ProductLinkType) => link.order === curLink.order)?.url;
              return {
                title: curLink.title,
                url: curUrl || '',
                order: index + 1
              };
            });
          }
        }

        return acc;
      },
      {}
    );
  } else {
    links = (page.links || []).reduce((acc: Record<string, LinkRequestType[]>) => {
      acc[languageCd.toUpperCase()] = page.links.map((link: ProductLinkType, index: number) => {
        return {
          title: link.title,
          url: link.url,
          order: index + 1
        };
      });

      return acc;
    }, {});

    description = {
      [languageCd.toUpperCase()]: page.productDescriptions || ''
    };

    if (page.oneLineIntroduction) {
      shortPieces = {
        [languageCd.toUpperCase()]: page.oneLineIntroduction || ''
      };
    }

    if (page.subtitle) {
      nameHeaders = {
        [languageCd.toUpperCase()]: page.subtitle || ''
      };
    }
  }

  if (page.settings && page.settings.length > 0) {
    systemSpecs = page.settings.reduce(
      (acc: SystemSpecRequestType, cur: ProductSettingType) => {
        acc.minimum = {
          ...acc.minimum,
          ...{
            [cur.label.toLowerCase()]: cur.minimumRequirement
          }
        };

        if (isEnableRecommendSettings) {
          acc.recommended = {
            ...acc.recommended,
            ...{
              [cur.label.toLowerCase()]: cur.setting
            }
          };
        }

        return acc;
      },
      {
        minimum: {
          os: '',
          processor: '',
          memory: '',
          graphic: '',
          directx: '',
          storage: '',
          sound: ''
        },
        recommended: {
          os: '',
          processor: '',
          memory: '',
          graphic: '',
          directx: '',
          storage: '',
          sound: ''
        }
      }
    );
  }

  if (page.additionalSettings && page.additionalSettings.length > 0) {
    systemSpecs.minimum = {
      ...systemSpecs.minimum,
      ...{
        others: page.additionalSettings.map((setting: ProductSettingType, index: number) => {
          return {
            name: setting.label,
            value: Array.isArray(setting.minimumRequirement)
              ? setting.minimumRequirement[0].value
              : setting.minimumRequirement,
            index
          };
        })
      }
    };

    if (isEnableRecommendSettings) {
      systemSpecs.recommended = {
        ...systemSpecs.recommended,
        ...{
          others: page.additionalSettings.map((setting: ProductSettingType, index: number) => {
            return {
              name: setting.label,
              value: Array.isArray(setting.setting) ? setting.setting[0].value : setting.setting,
              index
            };
          })
        }
      };
    }
  }

  if (page.productPageBgImage && page.productPageBgImage.length > 0) {
    resources = {
      ...resources,
      ...imageListMapper(page.productPageBgImage, PRODUCT_PAGE_RESOURCES.BACKGROUND)
    };
  }

  if (page.exhibitionVideosAndImages && page.exhibitionVideosAndImages.length > 0) {
    if (
      page.exhibitionVideosAndImagesSorted &&
      page.exhibitionVideosAndImagesSorted.length === page.exhibitionVideosAndImages.length
    ) {
      const sorted = page.exhibitionVideosAndImagesSorted.map((sortedImage: ImageLanguageType) => {
        const found = page.exhibitionVideosAndImages.find((image: ImageLanguageType) => {
          return image.fileId === sortedImage.fileId;
        });

        if (found) {
          return {
            ...sortedImage,
            // movieThumbnailUrl: found.movieThumbnailUrl,
            movieUrl: found.movieUrl
          };
        }

        return sortedImage;
      });

      resources = {
        ...resources,
        ...imageListMapper(sorted, PRODUCT_PAGE_RESOURCES.INTRODUCE_SCREENSHOT)
      };
    } else {
      resources = {
        ...resources,
        ...imageListMapper(
          page.exhibitionVideosAndImages,
          PRODUCT_PAGE_RESOURCES.INTRODUCE_SCREENSHOT
        )
      };
    }
  }

  if (page.image1x1 && page.image1x1.length > 0) {
    resources = {
      ...resources,
      ...imageListMapper(page.image1x1, PRODUCT_PAGE_RESOURCES.COVER_TITLE)
    };
  }
  if (page.image16x9 && page.image16x9.length > 0) {
    resources = {
      ...resources,
      ...imageListMapper(page.image16x9, PRODUCT_PAGE_RESOURCES.COVER_TITLE_LIST_DEFAULT)
    };
  }
  if (page.imageTitleBackground && page.imageTitleBackground.length > 0) {
    resources = {
      ...resources,
      ...imageListMapper(page.imageTitleBackground, PRODUCT_PAGE_RESOURCES.COVER_TITLE_BACKGROUND)
    };
  }
  if (page.imageOneLineIntro && page.imageOneLineIntro.length > 0) {
    resources = {
      ...resources,
      ...imageListMapper(page.imageOneLineIntro, PRODUCT_PAGE_RESOURCES.INTRODUCE_ONE_LINE)
    };
  }
  if (page.imageAward && page.imageAward.length > 0) {
    resources = {
      ...resources,
      ...imageListMapper(page.imageAward, PRODUCT_PAGE_RESOURCES.AWARD)
    };
  }
  if (page.productPageBgVideo && page.productPageBgVideo.length > 0) {
    resources = {
      ...resources,
      ...imageListMapper(page.productPageBgVideo, PRODUCT_PAGE_RESOURCES.TRAILER)
    };
  }

  const introduceResources = Object.values(page.mappedSorted ?? []).flat() as ImageLanguageType[];
  resources = {
    ...resources,
    ...imageListMapper(introduceResources, PRODUCT_PAGE_RESOURCES.INTRODUCE_SCREENSHOT)
  };

  // removeEmptyValues(description);
  removeEmptyValues(shortPieces);
  removeEmptyValues(nameHeaders);

  Object.entries(links).forEach(([key, value]: [string, LinkRequestType[]]) => {
    value.forEach((link: LinkRequestType) => {
      if (!link.title) {
        links[key] = links[key].filter((l: LinkRequestType) => l !== link);
      }
    });

    removeEmptyValues(links[key]);
  });

  removeEmptyValues(links);

  const finalRequest = {
    ...camelToSnake(
      {
        pageName: page.name,
        productNo: page.productNo,
        displayType: page.screenType === SCREEN_TYPES.BASIC ? 'BASIC' : 'TRAILER',
        exposeAllCountryYn:
          page.exposureCountry === COUNTRY_EXPOSURE.ALL ? Confirmation.YES : Confirmation.NO,
        pageTags: (page.productTags || []).map((tag: string) => Number(tag)),
        supportFeatures: (page.supportedFeatures || []).map((feature: string) => feature),
        selectedNations: nationsByRegion,
        systemSpecs,
        releasedAt:
          page.startDateType !== START_DATE_OPTS.IMMEDIATE
            ? DateTime.fromJSDate(new Date(page.startDate || 0))
              .startOf('minute')
              .toMillis()
            : undefined
      },
      undefined,
      LANGUAGE_LIST
    ),
    links,
    descriptions: description,
    resources,
    short_pieces: shortPieces,
    name_headers: nameHeaders,
    support_languages: camelToSnake(supportLanguages, undefined, LANGUAGE_LIST)
  };

  if (isEmpty(supportLanguages)) {
    delete finalRequest.support_languages;
  }
  if (Object.values(systemSpecs.minimum).every((v: string) => !v)) {
    delete finalRequest.system_specs.minimum;
  }
  if (Object.values(systemSpecs.recommended).every((v: string) => !v)) {
    delete finalRequest.system_specs.recommended;
  }
  if (isEmpty(finalRequest.system_specs)) {
    delete finalRequest.system_specs;
  }
  if (shortPieces) {
    if (Object.values(shortPieces).every((v: string) => !v)) {
      delete finalRequest.short_pieces;
    }
  }
  if (nameHeaders) {
    if (Object.values(nameHeaders).every((v: string) => !v)) {
      delete finalRequest.name_headers;
    }
  }

  return finalRequest;
};

export const productPageResponseMapper = (
  request: ProductPageDetailResponse,
  languageCd: string
): ProductPageFormType => {
  const { t } = useNuxtApp().$i18n as any;

  let tmp = [];
  let resLinks: ProductLinkType[] = [];
  const translatedContents: ProductPageLanguage<ProductLinkType[]>[] = [];
  let settingsMapper: ProductSettingType[] = [];
  const settingsAdditionalMapper: ProductSettingType[] = [];
  const defaultSettings: ProductSettingType[] = [];
  const defaultSettingLabels = [
    SettingLabels.OS,
    SettingLabels.PROCESSOR,
    SettingLabels.MEMORY,
    SettingLabels.GRAPHIC,
    SettingLabels.DIRECTX,
    SettingLabels.STORAGE,
    SettingLabels.SOUND
  ];

  if (request.systemSpecs?.minimum) {
    settingsMapper = Object.keys(request.systemSpecs.minimum)
      .filter((k: string) => k !== 'others')
      .map((key: string) => {
        return {
          label: settingLabelMapper(key.toLowerCase()),
          minimumRequirement:
            request.systemSpecs?.minimum?.[key as keyof typeof request.systemSpecs.minimum],
          setting:
            request.systemSpecs?.recommended?.[key as keyof typeof request.systemSpecs.recommended]
        };
      });

    if (request.systemSpecs?.minimum?.others) {
      const recommendOthers = request.systemSpecs?.recommended?.others;
      tmp = request.systemSpecs?.minimum?.others.map(
        (setting: { name: string; index: number; value?: string }, i: number) => {
          return {
            id: `additional_setting_${setting.index ?? i}`,
            label: setting.name,
            minimumRequirement: setting.value ?? '',
            setting:
              recommendOthers && Array.isArray(recommendOthers)
                ? recommendOthers.find((r: any) => r.name === setting.name)?.value
                : '',
            isRequired: false
          };
        }
      );

      settingsAdditionalMapper.push(...tmp);
    }
  } else {
    defaultSettingLabels.forEach((label: string) => {
      if (!settingsMapper.find((s: ProductSettingType) => s.label === label)) {
        defaultSettings.push({
          label,
          minimumRequirement: '',
          setting: ''
        });
      }
    });

    if (defaultSettings.length > 0) {
      settingsMapper.unshift(...defaultSettings);
    }
  }

  const regions = COUNTRY_LIST.reduce((acc: RegionType[], cur: CommonCountry) => {
    const foundRegion = acc.find((r: RegionType) => r.name === cur.region);
    if (foundRegion) {
      foundRegion.countries.push({
        name: cur.name,
        isChecked: false,
        code: cur.code
      });
    } else {
      acc.push({
        name: cur.region,
        countries: [
          {
            name: cur.name,
            isChecked: false,
            code: cur.code
          }
        ],
        isChecked: false
      });
    }

    return acc;
  }, []);

  const correctRegionOrder = CORRECT_REGION_ORDER;

  const sortedRegions = regions.sort((a: RegionType, b: RegionType) => {
    return correctRegionOrder.indexOf(a.name) - correctRegionOrder.indexOf(b.name);
  });

  const countryByRegion = sortedRegions.map((region: RegionType) => {
    region.countries = region.countries.map((country: CountryType) => {
      country.isChecked = (request.selectedNations || []).includes(country.code);
      return country;
    });

    if (region.countries.every((country: CountryType) => country.isChecked)) {
      region.isChecked = true;
    }
    return region;
  });

  if (
    request.links &&
    Object.keys(request.links).find((k: string) => k === languageCd.replace('-', '_').toUpperCase())
  ) {
    resLinks = request.links[languageCd.replace('_', '')].map(
      (link: LinkRequestType, index: number) => {
        return {
          title: link.title,
          url: link.url,
          order: index + 1,
          linkId: (index + 1).toString()
        };
      }
    );
  }

  // Create translated contents
  LANGUAGE_LIST_TRANSLATE.forEach((l: string, index: number) => {
    let newL: string = l;
    if (l === 'ZH_CN') {
      newL = 'ZHCN';
    }
    if (l === 'ZH_TW') {
      newL = 'ZHTW';
    }

    if (!request.links) {
      return;
    }

    const found = Object.keys(request.links).find((k: string) => k === newL);

    if (found) {
      translatedContents.push({
        default: l === languageCd,
        langCode: l.replace('_', '-').toLowerCase(),
        oneLineIntroduction: request.shortPieces ? request.shortPieces[newL] : '',
        subtitle: request.nameHeaders ? request.nameHeaders[newL] : '',
        productDescriptions: request.descriptions ? request.descriptions[newL] : '',
        links: (request.links ? request.links[newL] : []).map(
          (link: LinkRequestType, index1: number) => {
            return {
              title: link.title,
              url: link.url,
              order: index1 + 1,
              linkId: (index1 + 1).toString()
            };
          }
        )
      });
    } else {
      translatedContents.push({
        default: l === languageCd,
        langCode: l.replace('_', '-').toLowerCase(),
        oneLineIntroduction: '',
        subtitle: '',
        productDescriptions: '',
        links: [
          {
            title: '',
            url: '',
            linkId: index.toString()
          }
        ]
      });
    }
  });

  // Links with the most links
  const mostLinks = Object.values(request.links).reduce(
    (accumulator: LinkRequestType[], currentValue: LinkRequestType[]) => {
      if (currentValue.length > accumulator.length) {
        return currentValue;
      }

      return accumulator;
    },
    []
  );

  if (mostLinks.length === 0) {
    mostLinks.push({
      title: t('studio.prj_prod.this_prod.edit_additional_links_stove_cs'),
      url: CS_STOVE_LINK,
      order: 1
    });
  }

  translatedContents.forEach((content: ProductPageLanguage<ProductLinkType[]>) => {
    let newL: string = content.langCode.toUpperCase();
    if (newL === 'ZH-CN') {
      newL = 'ZHCN';
    }
    if (newL === 'ZH-TW') {
      newL = 'ZHTW';
    }

    if (request.shortPieces) {
      content.oneLineIntroduction = request.shortPieces[newL] ?? '';
    }

    if (request.nameHeaders) {
      content.subtitle = request.nameHeaders[newL] ?? '';
    }

    if (request.descriptions) {
      content.productDescriptions = request.descriptions[newL] ?? '';
    }

    if (request.descriptions) {
      content.productDescriptions = request.descriptions[newL] ?? '';
    }

    if (request.links) {
      if (request.links[newL] && request.links[newL].length > 0) {
        content.links = request.links[newL]?.map((link: LinkRequestType, index1: number) => ({
          ...link,
          linkId: (index1 + 1).toString(),
          order: index1 + 1
        }));

        if (content.links.length !== mostLinks.length) {
          const notTranslatedLink = mostLinks.filter((link: LinkRequestType) => {
            return !content.links.find(
              (l: ProductLinkType) => l.url === link.url && l.order === link.order
            );
          });

          notTranslatedLink.forEach((link: LinkRequestType, index1: number) => {
            content.links.push({
              title: '',
              url: link.url,
              linkId: (index1 + 1).toString(),
              order: content.links.length + 1
            });
          });
        }

        return;
      }

      // Add the links to other languages for translated contents
      content.links = mostLinks.map((link: LinkRequestType, index1: number) => {
        return {
          title:
            link.url === CS_STOVE_LINK && link.order === 1
              ? t('studio.prj_prod.this_prod.edit_additional_links_stove_cs')
              : '',
          url: link.url,
          linkId: (index1 + 1).toString()
        };
      });
    }
  });

  // Sort the links
  translatedContents.forEach((content: ProductPageLanguage<ProductLinkType[]>) => {
    const links = content.links;
    const sortedLinks = links.sort((a: ProductLinkType, b: ProductLinkType) => {
      if (a.order && b.order) {
        return a.order - b.order;
      }

      return 0;
    });

    content.links = sortedLinks;
  });

  // Remove the default title for the default language
  if (!request.pageUpdatedAt) {
    translatedContents.forEach((content: ProductPageLanguage<ProductLinkType[]>) => {
      content.links.forEach((link: ProductLinkType) => {
        if (
          content.default === false &&
          link.url === CS_STOVE_LINK &&
          link.title === t('studio.prj_prod.this_prod.edit_additional_links_stove_cs')
        ) {
          link.title = '';
        }
      });

      return content;
    });
  }

  const page: ProductPageFormType = {
    startDateType: request.scheduledStartedAt ? START_DATE_OPTS.RESERVE : START_DATE_OPTS.IMMEDIATE,
    name: request.pageName,
    productNo: request.productNo,
    startDate: request.scheduledStartedAt
      ? request.scheduledStartedAt
      : DateTime.local().plus({ hours: 1 }).toMillis(),
    screenType:
      request.displayType === SCREEN_TYPES.BASIC || !request.displayType
        ? SCREEN_TYPES.BASIC
        : SCREEN_TYPES.VIDEO,
    exposureCountry:
      request.exposeAllCountryYn === Confirmation.NO
        ? COUNTRY_EXPOSURE.SELECT
        : request.exposeAllCountryYn === Confirmation.YES ? COUNTRY_EXPOSURE.ALL : isAllCountrySelected(request.selectedNations),
    productTags: (request.pageTags || []).map((tag: number) => tag.toString()),
    supportedFeatures: request.supportFeatures as any,
    countryByRegion, // This needs to be reconstructed based on selectedNations
    countryBySearch: request.selectedNations,
    supportedLanguages: Object.keys(request.supportLanguages || {}).map((lang: string) => {
      if (lang === 'zh-tw' || lang === 'zhtw') {
        return 'ZH_TW';
      }
      if (lang === 'zh-cn' || lang === 'zhcn') {
        return 'ZH_CN';
      }
      return lang.toUpperCase();
    }),
    supportedLanguageSettings: Object.keys(request.supportLanguages || {}).map((lang: string) => ({
      lang: (lang || 'KO').toUpperCase().replace('-', '_'),
      settings: Object.keys(request.supportLanguages[lang])
        .filter(
          (key: string) => (request.supportLanguages[lang] as { [key: string]: boolean })[key]
        )
        .map((key: string) => featureMapper(key)) as string[]
    })),
    links:
      resLinks.length > 0
        ? resLinks.sort((a: ProductLinkType, b: ProductLinkType) => (a.order ?? 0) - (b.order ?? 0))
        : request.pageUpdatedAt
          ? []
          : [
            {
              linkId: '1',
              title: t('studio.prj_prod.this_prod.edit_additional_links_stove_cs'),
              url: CS_STOVE_LINK,
              isDefault: true,
              order: 1
            }
          ],
    settings: settingsMapper,
    additionalSettings: settingsAdditionalMapper,
    productPageBgImage: imageListResponseMapper(
      request.resources[PRODUCT_PAGE_RESOURCES_RESPONSE.BACKGROUND]
    ),
    exhibitionVideosAndImages:
      imageListResponseMapper(
        request.resources[PRODUCT_PAGE_RESOURCES_RESPONSE.INTRODUCE_SCREENSHOT]
      ).length > 0
        ? imageListResponseMapper(
          request.resources[PRODUCT_PAGE_RESOURCES_RESPONSE.INTRODUCE_SCREENSHOT]
        )
        : [],
    exhibitionVideosAndImagesSorted: imageListResponseMapper(
      request.resources[PRODUCT_PAGE_RESOURCES_RESPONSE.INTRODUCE_SCREENSHOT]
    ),
    image1x1: imageListResponseMapper(
      request.resources[PRODUCT_PAGE_RESOURCES_RESPONSE.COVER_TITLE],
      languageCd.toLowerCase().replace('_', '-')
    ),
    image16x9: imageListResponseMapper(
      request.resources[PRODUCT_PAGE_RESOURCES_RESPONSE.COVER_TITLE_LIST_DEFAULT],
      languageCd.toLowerCase().replace('_', '-')
    ),
    imageTitleBackground: imageListResponseMapper(
      request.resources[PRODUCT_PAGE_RESOURCES_RESPONSE.COVER_TITLE_BACKGROUND]
    ),
    imageOneLineIntro: imageListResponseMapper(
      request.resources[PRODUCT_PAGE_RESOURCES_RESPONSE.INTRODUCE_ONE_LINE],
      languageCd.toLowerCase().replace('_', '-')
    ),
    imageAward: filterImageListToDefaultLang(
      imageListResponseMapper(
        request.resources[PRODUCT_PAGE_RESOURCES_RESPONSE.AWARD],
        undefined,
        true
      ),
      languageCd
    ),
    productPageBgVideo: imageListResponseMapper(
      request.resources[PRODUCT_PAGE_RESOURCES_RESPONSE.TRAILER]
    ),
    oneLineIntroduction: request.shortPieces
      ? request.shortPieces[languageCd.replace('_', '')]
      : '',
    subtitle: request.nameHeaders ? request.nameHeaders[languageCd.replace('_', '')] : '',
    productDescriptions: request.descriptions
      ? request.descriptions[languageCd.replace('_', '')]
      : request.pageUpdatedAt
        ? ''
        : '<p class="text-sm font-medium leading-md text-on-surface-elevation-2"> 게임 상품 설명에는 이런 내용을 입력하면 좋아요! 이해를 도울 수 있는 이미지가 있다면 추가해 보세요. </p> <ul class="mt-16 text-sm font-medium leading-md text-on-surface-elevation-3"> <li class="list-inside list-disc">유저 플레이 후기</li> <li class="list-inside list-disc">게임의 매력 포인트</li> <li class="list-inside list-disc">캐릭터 소개</li> <li class="list-inside list-disc">스토리</li> <li class="list-inside list-disc">조작방법</li> <li class="list-inside list-disc">제작자 코멘트</li> </ul>',
    translatedContents
  };

  const mapped = page.exhibitionVideosAndImages.reduce((acc: any, cur: ImageLanguageType) => {
    const lang = cur.lang;
    acc[lang].push(cur);

    return acc;
  }, {
    en: [],
    ko: [],
    'zh-cn': [],
    'zh-tw': [],
    ja: []
  });

  const mappedSorted = mapped;

  page.mapped = mapped;
  page.mappedSorted = mappedSorted;
  page.exhibitionVideosAndImages = page.exhibitionVideosAndImages.filter((i: ImageLanguageType) => i.lang === languageCd.toLowerCase().replace('_', '-'));
  page.exhibitionVideosAndImagesSorted = page.exhibitionVideosAndImages.filter((i: ImageLanguageType) => i.lang === languageCd.toLowerCase().replace('_', '-'));

  return page;
};

<template>
  <div>
    <s-dialog :open="true" size="sm" :to="`stds-dialog-register-language`">
      <s-dialog-overlay />
      <s-dialog-panel>
        <st-dialog-header @clickClose="closeDialog">
          {{ $t('studio.prj_prod.this_prod.edit_gamepreview_language_setting_btn') }}
        </st-dialog-header>
        <s-dialog-content>
          <s-dialog-content-body>
            <div>
              <safe-html
                tag="p"
                :html="$t('studio.prj_prod.this_prod.edit_gamepreview_language_setting_guide')"
                class="text-xs leading-xs text-placeholder"
              />
              <checkbox-group
                v-model="selectedSupportedLangs"
                :optionProps="{
                  size: 'sm',
                  align: 'middle'
                }"
                :options="supportedLanguageList"
                class="mt-8 gap-y-8 product-language__register__checkbox"
              />
            </div>
          </s-dialog-content-body>
          <s-dialog-footer class="w-full px-0">
            <s-button class="w-full" @click="onSubmit">
              {{ $t('studio.common.popup_case_cf_btn') }}
            </s-button>
          </s-dialog-footer>
        </s-dialog-content>
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target :name="`stds-dialog-register-language`" />
  </div>
</template>

<script setup lang="ts">
import { ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

import SafeHtml from '@/components/common/safe-html.vue';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import CheckboxGroup from '@/components/validation/checkbox-group.vue';
import type { FormOptionGroup } from '@/types/common/form.type';

const props = defineProps<{
  supportedLanguageList: FormOptionGroup<string>[];
  selectedLanguageList: string[];
}>();

const { t } = useI18n();

const { selectedLanguageList } = toRefs(props);
const originalLanguageList = props.selectedLanguageList;

const emits = defineEmits<{
  close: [v: string[]];
}>();

const supportedLanguageList = ref<FormOptionGroup<string>[]>(
  props.supportedLanguageList.map((s: FormOptionGroup<string>, index: number) => {
    if (index === 0) {
      return {
        ...s,
        label: `${t(s.label)} (${t('studio.ai_translation.base_lang')})`
      };
    }
    return s;
  })
);

const selectedSupportedLangs = ref<string[]>(selectedLanguageList.value);

const onSubmit = () => {
  emits('close', selectedSupportedLangs.value);
};

const closeDialog = () => {
  emits('close', originalLanguageList);
};
</script>

<style lang="scss">
.product-language__register__checkbox {
  @apply grid grid-cols-2;
}

.product-language__register__checkbox *:first-child {
  @apply col-span-full;
}
</style>

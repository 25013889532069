<template>
  <s-dialog to="stds-dialog-preview-16-9-image" size="lg" :open="true">
    <s-dialog-overlay />
    <s-dialog-panel class="h-auto">
      <st-dialog-header @clickClose="closeDialog">
        {{ $t('studio.prj_prod.this_prod.edit_gamepreview_16to9_preview_title') }}
      </st-dialog-header>
      <s-dialog-content>
        <s-dialog-content-body>
          <div class="pb-40 pt-12">
            <p class="text-sm leading-md text-on-surface-elevation-3">
              {{ $t('studio.prj_prod.this_prod.edit_gamepreview_16to9_preview_msg1') }}
            </p>
            <p class="mt-16 text-lg font-bold leading-lg text-on-surface-elevation-2">
              {{ $t('studio.prj_prod.this_prod.edit_gamepreview_16to9_preview_msg2') }}
            </p>
            <div
              class="dark mt-8 flex gap-20 overflow-hidden rounded-xl bg-background-variant-1 px-[3rem] py-20"
            >
              <div class="w-220 shrink-0 overflow-hidden rounded-3xl bg-surface-variant-1">
                <div class="aspect-video">
                  <img
                    v-if="image16x9[0].image"
                    :src="image16x9[0].image"
                    alt=""
                    class="h-full w-full object-cover"
                  />
                  <img
                    v-else
                    src="@/assets/images/product/guide-16x9-image.png"
                    alt=""
                    class="h-full w-full object-cover"
                  />
                </div>
                <div class="flex h-108 flex-col px-20 pb-16 pt-[1.4rem]">
                  <p class="line-clamp-2 text-md font-bold leading-sm text-on-surface-elevation-1">
                    {{ productName }}
                  </p>
                  <p class="mt-auto text-md font-bold leading-2xl text-on-surface-elevation-1">
                    ₩00,000
                  </p>
                </div>
              </div>

              <div class="flex w-[46rem] shrink-0 overflow-hidden rounded-3xl bg-surface-variant-1">
                <div class="aspect-[174/232] w-[17.4rem] shrink-0">
                  <img
                    v-if="image16x9[0].image"
                    :src="image16x9[0].image"
                    alt=""
                    class="h-full w-full object-cover"
                  />
                  <img
                    v-else
                    src="@/assets/images/product/guide-16x9-image.png"
                    alt=""
                    class="h-full w-full object-cover"
                  />
                </div>
                <div class="flex w-full flex-1 flex-col p-24">
                  <p
                    class="line-clamp-2 text-3xl font-bold leading-2xl text-on-surface-elevation-1"
                  >
                    {{ productName }}
                  </p>
                  <p class="mt-auto text-3xl font-bold leading-2xl text-on-surface-elevation-1">
                    ₩00,000
                  </p>
                </div>
              </div>

              <div class="w-220 shrink-0 overflow-hidden rounded-3xl bg-surface-variant-1">
                <div class="aspect-video">
                  <img
                    src="@/assets/images/banner/thumbnail-demo1.jpg"
                    alt=""
                    class="h-full w-full object-cover"
                  />
                </div>
                <div class="flex h-108 flex-col px-20 pb-16 pt-[1.4rem]">
                  <p class="line-clamp-2 text-md font-bold leading-sm text-on-surface-elevation-1">
                    GAME TITLE
                  </p>
                  <p class="mt-auto text-md font-bold leading-2xl text-on-surface-elevation-1">
                    ₩00,000
                  </p>
                </div>
              </div>
            </div>

            <p class="mt-16 text-lg font-bold leading-lg text-on-surface-elevation-2">
              {{ $t('studio.prj_prod.this_prod.edit_gamepreview_16to9_preview_msg4') }}
            </p>
            <div
              class="dark mt-8 flex gap-16 overflow-hidden rounded-xl bg-background-variant-1 p-24"
            >
              <div class="aspect-video w-[14.2rem] shrink-0 overflow-hidden rounded-lg">
                <img
                  v-if="image16x9[0].image"
                  :src="image16x9[0].image"
                  alt=""
                  class="h-full w-full object-cover"
                />
                <img
                  v-else
                  src="@/assets/images/product/guide-16x9-image.png"
                  alt=""
                  class="h-full w-full object-cover"
                />
              </div>
              <div class="flex-1">
                <p class="text-xl font-bold leading-lg text-on-surface-elevation-1">
                  {{ productName }}
                </p>
                <p class="mt-4 text-md font-bold leading-lg text-on-surface-elevation-1">₩00,000</p>
              </div>
              <div class="flex shrink-0 items-center gap-16">
                <s-button size="sm" variant="primary" icon="ic-v2-navigation-cart-line" class="cursor-default pointer-events-none">
                  {{ $t('studio.prj_prod.this_prod.edit_gamepreview_16to9_preview_msg4') }}
                </s-button>
                <button type="button" class="h-20 cursor-default">
                  <s-icon
                    size="3xl"
                    icon="ic-v2-control-close-line"
                    class="text-on-surface-elevation-3"
                  />
                </button>
              </div>
            </div>

            <p class="mt-16 text-lg font-bold leading-lg text-on-surface-elevation-2">
              {{ $t('studio.product_page.gamepreview_my_home') }}
            </p>
            <div
              class="mt-12 flex flex-col gap-12 rounded-xl bg-background-variant-1 py-20 px-[3rem]"
            >
              <s-text as="p" role="text2" class="font-bold text-[#1f1f1f]">
                {{ $t('studio.product_page.gamepreview_my_home_game_list') }}
              </s-text>
              <div class="flex gap-20">
                <div class="w-[14.7rem]">
                  <div class="aspect-[174/232] w-[14.7rem] shrink-0 overflow-hidden rounded-xl">
                    <img
                      v-if="image16x9[0].image"
                      :src="image16x9[0].image"
                      alt=""
                      class="h-full w-full object-cover"
                    />
                    <img
                      v-else
                      src="@/assets/images/product/mypage_01.png"
                      alt=""
                      class="h-full w-full object-cover"
                    />
                  </div>
                  <s-text as="p" role="text3" class="font-bold text-[#1f1f1f] mt-[1rem] break-all">
                    {{ productName }}
                  </s-text>
                </div>
                <div class="w-[14.7rem]">
                  <div class="aspect-[174/232] w-[14.7rem] shrink-0 overflow-hidden rounded-xl">
                    <img
                      src="@/assets/images/product/mypage_02.png"
                      alt=""
                      class="h-full w-full object-cover"
                    />
                  </div>
                  <s-text as="p" role="text3" class="font-bold text-[#1f1f1f] mt-[1rem] break-all">
                    {{ productName }}
                  </s-text>
                </div>
                <div class="w-[14.7rem]">
                  <div class="aspect-[174/232] w-[14.7rem] shrink-0 overflow-hidden rounded-xl">
                    <img
                      src="@/assets/images/product/mypage_03.png"
                      alt=""
                      class="h-full w-full object-cover"
                    />
                  </div>
                  <s-text as="p" role="text3" class="font-bold text-[#1f1f1f] mt-[1rem] break-all">
                    {{ productName }}
                  </s-text>
                </div>
                <div class="w-[14.7rem]">
                  <div class="aspect-[174/232] w-[14.7rem] shrink-0 overflow-hidden rounded-xl">
                    <img
                      src="@/assets/images/product/mypage_04.png"
                      alt=""
                      class="h-full w-full object-cover"
                    />
                  </div>
                  <s-text as="p" role="text3" class="font-bold text-[#1f1f1f] mt-[1rem] break-all">
                    {{ productName }}
                  </s-text>
                </div>
                <div class="w-[14.7rem]">
                  <div class="aspect-[174/232] w-[14.7rem] shrink-0 overflow-hidden rounded-xl">
                    <img
                      src="@/assets/images/product/mypage_05.png"
                      alt=""
                      class="h-full w-full object-cover"
                    />
                  </div>
                  <s-text as="p" role="text3" class="font-bold text-[#1f1f1f] mt-[1rem] break-all">
                    {{ productName }}
                  </s-text>
                </div>
              </div>
            </div>
          </div>
        </s-dialog-content-body>
      </s-dialog-content>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="stds-dialog-preview-16-9-image" />
</template>

<script setup lang="ts">
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import type { ImageLanguageType } from '@/types/common/file.type';

defineProps<{
  image16x9: ImageLanguageType[];
  productName: string;
}>();

const emits = defineEmits<{
  close: [];
}>();

const closeDialog = () => {
  emits('close');
};
</script>

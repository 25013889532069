<template>
  <div class="shrink-0 px-40 py-12 bg-[#E7EEFF] flex justify-center items-center gap-16">
    <template v-if="isExposed">
      <p class="text-md leading-lg text-gray880">
        {{
          $t('studio.prj_prod.this_prod.edit_page_not_status2_msg', {
            releaseStatus: $t(statusLabel)
          })
        }}
      </p>
      <s-button
        variant="primary"
        size="sm"
        class="min-w-[6.6rem]"
        @click="handleDuplicateProductPage"
      >
        {{ $t('studio.edit.btn') }}
      </s-button>
    </template>
    <!-- <template v-else>
      <p class="text-md leading-lg text-gray880">
        {{ $t('studio.prj_prod.this_prod.edit_page_not_open_msg') }}
      </p>
      <s-button variant="primary" size="sm" class="min-w-[6.6rem]">
        {{ $t('studio.prj_prod.this_prod.edit_modify_setting_btn') }}
      </s-button>
    </template> -->
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { duplicateProductPageApi } from '@/apis/product-page.api';
import { useApp } from '@/composables/useApp';
import { showConfirm } from '@/composables/useDialog';
import { PRODUCT_PAGES_PAGE_URL } from '@/constants/url.const';
import { PROGRESS_STATUS } from '@/enums/product-page.enum';
import type { ProductPageClonedType } from '@/types/product-page/product-page-model.type';
import { redirectTo } from '@/utils/common.util';
import { getStatusLabel } from '@/utils/product-page.util';

const { checkProductPermission } = useApp();

const props = defineProps<{
  progressStatus?: PROGRESS_STATUS;
  productName: string;
}>();

const { t, locale } = useI18n();

const route = useRoute();
const { pageId } = route.query;
const productNo = Number(route.params.productId);

const statusLabel = computed(() => {
  if (!props.progressStatus) {
    return '';
  }

  return getStatusLabel(props.progressStatus);
});

const isExposed = computed(() => {
  return (
    props.progressStatus === PROGRESS_STATUS.END ||
    props.progressStatus === PROGRESS_STATUS.REVIEW ||
    props.progressStatus === PROGRESS_STATUS.LIVE ||
    props.progressStatus === PROGRESS_STATUS.READY
  );
});

const handleDuplicateProductPage = async () => {
  if (!(await checkProductPermission())) {
    return;
  }
  let content;
  switch (props.progressStatus) {
    case PROGRESS_STATUS.END:
      content = t('studio.prod_page.suspended_page_edit_alert_msg');
      break;
    case PROGRESS_STATUS.REVIEW:
      content = t('studio.prod_page.under_review_page_edit_alert_msg');
      break;
    case PROGRESS_STATUS.LIVE:
      content = t('studio.prod_page.live_page_edit_alert_msg');
      break;
    case PROGRESS_STATUS.READY:
      content = t('studio.prod_page.ready_page_edit_alert_msg');
      break;
    default:
      content = '';
      break;
  }

  const dialog = await showConfirm({
    content,
    confirmLabel: t('studio.common.popup_case_cf_btn'),
    cancelLabel: t('studio.common.popup_case_cancel_btn'),
    cancelVariant: 'outline'
  });

  if (!dialog) {
    return;
  }

  try {
    const result = await duplicateProductPageApi({
      pageId: pageId as string,
      pageName: props.productName,
      productNoList: [productNo]
    });

    if (result?.clones && result?.clones.length > 0) {
      const foundProduct = result.clones.find(
        (clone: ProductPageClonedType) => clone.productNo === productNo
      );

      if (!foundProduct) {
        return;
      }

      const { pageId: newPageId } = foundProduct;
      const recentlyClonedPageIds = JSON.parse(
        localStorage.getItem('recentlyClonedPageIds') || '[]'
      );

      if (recentlyClonedPageIds && !recentlyClonedPageIds.includes(newPageId)) {
        recentlyClonedPageIds.push(newPageId);
        localStorage.setItem('recentlyClonedPageIds', JSON.stringify(recentlyClonedPageIds));
      }

      await redirectTo(`/${locale.value}${PRODUCT_PAGES_PAGE_URL}/EDIT`, {
        query: {
          pageId: newPageId
        },
        external: true
      });
    }
  } catch (error) {}
};
</script>

<template>
  <!-- If you want to have scroll please insert this to class of div below 'max-h-[30em] studio-scrollbar-4' -->
  <div class="wrapper h-auto">
    <sortable
      :key="name"
      :list="inputFiles"
      itemKey="id"
      :options="options"
      @update="onUpdateOrderFiles"
    >
      <template #item="{ element, index }">
        <div class="draggable">
          <input-product-file
            :key="element.id"
            :extensions="element.extensions"
            :type="element.type"
            :requiredMessage="
              element.type === 'link'
                ? $t('studio.prj_prod.this_prod.edit_gamepreview_spotlights_add_btn_alert2')
                : $t('studio.prj_prod.this_prod.edit_gamepreview_add_img_required msg')
            "
            :fileId="element.id"
            inList
            :resolution="element.resolution"
            :name="`${name}[${index}]`"
            :cropBox="element.cropBox"
            :fileNameWidth="130"
            :expandedWidth="640"
            :stencilSize="element.stencilBox"
            :dialogName="element.dialogName"
            :required="requiredCondition"
            isZoomLimit
            :showPixel="element.type !== 'link'"
            :isDisabled="isDisabled"
            :selectedLanguage="selectedLanguage"
            isHiddenAddLogo
            @removeFile="onRemoveFile"
            @addFile="addFile"
            @updateMovieThumbnail="($event) => updateMovieThumbnail($event, element)"
          />
        </div>
      </template>
    </sortable>
  </div>
</template>

<script lang="ts" setup>
import { cloneDeep, differenceWith, isEqual } from 'lodash-es';
import type { SortableEvent, SortableOptions } from 'sortablejs';
import type { AutoScrollOptions } from 'sortablejs/plugins';
import { Sortable } from 'sortablejs-vue3';
import { useFieldValue, useSetFieldValue } from 'vee-validate';
import type { ComputedRef, Ref } from 'vue';
import { computed, nextTick, ref, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import InputProductFile from '@/components/product-page/input-product-file.vue';
import { DEFAULT_LOCALE } from '@/constants/locale.const';
import { FileRemoveType } from '@/enums/input-product-file.enum';
import { useUserStore } from '@/stores/user.store';
import type { ImageLanguageType } from '@/types/common/file.type';
import type { ImageDimension } from '@/types/image/image.type';
import type { ProductFileType } from '@/types/product-page.type';

interface ImageLanguageRender extends ProductFileType {}

const props = defineProps<{
  modelValue?: string[];
  numberOfFiles: number;
  maxFileNumbers?: number;
  name: string;
  required?: boolean;
  selectedLanguage: string;
  isDisabled?: boolean;
}>();

const emits = defineEmits<{
  'update:modelValue': [v: ProductFileType[]];
}>();

const { t } = useI18n();

const maxFiles = computed(() => props.maxFileNumbers || 20);

const userStore = useUserStore();
const { selectedGroupInfo } = toRefs(userStore);

const options = computed<SortableOptions | AutoScrollOptions>(() => {
  return {
    draggable: '.draggable',
    animation: 150,
    easing: 'cubic-bezier(1, 0, 0, 1)',
    ghostClass: 'ghost',
    dragClass: 'drag',
    scroll: true,
    forceFallback: true,
    bubbleScroll: true,
    swapThreshold: 0.65,
    direction: 'vertical',
    disabled: props.isDisabled
  };
});

const fileTypes = ['link', 'video', 'image', 'award'];

const inputFiles = ref<ProductFileType[]>([]);
const inputFilesForm = useFieldValue<ImageLanguageRender[]>(props.name);
const setInputFilesForm = useSetFieldValue(props.name);

const sortedInputFilesForm = useFieldValue<ImageLanguageType[]>(`${props.name}Sorted`);
const setSortedInputFilesForm = useSetFieldValue(`${props.name}Sorted`);

const mapped = useFieldValue<{[key: string]: ImageLanguageType[];}>('mapped');
const setMapped = useSetFieldValue('mapped');

const mappedSorted = useFieldValue<{[key: string]: ImageLanguageType[];}>('mappedSorted');
const setMappedSorted = useSetFieldValue('mappedSorted');

const LINK_WIDTH = 860;
const LINK_HEIGHT = 483;
const EXHIBITION_VIDEO_IMAGE_STENCILBOX_WIDTH = 680;
const EXHIBITION_VIDEO_IMAGE_STENCILBOX_HEIGHT = 381;

const dialogName = ref<string>('');
const cropBox = ref<ImageDimension>({
  width: 0,
  height: 0
});
const extensions = ref<string[]>([]);
const stencilBox = ref<ImageDimension>({
  width: 0,
  height: 0
});
const resolution = ref<string>('');

const requiredCondition = computed(() => {
  if (props.name === 'imageAward') {
    return false;
  } else {
    if (props.selectedLanguage !== selectedGroupInfo.value?.languageCd) {
      return false;
    }

    if (inputFilesForm.value.some((file: ImageLanguageType) => file.fileId)) {
      return false;
    }
    return true;
  }
});

const addMoreFile = (
  value: string, // 'link' | 'video' | 'image' | 'award'
  { imgResolution, dName }: { imgResolution?: string; dName?: string }
) => {
  if (inputFiles.value.length >= maxFiles.value) {
    return;
  }
  const dimension = imgResolution ? imgResolution.split('x') : [];

  switch (value) {
    case 'link':
      extensions.value = ['jpg', 'png', 'gif', 'webp'];
      resolution.value = t(
        'studio.prj_prod.this_prod.edit_gamepreview_spotlights_video_thumbnail_link',
        { thumbnailSize: '860x483px' }
      );
      cropBox.value = {
        width: LINK_WIDTH,
        height: LINK_HEIGHT
      };
      stencilBox.value = {
        width: EXHIBITION_VIDEO_IMAGE_STENCILBOX_WIDTH,
        height: EXHIBITION_VIDEO_IMAGE_STENCILBOX_HEIGHT
      };
      dialogName.value = 'studio.prod_pg.img_edit_popup.img_type2_display_vid_thumbnail';

      break;
    case 'video':
      extensions.value = ['mp4'];
      resolution.value = '1920x520';
      cropBox.value = {
        width: 1920,
        height: 520
      };
      stencilBox.value = {
        width: EXHIBITION_VIDEO_IMAGE_STENCILBOX_WIDTH,
        height: EXHIBITION_VIDEO_IMAGE_STENCILBOX_HEIGHT
      };

      break;
    case 'image':
      extensions.value = ['jpg', 'png', 'gif', 'webp'];
      resolution.value = imgResolution ?? '860x483';
      cropBox.value = {
        width: imgResolution ? Number(dimension[0]) : LINK_WIDTH,
        height: imgResolution ? Number(dimension[1]) : LINK_HEIGHT
      };
      stencilBox.value = {
        width: EXHIBITION_VIDEO_IMAGE_STENCILBOX_WIDTH,
        height: EXHIBITION_VIDEO_IMAGE_STENCILBOX_HEIGHT
      };
      dialogName.value = dName ?? 'studio.prod_pg.img_edit_popup.img_type3_display_img';
      break;
    case 'award':
      extensions.value = ['jpg', 'png', 'gif', 'webp'];
      resolution.value = imgResolution ?? '1920x520';
      cropBox.value = {
        width: Number(dimension[0]),
        height: Number(dimension[1])
      };
      stencilBox.value = {
        width: EXHIBITION_VIDEO_IMAGE_STENCILBOX_WIDTH,
        height: EXHIBITION_VIDEO_IMAGE_STENCILBOX_HEIGHT
      };
      dialogName.value = dName ?? 'studio.prod_pg.img_edit_popup.img_type3_display_img';
      break;
    default:
      break;
  }

  inputFiles.value.push({
    id: inputFiles.value.length,
    type: value,
    order: inputFiles.value.length,
    extensions: extensions.value,
    resolution: resolution.value,
    cropBox: cropBox.value,
    stencilBox: stencilBox.value,
    dialogName: dialogName.value,
    lang: selectedGroupInfo.value?.languageCd || DEFAULT_LOCALE
  });

  emits('update:modelValue', inputFiles.value);
};

const resetFileData = (fileId: number | string) => {
  const id = Number(fileId);
  if (!isNaN(id) && inputFiles.value[id]) {
    inputFiles.value[id].image = '';
    inputFiles.value[id].linkCdn = '';
    inputFiles.value[id].movieThumbnailUrl = undefined;
  }
};

const resetMappedAndMappedSorted = (fileId: number | string) => {
  const id = Number(fileId);

  if (isNaN(id)) {
    return;
  }

  const newMapped = mapped.value;
  const newMappedSorted = mappedSorted.value;
  const selectedLang = mapped.value[props.selectedLanguage];
  const currentFile = selectedLang.find((_: ImageLanguageType, index: number) => index === id);
  if (currentFile) {
    const index = selectedLang.indexOf(currentFile);
    selectedLang.splice(index, 1);
    newMapped[props.selectedLanguage] = selectedLang;
    setMapped(newMapped);

    newMappedSorted[props.selectedLanguage] = selectedLang;
    setMappedSorted(newMappedSorted);
  }
};

const onRemoveFile = (fileId?: number | string, removeType?: FileRemoveType) => {
  const index = Number(fileId);
  if (!Number.isInteger(index)) {
    return;
  }
  resetFileData(index);
  if (props.name !== 'imageAward') {
    resetMappedAndMappedSorted(index);
  }
  if (removeType === FileRemoveType.REMOVE_FILE_ONLY) {
    return;
  }
  if (inputFiles.value[index]) {
    inputFiles.value[index].movieUrl = '';
  }
  inputFiles.value = inputFiles.value.filter((file: ImageLanguageRender) => {
    return file.id !== index;
  });
  inputFilesForm.value.splice(index, 1);
  if (props.name === 'imageAward') {
    inputFiles.value = inputFiles.value.map((f: ImageLanguageType, index: number) => ({
      type: 'award',
      extensions: ['jpg', 'png', 'gif', 'webp'],
      resolution: '860x483',
      cropBox: {
        width: LINK_WIDTH,
        height: LINK_HEIGHT
      },
      stencilBox: {
        width: EXHIBITION_VIDEO_IMAGE_STENCILBOX_WIDTH,
        height: EXHIBITION_VIDEO_IMAGE_STENCILBOX_HEIGHT
      },
      dialogName: 'studio.prod_pg.img_edit_popup.img_type8_award_img',
      ...f,
      id: index,
      order: index
    }));
  } else {
    inputFiles.value = inputFiles.value.map((file: ImageLanguageType, index: number) => ({
      type:
        file.fileType && fileTypes.includes(file.fileType)
          ? file.fileType
          : file.fileType?.includes('video')
            ? 'video'
            : file.movieUrl
              ? 'link'
              : 'image',
      extensions: file.fileType?.includes('video') ? ['mp4'] : ['jpg', 'png', 'gif', 'webp'],
      resolution: file.fileType?.includes('video')
        ? '1920x520'
        : file.movieUrl
          ? t('studio.prj_prod.this_prod.edit_gamepreview_spotlights_video_thumbnail_link', {
            thumbnailSize: '860x483'
          })
          : `${LINK_WIDTH}x${LINK_HEIGHT}`,
      cropBox: {
        width: file.fileType?.includes('video') ? 1920 : LINK_WIDTH,
        height: file.fileType?.includes('video') ? 520 : LINK_HEIGHT
      },
      stencilBox: {
        width: EXHIBITION_VIDEO_IMAGE_STENCILBOX_WIDTH,
        height: EXHIBITION_VIDEO_IMAGE_STENCILBOX_HEIGHT
      },
      dialogName: file.fileType?.includes('video')
        ? 'studio.prod_pg.img_edit_popup.img_type2_display_vid_thumbnail'
        : 'studio.prod_pg.img_edit_popup.img_type3_display_img',
      ...file,
      image: inputFiles.value[index]?.image,
      linkCdn: inputFiles.value[index]?.linkCdn,
      name: inputFiles.value[index]?.name,
      movieUrl: inputFilesForm.value[index]?.movieUrl,
      movieThumbnailUrl: inputFiles.value[index]?.movieThumbnailUrl,
      id: index,
      order: index
    }));
  }

  setInputFilesForm(inputFiles.value);
  setSortedInputFilesForm(inputFiles.value);

  if (props.name !== 'imageAward') {
    const mappedFiles = mapped.value;
    mappedFiles[props.selectedLanguage] = inputFiles.value;
    setMapped(mappedFiles);

    const mappedFilesSorted = mappedSorted.value;
    mappedFilesSorted[props.selectedLanguage] = inputFiles.value;
    setMappedSorted(mappedFilesSorted);
  }
};

const addFile = (e: ImageLanguageType, fileId?: number | string) => {
  // Add new file to the sorted list
  const index = Number(fileId);
  if (isNaN(index)) {
    return;
  }
  const newImage = { ...e, order: index };
  const updatedList = [
    ...(Array.isArray(sortedInputFilesForm.value) ? sortedInputFilesForm.value : []),
    newImage
  ];
  setSortedInputFilesForm(updatedList);

  if (props.name !== 'imageAward') {
    const mappedFiles = mapped.value;
    mappedFiles[props.selectedLanguage] = updatedList;
    setMapped(mappedFiles);

    const mappedSortedFiles = mappedSorted.value;
    mappedSortedFiles[props.selectedLanguage] = updatedList;
    setMappedSorted(mappedSortedFiles);
  }

  // Update the original list
  const currentImage = inputFiles.value[index];
  const updateCurrentImage = {
    ...newImage,
    id: newImage.order,
    type: currentImage.type,
    dialogName: currentImage.dialogName,
    resolution: currentImage.resolution,
    extensions: currentImage.extensions,
    cropBox: currentImage.cropBox,
    stencilBox: currentImage.stencilBox,
    movieUrl: inputFilesForm.value[index]?.movieUrl
  };
  inputFiles.value[index] = updateCurrentImage;
};

const swapElements = (
  arr: Ref<ImageLanguageType[]> | ComputedRef<ImageLanguageRender[]>,
  oldIndex: number,
  newIndex: number
) => {
  const temp = arr.value[newIndex];
  arr.value[newIndex] = arr.value[oldIndex];
  arr.value[oldIndex] = temp;
};

const onUpdateOrderFiles = async (event: SortableEvent) => {
  const oldIndex = event.oldIndex ?? 0;
  const newIndex = event.newIndex ?? 0;
  if (oldIndex === newIndex) {
    return;
  }
  swapElements(inputFiles, oldIndex, newIndex);
  await nextTick();
  inputFiles.value = inputFiles.value.map((file: ProductFileType, index: number) => ({
    ...file,
    id: index,
    order: index
  }));
  swapElements(inputFilesForm, oldIndex, newIndex);
  const inputFilesFormNew: Array<ImageLanguageType> = inputFilesForm.value.map(
    (file: ImageLanguageType, index: number) => ({
      ...file,
      order: index
    })
  );
  setInputFilesForm(inputFilesFormNew);
  setSortedInputFilesForm(inputFiles.value);

  if (props.name !== 'imageAward') {
    const mappedFiles = mapped.value;
    mappedFiles[props.selectedLanguage] = inputFilesFormNew;
    setMapped(mappedFiles);

    const mappedFilesSorted = mappedSorted.value;
    mappedFilesSorted[props.selectedLanguage] = inputFiles.value;
    setMappedSorted(mappedFilesSorted);
  }
};

defineExpose({
  addMoreFile,
  inputFiles
});

const mapInputFiles = () => {
  inputFiles.value = inputFilesForm.value.map((file: ImageLanguageType, index: number) => ({
    type:
          file.fileType && fileTypes.includes(file.fileType)
            ? file.fileType
            : file.fileType?.includes('video')
              ? 'video'
              : file.movieUrl || file.type === 'link'
                ? 'link'
                : 'image',
    extensions: file.fileType?.includes('video') ? ['mp4'] : ['jpg', 'png', 'gif', 'webp'],
    resolution: file.fileType?.includes('video')
      ? '1920x520'
      : file.movieUrl
        ? t('studio.prj_prod.this_prod.edit_gamepreview_spotlights_video_thumbnail_link', {
          thumbnailSize: '860x483'
        })
        : `${LINK_WIDTH}x${LINK_HEIGHT}`,
    cropBox: {
      width: file.fileType?.includes('video') ? 1920 : LINK_WIDTH,
      height: file.fileType?.includes('video') ? 520 : LINK_HEIGHT
    },
    stencilBox: {
      width: EXHIBITION_VIDEO_IMAGE_STENCILBOX_WIDTH,
      height: EXHIBITION_VIDEO_IMAGE_STENCILBOX_HEIGHT
    },
    dialogName:
          file.fileType?.includes('video') || file.type === 'link'
            ? 'studio.prod_pg.img_edit_popup.img_type2_display_vid_thumbnail'
            : 'studio.prod_pg.img_edit_popup.img_type3_display_img',
    ...file,
    id: index,
    order: index
  }));
};

const clonedInputFilesForm = computed(() => cloneDeep(inputFilesForm.value));

const updateMovieThumbnail = (movieThumbnailUrl: any, file: ProductFileType) => {
  const selectedLang = mapped.value[file.lang];
  const selectedLangSorted = mappedSorted.value[file.lang];

  const currentFile = selectedLang.find((f: ImageLanguageType) => f.fileId === file.fileId);
  const currentFileSorted = selectedLangSorted.find((f: ImageLanguageType) => f.fileId === file.fileId);

  if (currentFile && currentFileSorted) {
    const newMapped = mapped.value;
    const newMappedSorted = mappedSorted.value;

    currentFile.movieUrl = movieThumbnailUrl;
    currentFileSorted.movieUrl = movieThumbnailUrl;

    newMapped[file.lang] = selectedLang;
    setMapped(newMapped);

    newMappedSorted[file.lang] = selectedLangSorted;
    setMappedSorted(newMappedSorted);
  }
};

watch(() => clonedInputFilesForm.value, (newValue: ImageLanguageRender[], oldValue: ImageLanguageRender[]) => {
  const v1 = newValue.map((v: ImageLanguageRender) => v.movieUrl).filter((v?: string) => !!v);
  const v2 = oldValue.map((v: ImageLanguageRender) => v.movieUrl).filter((v?: string) => !!v);
  const diff = differenceWith(v1, v2, isEqual).length !== 0;
  if (diff && props.name !== 'imageAward') {
    const mappedFiles = mapped.value;
    mappedFiles[props.selectedLanguage] = newValue;
    setMapped(mappedFiles);

    const mappedFilesSorted = mappedSorted.value;
    mappedFilesSorted[props.selectedLanguage] = newValue;
    setMappedSorted(mappedFilesSorted);
  }
}, { deep: true });

watch(() => props.selectedLanguage, () => {
  mapInputFiles();
});

watch(
  () => inputFilesForm.value,
  () => {
    if (props.name === 'imageAward') {
      inputFiles.value = inputFilesForm.value.map((f: ImageLanguageType, index: number) => ({
        type: 'award',
        extensions: ['jpg', 'png', 'gif', 'webp'],
        resolution: '860x483',
        cropBox: {
          width: LINK_WIDTH,
          height: LINK_HEIGHT
        },
        stencilBox: {
          width: EXHIBITION_VIDEO_IMAGE_STENCILBOX_WIDTH,
          height: EXHIBITION_VIDEO_IMAGE_STENCILBOX_HEIGHT
        },
        dialogName: 'studio.prod_pg.img_edit_popup.img_type8_award_img',
        ...f,
        id: index,
        order: index
      }));
    } else {
      mapInputFiles();
    }
  },
  { once: true }
);
</script>

<template>
  <div class="flex h-full w-full flex-col bg-surface-elevation-1">
    <product-page-header
      :productName="productName"
      :isDisabled="isDisabled"
      :source="source"
      :pageRequest="pageRequest"
      :isOpenMultilanguage="isOpenMultilanguage"
      :collectionId="Number(collectionId)"
      :collectionPageId="collectionPageId"
      :collectionStatus="collectionStatus"
      @onSelectedMobile="isSelectedMobile = true"
      @onSelectedPc="isSelectedMobile = false"
      @onSubmit="validateCountries"
      @close="closeDialog"
      @onUpdateIsOpenMultilanguage="updateIsOpenMultilanguage"
    />

    <product-page-guide
      v-if="hasTopGuide && !isCollection"
      :productName="productName"
      :progressStatus="productPageDetail?.progressStatus"
    />

    <div class="relative flex flex-1">
      <div class="relative min-w-0 flex-1">
        <div class="dark studio-scrollbar-4 absolute inset-0 overflow-x-auto bg-gray900 px-[3rem]">
          <iframe
            ref="storePreviewIframeRef"
            frameborder="0"
            :src="iframeSrc"
            class="mx-auto overflow-hidden pointer-events-none select-none"
            :class="{
              'w-[37.5rem] ': isSelectedMobile,
              'w-[144rem]': !isSelectedMobile,
              invisible: !storePreviewMessageBridge?.isStorePreviewLoaded,
              visible: storePreviewMessageBridge?.isStorePreviewLoaded
            }"
            style="height: 5000px; background: #191919"
          ></iframe>
        </div>
      </div>

      <reject-reason-button
        v-if="rejectInfo"
        :rejectInfo="rejectInfo"
        class="absolute top-20 left-24"
      />
      <product-page-editor :isDisabled="isDisabled" @onTabChange="currentTab = $event" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { PropertyName } from 'lodash';
import { cloneDeep, debounce, differenceWith, isEqual, isMatchWith } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useFieldError, useFieldValue, useForm, useSetFieldError, useSetFieldTouched } from 'vee-validate';
import { computed, onBeforeUnmount, onMounted, ref, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import {
  createProductPageApi,
  modifyProductPageDetailApi,
  syncProductPagePreviewApi
} from '@/apis/product-page.api';
import { fetchProductStatusApi } from '@/apis/products.api';
import ProductPageEditor from '@/components/product-page/product-page-editor.vue';
import ProductPageGuide from '@/components/product-page/product-page-guide.vue';
import ProductPageHeader from '@/components/product-page/product-page-header.vue';
import RejectReasonButton from '@/components/product-page/reject-reason-button.vue';
import { showAlert, showConfirm } from '@/composables/useDialog';
import { CS_STOVE_LINK, MODE } from '@/constants/common.const';
import { STATUS_CODE } from '@/constants/error.const';
import {
  DEFAULT_LOCALE,
  DEFAULT_LOCALE_UPPERCASE,
  DEFAULT_LOCALES
} from '@/constants/locale.const';
import { EXPOSURE_TABS, SCREEN_TYPES } from '@/constants/product-page.const';
import { PLAN_STATUS, PRODUCT_TYPE_DETAIL } from '@/constants/products.const';
import { PRODUCT_HOME_PAGE_URL, PRODUCT_PAGES_PAGE_URL } from '@/constants/url.const';
import {
  COUNTRY_EXPOSURE,
  PROGRESS_STATUS,
  SettingLabels,
  START_DATE_OPTS
} from '@/enums/product-page.enum';
import { RuleNames } from '@/enums/validation.enum';
import { productPageMapper, productPageResponseMapper } from '@/mappers/product-page.mapper';
import useProductStore from '@/stores/product.store';
import { useProductPageStore } from '@/stores/product-page.store';
import { useUserStore } from '@/stores/user.store';
import type { ErrorResponse } from '@/types/common/common.type';
import type { ImageLanguageType } from '@/types/common/file.type';
import type { FormOptionGroup } from '@/types/common/form.type';
import type { ProductStatusResponse } from '@/types/product/product-response.type';
import type {
  ProductLinkType,
  ProductPageFormType,
  ProductPageLanguage,
  ProductSettingType
} from '@/types/product-page.type';
import { camelToSnake } from '@/utils/api.util';
import { getConfigs, redirectTo } from '@/utils/common.util';
import {
  type ActionCallback,
  type IframeMessage
} from '@/utils/iframe-message/iframe-message-bridge.type';
import { createIframeMessageBridge } from '@/utils/iframe-message/iframe-message-bridge.util';
import { hasInputAllRequiredFields } from '@/utils/product-page.util';
import { generateErrorMsg } from '@/utils/validation.util';

const props = defineProps<{
  source: string;
  productPageName?: string;
  pageSimpleId?: string;
  type: 'dialog' | 'page';
  childProductNos?: number[];
}>();

const emits = defineEmits<{
  onClose: [v?: ProductPageFormType];
}>();

const { t, locale } = useI18n();

const { productPageName } = toRefs(props);

const productStore = useProductStore();
const { currentProductDetailType, isProductWritable, product } = storeToRefs(productStore);

const DISABLE_STATUS = [
  PROGRESS_STATUS.REVIEW,
  PROGRESS_STATUS.END,
  PROGRESS_STATUS.LIVE,
  PROGRESS_STATUS.READY
];

const route = useRoute();
const prodName = ref<string>(productPageName.value || '');
const isOpenMultilanguage = ref(false);

/** Store preview message bridge */
// eslint-disable-next-line no-use-before-define
const storePreviewMessageBridge = ref<ReturnType<typeof createStorePreviewMessageBridge> | null>(
  null
);

const { mode, productId } = route.params;
const { pageId, productNo: collectionId } = route.query;

const isCollection = computed(() => collectionId !== undefined);

const productName = computed(() => {
  if (mode === MODE.CREATE && prodName.value) {
    return prodName.value;
  }
  return mode === MODE.EDIT ? prodName.value : String(route.query.product);
});
const productNo = ref<number>(Number(productId) || 0);

const productPageStore = useProductPageStore();
const userStore = useUserStore();

const { fetchProductPageInfo, setProductDescription, setPageEmpty } = productPageStore;

const { productPageDetail, showOnlyRequiredFields, productDescription, isClickedSave } =
  storeToRefs(productPageStore);
const { selectedGroupInfo } = storeToRefs(userStore);

const language = computed(() => {
  return DEFAULT_LOCALES[selectedGroupInfo.value?.languageCd || DEFAULT_LOCALE].code;
});

const isSelectedMobile = ref<boolean>(false);
const isDisabled = ref<boolean>(false);
const collectionPageId = ref<string>('');
const collectionStatus = ref<ProductStatusResponse>();

const hasTopGuide = computed(() => {
  return isDisabled.value;
});

const rejectInfo = ref<{
  title: string;
  reason: string;
}>();

const currentTab = ref<string>(EXPOSURE_TABS.REGION);

const content = ref<string>(
  `<p style="color: rgb(59, 61, 69); font-size: 13px; font-weight: 500;"> ${t(
    'studio.prj_prod.this_prod.edit_summary_place_holder1'
  )} </p> <ul> <li style="font-size: 13px; color: rgb(108, 111, 122); font-weight: 500;">${t(
    'studio.prj_prod.this_prod.edit_summary_place_holder2'
  )}</li> <li style="font-size: 13px; color: rgb(108, 111, 122); font-weight: 500;">${t(
    'studio.prj_prod.this_prod.edit_summary_place_holder3'
  )}</li> <li style="font-size: 13px; color: rgb(108, 111, 122); font-weight: 500;">${t(
    'studio.prj_prod.this_prod.edit_summary_place_holder4'
  )}</li> <li style="font-size: 13px; color: rgb(108, 111, 122); font-weight: 500;">${t(
    'studio.prj_prod.this_prod.edit_summary_place_holder5'
  )}</li> <li style="font-size: 13px; color: rgb(108, 111, 122); font-weight: 500;">${t(
    'studio.prj_prod.this_prod.edit_summary_place_holder6'
  )}</li> <li style="font-size: 13px; color: rgb(108, 111, 122); font-weight: 500;">${t(
    'studio.prj_prod.this_prod.edit_summary_place_holder7'
  )}</li></ul>`
);

const { values, resetForm, errors, validate } = useForm<ProductPageFormType>({
  initialValues: {
    productNo: productNo.value,
    exposureCountry: COUNTRY_EXPOSURE.ALL,
    startDateType: START_DATE_OPTS.IMMEDIATE,
    startDate: undefined,
    screenType: SCREEN_TYPES.BASIC,
    productPageBgImage: [
      {
        lang: language.value,
        image: ''
      }
    ],
    settings: [
      {
        label: SettingLabels.OS,
        setting: '',
        minimumRequirement: '',
        isRequired: isProductSpecificationRequired(SettingLabels.OS)
      },
      {
        label: SettingLabels.PROCESSOR,
        setting: '',
        minimumRequirement: '',
        isRequired: isProductSpecificationRequired(SettingLabels.PROCESSOR)
      },
      {
        label: SettingLabels.MEMORY,
        setting: '',
        minimumRequirement: '',
        isRequired: isProductSpecificationRequired(SettingLabels.MEMORY)
      },
      {
        label: SettingLabels.GRAPHIC,
        setting: '',
        minimumRequirement: '',
        isRequired: isProductSpecificationRequired(SettingLabels.GRAPHIC)
      },
      {
        label: SettingLabels.DIRECTX,
        setting: '',
        minimumRequirement: '',
        isRequired: isProductSpecificationRequired(SettingLabels.DIRECTX)
      },
      {
        label: SettingLabels.STORAGE,
        setting: '',
        minimumRequirement: '',
        isRequired: isProductSpecificationRequired(SettingLabels.STORAGE)
      },
      {
        label: SettingLabels.SOUND,
        setting: '',
        minimumRequirement: '',
        isRequired: isProductSpecificationRequired(SettingLabels.SOUND)
      }
    ],
    additionalSettings: [],
    regions: [],
    productDescriptions: content.value,
    oneLineIntroduction: '',
    subtitle: '',
    countryByRegion: [],
    countryBySearch: [],
    image1x1: [
      {
        lang: language.value,
        image: ''
      }
    ],
    image16x9: [
      {
        lang: language.value,
        image: ''
      }
    ],
    imageOneLineIntro: [
      {
        lang: language.value,
        image: ''
      }
    ],
    imageTitleBackground: [
      {
        lang: language.value,
        image: ''
      }
    ],
    links: [
      {
        linkId: '0',
        title: t('studio.prj_prod.this_prod.edit_additional_links_stove_cs'),
        url: CS_STOVE_LINK,
        isDefault: true,
        order: 0
      }
    ],
    supportedFeatures: [],
    supportedLanguages: [],
    supportedLanguageSettings: [],
    imageAward: [],
    productTags: [],
    exhibitionVideosAndImages: [],
    exhibitionVideosAndImagesSorted: [],
    productPageBgVideo: [
      {
        lang: language.value,
        image: ''
      }
    ],
    translatedContents: undefined,
    selectedLanguage: language.value
  },
  keepValuesOnUnmount: true
});

const pageRequest = computed(() => values);

const setIsFieldTouched = useSetFieldTouched('countryByRegion');
const setRegionError = useSetFieldError('countryByRegion');

const startDateError = useFieldError('startDate');
const setStartDateError = useSetFieldError('startDate');

const countriesBySearch = useFieldValue<FormOptionGroup<string>[]>('countryBySearch');
const setCountryBySearchError = useSetFieldError('countryBySearch');

const exposureCountry = useFieldValue<string>('exposureCountry');

const setExhError = useSetFieldError('exhibitionVideosAndImages');
const setSupLangError = useSetFieldError('supportedLanguages');

/** Returns the store preview origin. */
const getStoreOrigin = () => {
  /**
   * If local testing for the store is needed, set the local server path as shown below:
   * https://local.gate8.com:3000
   */
  return getConfigs().STORE_URL;
};

const storeHref = computed(() => {
  return `${getStoreOrigin()}/${selectedGroupInfo.value?.languageCd || DEFAULT_LOCALE}`;
});

/**
 * Sets the store preview iframe src.
 * @param previewKey Preview token
 */
const setIframeSrc = (previewKey: string) => {
  const iframeUrl = `${storeHref.value}/games/${
    productId ?? collectionId
  }?token=${previewKey}&preview_type=studio&preview_origin=${window.location.origin}`;

  iframeSrc.value = iframeUrl;
};

const updateIsOpenMultilanguage = (value: boolean) => {
  isOpenMultilanguage.value = value;
};

/**
 * Applies the store preview.
 * @description
 *  - Calls the preview API and applies the result if it exists.
 */
const onPreview = debounce(async (showLoading?: boolean, isIframeLoaded?: boolean) => {
  let request = productPageMapper(
    {
      ...pageRequest.value,
      productNo: values.productNo > 0 ? values.productNo : Number(collectionId),
      productDescriptions: productDescription.value
    },
    selectedGroupInfo.value?.languageCd.replace('-', '_').toUpperCase() || DEFAULT_LOCALE
  );

  if (isCollection.value) {
    request = {
      ...request,
      ...camelToSnake({
        collectionRelatedProduct: {
          subProductNos: props.childProductNos
        }
      })
    };
  }

  let previewKey = productPageDetail.value?.previewKey;

  if (isProductWritable.value) {
    const result = await syncProductPagePreviewApi(
      (pageId as string) || props.pageSimpleId || '',
      request,
      showLoading,
      selectedGroupInfo.value?.languageCd || DEFAULT_LOCALE
    );
    previewKey = result?.previewKey;
  }

  if (previewKey) {
    if (isIframeLoaded) {
      if (storePreviewMessageBridge.value?.isFallbackMode) {
        // Operates in fallback mode
        // This means that the iframe did not receive a load message.
        // In fallback mode, the iframe's src is updated each time to refresh the store page.
        setIframeSrc(previewKey);
      } else {
        // Operates in router replace mode
        // This means that the iframe has received a load message.
        // Sends a message to the store iframe to trigger a router replace
        // and reload the data within the store.
        storePreviewMessageBridge.value?.sendRouterReplaceMessage(previewKey);
      }
    } else {
      // Sets the initial iframe src.
      // The iframe src is set, but the iframe remains hidden.
      // The iframe will be displayed only after isIframeLoaded becomes true.
      setIframeSrc(previewKey);
    }
  }
}, 2000);

const clonedValues = computed(() => cloneDeep(values));

const compareSpecialKey = (value1: any, value2: any) => {
  const v1 = value1
    .filter(
      (v: ImageLanguageType) =>
        v.linkCdn && ((v.type === 'link' && v.movieUrl) || v.type !== 'link')
    )
    .map((v: ImageLanguageType) => {
      const r: any = { linkCdn: v.linkCdn };
      if (v.type === 'link') {
        r.movieUrl = v.movieUrl;
      }
      return r;
    });
  const v2 = value2
    .filter(
      (v: ImageLanguageType) =>
        v.linkCdn && ((v.type === 'link' && v.movieUrl) || v.type !== 'link')
    )
    .map((v: ImageLanguageType) => {
      const r: any = { linkCdn: v.linkCdn };
      if (v.type === 'link') {
        r.movieUrl = v.movieUrl;
      }
      return r;
    });
  if (v1.length !== v2.length) {
    return false;
  }
  const result = differenceWith(v1, v2, isEqual).length === 0;

  return result;
};

const compare = (value1: any, value2: any, key: PropertyName | undefined) => {
  if (key === 'mapped') {
    return compareSpecialKey(value1[language.value], value2[language.value]);
  }
  if (
    key === 'productPageBgImage' ||
    key === 'productPageBgVideo' ||
    key === 'image1x1' ||
    key === 'image16x9' ||
    key === 'imageTitleBackground' ||
    key === 'imageOneLineIntro' ||
    key === 'imageAward'
  ) {
    return compareSpecialKey(value1, value2);
  }
  if (key === 'productTags' || key === 'supportedFeatures' || key === 'supportedLanguages') {
    return isEqual(value1, value2);
  }
  if (
    key === 'exposureCountry' || key === 'subtitle' ||
    key === 'selectedLanguage' ||
    key === 'exhibitionVideosAndImagesSorted' || key === 'exhibitionVideosAndImages'
  ) {
    return true;
  }
};

/** iframe url */
const iframeSrc = ref<string>();

/** store preview iframe element */
const storePreviewIframeRef = ref<HTMLIFrameElement | null>(null);

function isProductSpecificationRequired(label: string): boolean {
  const SETTING_LABELS_REQUIRED: string[] = [
    SettingLabels.OS,
    SettingLabels.PROCESSOR,
    SettingLabels.MEMORY,
    SettingLabels.GRAPHIC,
    SettingLabels.DIRECTX,
    SettingLabels.STORAGE
  ];
  if (SETTING_LABELS_REQUIRED.includes(label)) {
    return !(currentProductDetailType.value === PRODUCT_TYPE_DETAIL.GAME_DLC);
  }
  return false;
}

const validateCountries = () => {
  if (exposureCountry.value === COUNTRY_EXPOSURE.ALL) {
    onSubmit();
    return;
  }

  if (currentTab.value === EXPOSURE_TABS.REGION) {
    validateRegionTab();

    return;
  }

  validateSearchTab();
};

const validateRegionTab = () => {
  // Set if region error message should be shown
  setIsFieldTouched(true);

  // Set error message for country search tab to empty
  setCountryBySearchError(generateErrorMsg('', RuleNames.REQUIRED));

  onSubmit();
};

const validateAllFields = async () => {
  await validate();
  if (countriesBySearch.value.length === 0 && values.exposureCountry === COUNTRY_EXPOSURE.SELECT) {
    if (currentTab.value === EXPOSURE_TABS.SEARCH) {
      setCountryBySearchError(
        generateErrorMsg(
          'studio.prj_prod.this_prod.edit_display_countries_checkbox_val1',
          RuleNames.REQUIRED
        )
      );
    }

    if (currentTab.value === EXPOSURE_TABS.REGION) {
      setIsFieldTouched(true);
      setRegionError(
        generateErrorMsg(
          'studio.prj_prod.this_prod.edit_display_countries_checkbox_val1',
          RuleNames.REQUIRED
        )
      );
    }
  }

  const selectedLang = Object.entries(values.mappedSorted ?? []).find((img: any) => img[0] === language.value);
  if (selectedLang && selectedLang[1].length === 0) {
    setExhError(
      generateErrorMsg(
        'studio.prj_prod.this_prod.edit_gamepreview_spotlights_add_btn_alert1',
        RuleNames.REQUIRED
      )
    );
  } else {
    setExhError(undefined);
  }

  if (values.supportedLanguages.length === 0) {
    setSupLangError(
      generateErrorMsg(
        'studio.prj_prod.this_prod.edit_additional_supporting_languages_val_msg1',
        RuleNames.REQUIRED
      )
    );
  }
};

const validateSearchTab = () => {
  // Set if region error message should NOT be shown
  setIsFieldTouched(false);

  if (countriesBySearch.value.length === 0) {
    setCountryBySearchError(
      generateErrorMsg(
        'studio.prj_prod.this_prod.edit_display_countries_checkbox_val1',
        RuleNames.REQUIRED
      )
    );
  } else {
    setCountryBySearchError(generateErrorMsg('', RuleNames.REQUIRED));
  }
  onSubmit();
};

const errorBeforeSubmit = (): boolean => {
  const err = cloneDeep(errors.value);
  if (
    exposureCountry.value !== COUNTRY_EXPOSURE.ALL &&
    err.countryByRegion &&
    err.countryBySearch
  ) {
    return true;
  } else if (!isCollection.value) {
    delete err.countryByRegion;
    delete err.countryBySearch;
    delete err.regions;
    delete err.countryByRegion;

    if (Object.keys(err).length > 0) {
      return true;
    }
  } else if (isCollection.value) {
    delete err.countryByRegion;
    delete err.countryBySearch;
    delete err.regions;
    delete err.countryByRegion;
    delete err.supportedLanguages;
    for (let i = 0; i < 6; ++i) {
      delete err[`settings[${i}].minimumRequirement` as keyof typeof err];
    }

    if (Object.keys(err).length > 0) {
      return true;
    }
  }

  return false;
};

const isMultipleLanguagesBaseFieldsChanged = () => {
  const baseLangData = values.translatedContents?.find(
    (data: ProductPageLanguage<ProductLinkType[]>) => data.langCode === language.value
  );
  if (baseLangData) {
    if (baseLangData.oneLineIntroduction !== values.oneLineIntroduction) {
      return true;
    }
    if (baseLangData.productDescriptions !== productDescription.value) {
      return true;
    }
    if (baseLangData.subtitle !== values.subtitle) {
      return true;
    }
    if (baseLangData.links.length !== values.links.length) {
      return true;
    }
    for (let i = 0; i < values.links.length; ++i) {
      if (baseLangData.links[i].title !== values.links[i].title) {
        return true;
      }
    }
  }
  return false;
};

const showSuccessDialog = async (saveCompleteMessage: string) => {
  const dialog = await showConfirm({
    content: saveCompleteMessage,
    confirmLabel: t('studio.redirect_prod_home_btn'),
    cancelLabel: t('studio.common.popup_case_close_btn'),
    cancelVariant: 'outline'
  });

  if (!dialog) {
    showOnlyRequiredFields.value = false;
    isClickedSave.value = false;
    await redirectTo(`/${locale.value}${PRODUCT_PAGES_PAGE_URL}`, { external: true });
  } else {
    showOnlyRequiredFields.value = false;
    isClickedSave.value = false;
    await redirectTo(PRODUCT_HOME_PAGE_URL);
  }
};

const submitPage = async (saveCompleteMessage: string) => {
  const valuesWithFormat: ProductPageFormType = JSON.parse(JSON.stringify(values));
  valuesWithFormat.productDescriptions = productDescription.value;

  const request = productPageMapper(
    valuesWithFormat,
    selectedGroupInfo.value?.languageCd.replace('-', '_').toUpperCase() || DEFAULT_LOCALE
  );

  if (errorBeforeSubmit()) {
    await validate();
    return;
  }

  let isSuccessFul = false;
  if (props.type === 'page') {
    if (mode === MODE.CREATE) {
      const res = await createProductPageApi(request);

      if (res) {
        isSuccessFul = true;
      }
    }
    if (mode === MODE.EDIT) {
      try {
        const res = await modifyProductPageDetailApi(pageId as string, request);

        if (res === null) {
          isSuccessFul = true;
        }
      } catch (error) {
        const err = error as ErrorResponse;
        if (err.statusCode === STATUS_CODE.PRODUCT_PAGE_DUPLICATED_RELEASE_DATE) {
          setStartDateError(generateErrorMsg('studio.product_page.page_display_date_duplicate_msg'));
        }
      }
    }
    if (isSuccessFul) {
      if (product.value?.planStatus !== PLAN_STATUS.RELEASE &&
      product.value?.planStatus !== PLAN_STATUS.PAGE_OPEN && product.value?.planStatus !== PLAN_STATUS.PRE_PURCHASE) {
        showSuccessDialog(saveCompleteMessage);
      } else {
        showOnlyRequiredFields.value = false;
        isClickedSave.value = false;
        await redirectTo(`/${locale.value}${PRODUCT_PAGES_PAGE_URL}`, { external: true });
      }
    }
    return;
  }
  showOnlyRequiredFields.value = false;
  if (props.pageSimpleId) {
    try {
      const res = await modifyProductPageDetailApi(props.pageSimpleId, request);
      if (res === null && !isCollection.value) {
        await showAlert({
          content: saveCompleteMessage,
          confirmLabel: t('studio.common.popup_case_cf_btn')
        });
      }
    } catch (error) {
      const err = error as ErrorResponse;
      if (err.statusCode === STATUS_CODE.PRODUCT_PAGE_DUPLICATED_RELEASE_DATE) {
        setStartDateError(generateErrorMsg('studio.product_page.page_display_date_duplicate_msg'));
      }
    }
  }

  emits('onClose', values);
};

const showRequiredFieldsMissing = async (confirmMessage: string) => {
  await showAlert({
    content: confirmMessage
  });

  isClickedSave.value = true;
};

const onSubmit = async () => {
  await validateAllFields();

  if (startDateError.value) {
    return;
  }

  if (!hasInputAllRequiredFields(values, isCollection.value, selectedGroupInfo.value?.languageCd)) {
    await showRequiredFieldsMissing(t('studio.common.popup_case_ai_trans_required_field'));
    return;
  }

  if (!hasInputMultilanguage()) {
    await showAlert({
      content: t('studio.common.popup_case_ai_not_entered')
    });

    isOpenMultilanguage.value = true;
    return;
  }
  if (isMultipleLanguagesBaseFieldsChanged()) {
    const result = await showConfirm({
      content: t('studio.common.popup_case_ai_modified'),
      confirmLabel: t('studio.common.popup_case_save_btn'),
      cancelVariant: 'outline'
    });
    if (!result) {
      return;
    }
  }

  if (
    product.value?.planStatus === PLAN_STATUS.RELEASE ||
    product.value?.planStatus === PLAN_STATUS.PAGE_OPEN
  ) {
    if (values.startDateType === START_DATE_OPTS.IMMEDIATE) {
      const dialog = await showConfirm({
        content: t('studio.prj_prod.this_proj.this_prod.edit_sidebar_display_start_date_pop1_msg'),
        confirmLabel: t('studio.common.popup_case_cf_btn'),
        cancelVariant: 'outline',
        cancelLabel: t('studio.common.popup_case_cancel_btn')
      });
      if (!dialog) {
        return;
      }
    } else {
      const dialog = await showConfirm({
        content: t('studio.prj_prod.this_proj.this_prod.edit_sidebar_display_start_date_pop2_msg'),
        confirmLabel: t('studio.common.popup_case_cf_btn'),
        cancelVariant: 'outline',
        cancelLabel: t('studio.common.popup_case_cancel_btn')
      });
      if (!dialog) {
        return;
      }
    }
  }

  await submitPage(t('studio.product_page.save_complete_popup'));
};

/**
 * Creates the store preview message bridge.
 * @description
 *  - When `init` is called, if the `STORE_PRODUCT_PREVIEW_PAGE_LOADED` message
 *    is not received from the store iframe within the specified time,
 *    the internal fallback logic is triggered.
 *  - The fallback logic works as follows:
 *    - If the message is received within the specified time:
 *      - Operates using the `postMessage` method.
 *    - If the message is not received within the specified time:
 *      - Operates by refreshing the iframe.
 */
const createStorePreviewMessageBridge = () => {
  // Guard to prevent duplicate calls
  storePreviewMessageBridge.value?.destroy();

  // Guard for cases where the iframe reference is not ready
  if (!storePreviewIframeRef.value?.contentWindow) {
    return;
  }

  /** Fallback timer ID */
  let fallbackTimerId: ReturnType<typeof setTimeout>;

  /** Fallback mode */
  const isFallbackMode = ref(false);

  /** Store preview load completion status */
  const isStorePreviewLoaded = ref(false);

  /** Iframe message bridge */
  const iframeMessageBridge = createIframeMessageBridge({
    currentWindow: window,
    targetOrigin: getStoreOrigin(),
    targetWindow: storePreviewIframeRef.value.contentWindow,
    debug: false
  });

  /** Mapping object for received messages */
  const receiveMessageMapper: { [key: string]: ActionCallback } = {
    /**
     * Function called when the store product preview page loads
     * @description
     *  - When the message is received, the following actions are performed:
     *    - Mark preview as ready
     *    - Disable fallback mode
     *    - Clear the fallback timer
     * @param message Message
     */
    STORE_PRODUCT_PREVIEW_PAGE_LOADED: (_message: IframeMessage) => {
      isStorePreviewLoaded.value = true;
      isFallbackMode.value = false;
      clearTimeout(fallbackTimerId);
    }
  };

  // Bind message event handlers
  for (const key in receiveMessageMapper) {
    iframeMessageBridge.on(key, receiveMessageMapper[key]);
  }

  return {
    /**
     * Initializes the iframe message bridge.
     * @description
     *  - Initializes the iframe message bridge.
     *  - If no message is received from the iframe within the specified time, the following actions are performed:
     *    - Enable forced execution of iframe readiness
     *    - Mark iframe as ready
     */
    init: () => {
      iframeMessageBridge.init();
      fallbackTimerId = setTimeout(() => {
        isStorePreviewLoaded.value = true;
        isFallbackMode.value = true;
      }, 1000 * 20);
    },
    /** Cleans up the iframe message bridge. */
    destroy: () => {
      iframeMessageBridge.destroy();
    },
    /**
     * Sends a router navigation message to the iframe.
     * @param previewKey Preview token
     */
    sendRouterReplaceMessage: (previewKey: string) => {
      iframeMessageBridge.send({
        action: 'STUDIO_PREVIEW_CHANGE_PREVIEW_KEY',
        data: {
          previewKey
        }
      });
    },
    /** Getter for checking if fallback mode is enabled */
    get isFallbackMode() {
      return isFallbackMode.value;
    },
    /** Getter for checking if the store preview is loaded */
    get isStorePreviewLoaded() {
      return isStorePreviewLoaded.value;
    }
  };
};

const closeDialog = () => {
  emits('onClose');
};

const hasInputMultilanguage = () => {
  if (!values.translatedContents) {
    return false;
  }

  if (values.oneLineIntroduction) {
    for (let i = 0; i < values.translatedContents.length; ++i) {
      if (!values.translatedContents[i].oneLineIntroduction) {
        return false;
      }
    }
  }
  return true;
};

const init = async () => {
  isDisabled.value = !isProductWritable.value;
  if (pageId) {
    await fetchProductPageInfo(pageId as string);
  }

  if (props.pageSimpleId) {
    await fetchProductPageInfo(props.pageSimpleId);
    setProductDescription('');
  }

  const detail = productPageDetail.value;
  if (
    detail?.progressStatus &&
    DISABLE_STATUS.includes(detail.progressStatus) &&
    !isCollection.value
  ) {
    isDisabled.value = true;
  }

  if (isCollection.value) {
    const status = await fetchProductStatusApi(Number(collectionId));

    if (status) {
      collectionStatus.value = status;
    }
  }

  if (detail) {
    prodName.value = detail.pageName;
    collectionPageId.value = detail.pageId;
    rejectInfo.value = detail?.rejectInfo;

    const result = productPageResponseMapper(
      detail,
      selectedGroupInfo?.value?.languageCd.replace('-', '_').toUpperCase() ||
        DEFAULT_LOCALE_UPPERCASE
    );

    if (result.productDescriptions) {
      setProductDescription(result.productDescriptions);
    }

    if (detail.pageUpdatedAt && !result.productDescriptions) {
      setProductDescription('');
    }

    if (Array.isArray(result.settings)) {
      result.settings.forEach((setting: ProductSettingType) => {
        setting.isRequired = isProductSpecificationRequired(setting.label);
      });
    }

    if (isCollection.value) {
      result.startDateType = START_DATE_OPTS.IMMEDIATE;
    }

    resetForm({ values: result });
  }

  const recentlyClonedPageIds = JSON.parse(localStorage.getItem('recentlyClonedPageIds') || '[]');
  if (recentlyClonedPageIds.includes(pageId as string)) {
    const dialog = await showAlert({
      content: t('studio.prod_page.live_page_duplicate_completed_alert'),
      confirmLabel: t('studio.common.popup_case_cf_btn')
    });

    if (dialog) {
      localStorage.setItem(
        'recentlyClonedPageIds',
        JSON.stringify(recentlyClonedPageIds.filter((id: string) => id !== pageId))
      );
    }
  }
};

init();

watch(
  clonedValues,
  (newValue: ProductPageFormType, oldValue: ProductPageFormType) => {
    const equal = isMatchWith(newValue, oldValue, compare);
    if (!equal) {
      onPreview(true, storePreviewMessageBridge.value?.isStorePreviewLoaded);
    }
  },
  { deep: true }
);

onMounted(() => {
  storePreviewMessageBridge.value = createStorePreviewMessageBridge();
  storePreviewMessageBridge.value?.init();
});

onBeforeUnmount(() => {
  storePreviewMessageBridge.value?.destroy();
  setPageEmpty();
});
</script>

<template>
  <div class="mt-20 flex h-220 flex-col gap-4 rounded-2xl bg-[var(--stds-glob-color-gray40)] pt-16">
    <div class="shrink-0 px-20">
      <input-text
        v-model="searchText"
        :placeholder="
          $t('studio.prj_prod.this_prod.edit_display_countries_search_country_placeholder')
        "
        :isDisabled="isDisabled"
        searchable
        @update:modelValue="onSearch(searchText)"
      />
    </div>
    <div v-if="searchResult.length" class="studio-scrollbar-4 flex-1 pl-20 mb-20">
      <checkbox-group
        :options="searchResult"
        class="flex flex-col mt-12 gap-y-8 h-full"
        name="countryBySearch"
        :rules="{
          required: {
            value: true,
            message: $t('studio.prj_prod.this_prod.edit_display_countries_checkbox_val1'),
            showError: false
          }
        }"
        size="sm"
        notTranslate
      />
    </div>

    <div v-else class="flex flex-col gap-y-12 h-[10em] overflow-auto">
      <div class="flex items-center justify-center h-[10em]">
        <span class="text-[#ccc]">{{
          $t('studio.prj_prod.this_prod.edit_additional_info_tags_search_no_msg')
        }}</span>
      </div>
    </div>
  </div>
  <div v-show="countriesList.length" class="mt-12 flex flex-wrap gap-12">
    <selectable-tag-list
      :tagList="defaultList"
      name="countryBySearch"
      hasLimit
      :maxTag="10"
      isDeletableTag
    />
  </div>
  <st-error-message v-if="error && JSON.parse(error).message" :errorMessage="error" />
</template>

<script lang="ts" setup>
import { useFieldError, useFieldValue, useSetFieldValue } from 'vee-validate';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import SelectableTagList from '@/components/common/selectable-tag-list.vue';
import StErrorMessage from '@/components/common/st-error-message.vue';
import CheckboxGroup from '@/components/validation/checkbox-group.vue';
import InputText from '@/components/validation/input-text.vue';
import { COUNTRY_LIST } from '@/constants/national-list.const';
import type { CommonCountry } from '@/types/common/common.type';
import type { FormOptionGroup } from '@/types/common/form.type';
import type { CountryType, RegionType } from '@/types/product-page.type';

const countriesList = useFieldValue<string[]>('countryBySearch');
const regionList = useFieldValue<RegionType[]>('countryByRegion');
const error = useFieldError('countryBySearch');
const setCountryList = useSetFieldValue<string[]>('countryBySearch');

const { t } = useI18n();

const searchText = ref<string>('');
const defaultList = COUNTRY_LIST.map((country: CommonCountry) => ({
  value: country.code,
  label: t(`studio.national_code.${country.code.toLowerCase()}`)
}));
const searchResult = ref<FormOptionGroup<string>[]>(defaultList);

defineProps<{
  isDisabled?: boolean;
}>();

const onSearch = async (searchText: string) => {
  if (searchText) {
    searchResult.value = defaultList.filter((country: FormOptionGroup<string>) =>
      country.label.toLowerCase().includes(searchText.toLowerCase())
    );
  }
};

watch(searchText, (value: string) => {
  if (!value) {
    searchResult.value = defaultList;
  }
});

watch(
  () => regionList.value,
  () => {
    const countries: string[] = [];
    regionList.value.forEach((region: RegionType) => {
      region.countries.forEach((country: CountryType) => {
        if (country.isChecked) {
          countries.push(country.code);
        }
      });
    });

    setCountryList(countries);
  },
  {
    deep: true
  }
);
</script>

<template>
  <div>
    <s-contents-popup
      :distance="4"
      :offset="[0, 0]"
      placement="bottom-start"
      trigger="click"
      :minWidth="328"
      :flipOnUpdate="false"
      @shown="onRejectReasonLayerShown"
      @hidden="onRejectReasonLayerHidden"
    >
      <template #target>
        <s-button
          size="md"
          variant="white"
          iconClass="!mr-0 ml-4"
          :icon="
            isRejectReasonActive ? 'ic-v2-control-arrow-up-line' : 'ic-v2-control-arrow-down-line'
          "
          class="flex-row-reverse !rounded-full"
        >
          {{ $t('studio.prj_prod.this_prod.edit.pop_declined_items_msg') }}
        </s-button>
      </template>
      <template #contents>
        <s-contents-popup-content-body>
          <div class="space-y-16">
            <div class="flex flex-col gap-4">
              <p class="text-xl font-bold leading-lg text-on-surface-elevation-2">
                {{ rejectInfo?.title }}
              </p>
              <safe-html tag="span" :html="rejectInfo?.reason || ''" />
            </div>
          </div>
        </s-contents-popup-content-body>
      </template>
    </s-contents-popup>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';

import SafeHtml from '@/components/common/safe-html.vue';

defineProps<{
  rejectInfo?: {
    title: string;
    reason: string;
  };
}>();

const isRejectReasonActive = ref(false);
const onRejectReasonLayerShown = () => {
  isRejectReasonActive.value = true;
};
const onRejectReasonLayerHidden = () => {
  isRejectReasonActive.value = false;
};
</script>

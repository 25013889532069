<template>
  <s-checkbox
    id="chk-all"
    v-model="selectedAllRegions"
    size="sm"
    align="middle"
    class="py-8 font-bold text-on-surface-elevation-2"
    @update:modelValue="onAllRegionCheck($event)"
  >
    <span class="text-lg font-bold leading-lg text-on-surface-elevation-2">{{
      $t('studio.prj_prod.this_prod.edit_display_countries_regions_all')
    }}</span>
  </s-checkbox>
  <div v-for="r in regionList" :key="r.name">
    <div
      class="flex items-center justify-between rounded-2xl bg-[var(--stds-glob-color-gray40)] py-[1rem] px-16"
    >
      <checkbox
        v-model="r.isChecked"
        :options="{
          size: 'sm',
          align: 'middle',
          class: 'inline-flex flex-1'
        }"
        :disabled="isDisabled"
        @update:modelValue="onRegionCheck(r.name, $event)"
      >
        <span class="text-sm font-medium leading-md text-on-surface-elevation-4">
          {{ $t(`studio.prj_prod.this_prod.edit_display_countries_regions_${r.name}`) }}
        </span>
      </checkbox>

      <div class="flex items-center gap-12">
        <span
          class="shrink-0 text-sm font-medium leading-md"
          :class="
            countryCount(r.countries) === 0
              ? 'text-on-surface-elevation-4'
              : 'text-on-surface-elevation-2'
          "
        >
          {{ countryCount(r.countries) }}
        </span>

        <button
          type="button"
          class="inline-flex shrink-0"
          :disabled="isDisabled"
          @click="showCountries(r.name)"
        >
          <s-icon
            size="3xl"
            icon="ic-v2-control-arrow-right-line"
            class="text-on-surface-elevation-1"
            :class="{ 'rotate-90': checkRegionSelected(r.name) }"
          />
        </button>
      </div>
    </div>
    <div v-if="checkRegionSelected(r.name)" class="flex gap-8 mt-12 flex-wrap">
      <tag
        v-for="country in r.countries"
        :key="country.code"
        class="flex cursor-pointer items-center rounded-full px-12 text-xs font-medium leading-xs text-on-surface-elevation-2 border-1 border-solid bg-white border-[var(--stds-glob-color-gray60)]"
        variant="outline"
        :class="{ '!bg-blue50 !border-blue300 !text-brand-primary': country.isChecked }"
        @click="onSelectCountry(country.name, r.name)"
      >
        {{ $t(`studio.national_code.${country.code.toLowerCase()}`) }}
      </tag>
    </div>
  </div>
  <st-error-message
    v-if="(isFieldTouched || isClickedSave) && error && JSON.parse(error).message"
    :errorMessage="error"
  />
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import {
  useFieldValue,
  useIsFieldTouched,
  useSetFieldError,
  useSetFieldTouched,
  useSetFieldValue
} from 'vee-validate';
import { computed, ref, toRefs, watch } from 'vue';

import StErrorMessage from '@/components/common/st-error-message.vue';
import Tag from '@/components/common/tag.vue';
import Checkbox from '@/components/validation/checkbox.vue';
import { EXPOSURE_TABS } from '@/constants/product-page.const';
import { RuleNames } from '@/enums/validation.enum';
import { useProductPageStore } from '@/stores/product-page.store';
import type { CountryType, RegionType } from '@/types/product-page.type';
import { generateErrorMsg } from '@/utils/validation.util';

const props = defineProps<{
  isDisabled: boolean;
  currentTab: string;
}>();

const { isDisabled, currentTab } = toRefs(props);

const productPageStore = useProductPageStore();
const { isClickedSave } = storeToRefs(productPageStore);

const countriesList = useFieldValue<string[]>('countryBySearch');
const regionList = useFieldValue<RegionType[]>('countryByRegion');
const setRegionsValue = useSetFieldValue<RegionType[]>('countryByRegion');
const setIsFieldTouched = useSetFieldTouched('countryByRegion');
const setRegionError = useSetFieldError('countryByRegion');
const isFieldTouched = useIsFieldTouched('countryByRegion');

const showedCountriesOfRegions = ref<string[]>([]);
const error = ref<string>();

const selectedAllRegions = computed(() => {
  return regionList.value.every((r: RegionType) => r.isChecked);
});

const showCountries = (region: string) => {
  if (showedCountriesOfRegions.value.includes(region)) {
    showedCountriesOfRegions.value = showedCountriesOfRegions.value.filter(
      (r: string) => r !== region
    );
    return;
  }
  showedCountriesOfRegions.value.push(region);
};

const checkRegionSelected = (region: string) => {
  return showedCountriesOfRegions.value.includes(region);
};

const onSelectCountry = (country: string, region: string) => {
  const foundRegion = regionList.value.find((r: RegionType) => r.name === region);
  if (foundRegion) {
    const foundCountry = foundRegion.countries.find((c: CountryType) => c.name === country);
    if (foundCountry) {
      foundCountry.isChecked = !foundCountry.isChecked;
    }

    if (foundRegion?.countries.every((c: CountryType) => c.isChecked)) {
      foundRegion.isChecked = true;
    } else {
      foundRegion.isChecked = false;
    }
  }

  setIsFieldTouched(true);
  setRegionsValue(JSON.parse(JSON.stringify(regionList.value)));
};

const onRegionCheck = (region: string, event: boolean) => {
  const foundRegion = regionList.value.find((r: RegionType) => r.name === region);
  if (foundRegion) {
    foundRegion.isChecked = event;
    foundRegion.countries.forEach((c: CountryType) => {
      c.isChecked = event;
    });
  }

  setIsFieldTouched(true);
  setRegionsValue(JSON.parse(JSON.stringify(regionList.value)));
};

const onAllRegionCheck = (event: boolean) => {
  const res = regionList.value.map((region: RegionType) => {
    region.isChecked = event;
    region.countries.forEach((country: CountryType) => {
      country.isChecked = event;
    });

    return region;
  });

  setIsFieldTouched(true);
  setRegionsValue(JSON.parse(JSON.stringify(res)));
};

const countryCount = (countries: CountryType[]) => {
  return countries.filter((c: CountryType) => c.isChecked).length;
};

const setRegionListError = () => {
  error.value = generateErrorMsg(
    'studio.prj_prod.this_prod.edit_display_countries_checkbox_val1',
    RuleNames.REQUIRED
  );
};

watch(
  () => regionList.value,
  () => {
    if (
      regionList.value.some((region: RegionType) =>
        region.countries.some((country: CountryType) => country.isChecked)
      )
    ) {
      error.value = generateErrorMsg('', RuleNames.REQUIRED);

      return;
    }

    setRegionListError();
  },
  { immediate: false }
);

// Only shows error if the user is on the region tab
watch(
  () => currentTab.value,
  () => {
    if (currentTab.value === EXPOSURE_TABS.REGION) {
      setRegionListError();

      return;
    }

    error.value = generateErrorMsg('', RuleNames.REQUIRED);
  }
);

watch(
  () => error.value,
  (error?: string) => {
    setRegionError(error);
  },
  { immediate: false }
);

// Only shows error if the user is on the region tab
watch(
  () => currentTab.value,
  () => {
    if (currentTab.value === EXPOSURE_TABS.REGION) {
      setRegionListError();

      return;
    }

    error.value = generateErrorMsg('', RuleNames.REQUIRED);
  }
);

watch(
  () => error.value,
  (error?: string) => {
    setRegionError(error);
  },
  { immediate: false }
);

watch(
  () => countriesList.value,
  () => {
    regionList.value.forEach((region: RegionType) => {
      region.isChecked = region.countries.every((country: CountryType) => {
        return countriesList.value.includes(country.code);
      });

      region.countries.forEach((country: CountryType) => {
        country.isChecked = !!countriesList.value.includes(country.code);
      });
    });
  }
);
</script>

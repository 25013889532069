<template>
  <div class="space-y-20 divide-y divide-solid divide-inverse-elevation-5">
    <div class="pt-20">
      <st-form-title
        :formTitle="$t('studio.prj_prod.this_prod.edit_texts_headline_title')"
        required
      >
        <s-contents-popup
          :distance="0"
          :offset="[0, 0]"
          placement="bottom-end"
          trigger="mouseenter focus"
          :minWidth="328"
          :maxWidth="328"
          :flipOnUpdate="false"
          :popperOptions="undefined"
        >
          <template #target>
            <div class="flex items-center gap-4">
              <p class="text-xs leading-xs text-on-surface-elevation-4">
                {{ $t('studio.prj_prod.this_prod.edit_writing_guidance_btn') }}
              </p>
              <s-icon
                icon="ic-v2-state-info-circle-line"
                size="xl"
                class="text-on-surface-elevation-4 flex"
              />
            </div>
          </template>
          <template #contents>
            <s-contents-popup-content-body>
              <p class="my-8 text-lg font-medium leading-lg text-on-surface-elevation-2">
                {{ $t('studio.prj_prod.this_prod.edit_writing_guidance_info1') }}
              </p>
              <div class="mt-4 rounded-xl bg-[var(--stds-glob-color-gray40)] p-12">
                <p class="text-sm font-medium leading-md">
                  👍 {{ $t('studio.prj_prod.this_prod.edit_writing_guidance_info2') }}
                </p>
                <ul class="mt-4">
                  <li
                    class="relative pl-20 text-sm font-medium leading-md text-on-surface-elevation-3 before:absolute before:left-[.6rem] before:top-[.9rem] before:h-4 before:w-4 before:rounded-full before:bg-[currentColor]"
                  >
                    {{ $t('studio.prj_prod.this_prod.edit_writing_guidance_info3') }}
                  </li>
                  <li
                    class="relative pl-20 text-sm font-medium leading-md text-on-surface-elevation-3 before:absolute before:left-[.6rem] before:top-[.9rem] before:h-4 before:w-4 before:rounded-full before:bg-[currentColor]"
                  >
                    {{ $t('studio.prj_prod.this_prod.edit_writing_guidance_info4') }}
                  </li>
                  <li
                    class="relative pl-20 text-sm font-medium leading-md text-on-surface-elevation-3 before:absolute before:left-[.6rem] before:top-[.9rem] before:h-4 before:w-4 before:rounded-full before:bg-[currentColor]"
                  >
                    {{ $t('studio.prj_prod.this_prod.edit_writing_guidance_info5') }}
                  </li>
                </ul>
                <p class="mt-8 text-sm font-medium leading-md">
                  😢 {{ $t('studio.prj_prod.this_prod.edit_writing_guidance_info6') }}
                </p>
                <ul class="mt-4">
                  <li
                    class="relative pl-20 text-sm font-medium leading-md text-on-surface-elevation-3 before:absolute before:left-[.6rem] before:top-[.9rem] before:h-4 before:w-4 before:rounded-full before:bg-[currentColor]"
                  >
                    {{ $t('studio.prj_prod.this_prod.edit_writing_guidance_info7') }}
                  </li>
                  <li
                    class="relative pl-20 text-sm font-medium leading-md text-on-surface-elevation-3 before:absolute before:left-[.6rem] before:top-[.9rem] before:h-4 before:w-4 before:rounded-full before:bg-[currentColor]"
                  >
                    {{ $t('studio.prj_prod.this_prod.edit_writing_guidance_info8') }}
                  </li>
                  <li
                    class="relative pl-20 text-sm font-medium leading-md text-on-surface-elevation-3 before:absolute before:left-[.6rem] before:top-[.9rem] before:h-4 before:w-4 before:rounded-full before:bg-[currentColor]"
                  >
                    {{ $t('studio.prj_prod.this_prod.edit_writing_guidance_info9') }}
                  </li>
                </ul>
              </div>
            </s-contents-popup-content-body>
          </template>
        </s-contents-popup>
      </st-form-title>
      <st-textarea
        :rules="{
          regex: {
            regex: REGEX_PRODUCT_NAME,
            message: $t('studio.prj_prod.this_prod.home_product_setting_basic_msg4_invalid')
          },
          required: {
            value: true,
            message: $t('studio.prj_prod.this_prod.edit_texts_headline_alert1'),
            showError: isClickedSave
          },
          max_length: {
            length: WORD_COUNT_LIMIT,
            message: $t('studio.common.def_key.exceed', { length: WORD_COUNT_LIMIT })
          }
        }"
        :placeholder="$t('studio.prj_prod.this_prod.edit_texts_headline_place_holder')"
        name="oneLineIntroduction"
        :maxLength="WORD_COUNT_LIMIT.toString()"
        containerClass="h-[11rem] rounded-xl"
        :disabled="isDisabled"
        size="sm"
      />
    </div>

    <div v-show="!showOnlyRequiredFields" class="pt-20">
      <st-form-title :formTitle="$t('studio.prj_prod.this_prod.edit_summary_title')">
        <div class="flex items-center gap-4">
          <s-button
            size="xs"
            variant="outline"
            icon="ic-v2-media-av-fullscreen-fill"
            :isDisabled="isDisabled || isCodeView"
            @click="handleFullScreen"
          >
            {{ $t('studio.prj_prod.this_prod.edit_summary_title_full_btn') }}
          </s-button>
          <s-tooltip
            arrow
            :content="$t('studio.prj_prod.this_prod.edit_summary_info_hover_msg')"
            :duration="0"
            :offset="[8, 4]"
            useFlip
            flipOnUpdate
            placement="bottom-end"
            trigger="mouseenter focus"
            :theme="'studio-tooltip'"
            :zIndex="2500"
          >
            <template #target>
              <s-icon
                size="xl"
                icon="ic-v2-state-info-circle-line"
                class="h-16 text-on-surface-elevation-4"
              />
            </template>
          </s-tooltip>
        </div>
      </st-form-title>
      <div class="mt-8">
        <text-editor
          ref="textEditorRef"
          v-model="productDes"
          name="productDescriptions"
          :toolbarButtons="TOOLBAR_BUTTONS_FROALA"
          :config="editorConfig"
          :charCounterMax="PRODUCT_DESCRIPTION_WORD_COUNT_LIMIT"
          class="h-[52rem]"
          :rules="{
            max_char_count: {
              length: PRODUCT_DESCRIPTION_WORD_COUNT_LIMIT,
              charCount: productDescriptionCharCount,
              message: $t('studio.common.def_key.exceed', {
                length: formatNumberMultipleWithCommas(PRODUCT_DESCRIPTION_WORD_COUNT_LIMIT)
              })
            }
          }"
          :disabled="isDisabled"
          :isChooseOneFileFromStorage="false"
          isSingleEditor
          :uploadVideoComputerRules="{
            allowedExtensions: [VIDEO_TYPE.MP4],
            maxFileSize: ONE_HUNDRED_MEGABYTES
          }"
          @onClickBtnHtmlCodeBlock="isCodeView = $event"
          @update:modelValue="() => setProductDescription(productDes)"
          @wordCount="onProductDescriptionCharCount"
        />
      </div>
    </div>

    <!-- <div v-show="!showOnlyRequiredFields" class="pt-20">
      <st-form-title :formTitle="$t('studio.prj_prod.this_prod.edit_texts_subtitle_title')" />
      <safe-html
        tag="p"
        class="text-placeholder text-xs leading-xs"
        :html="$t('studio.prj_prod.this_prod.edit_texts_subtitle_guide')"
      />
      <input-text
        name="subtitle"
        :placeholder="$t('studio.prj_prod.this_prod.edit_texts_subtitle_place_holder')"
        containerClass="mt-8"
        maxLength="30"
        :rules="{
          max_length: 30
        }"
        :disabled="isDisabled"
      />
    </div> -->
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useSetFieldValue } from 'vee-validate';
import { onMounted, ref, shallowRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

// import SafeHtml from '@/components/common/safe-html.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import FullScreen from '@/components/early-access/fullscreen.vue';
// import InputText from '@/components/validation/input-text.vue';
import TextEditor from '@/components/validation/text-editor.vue';
import StTextarea from '@/components/validation/textarea.vue';
import { showDialog } from '@/composables/useDialog';
import { ONE_HUNDRED_MEGABYTES, VIDEO_TYPE } from '@/constants/file.const';
import { TOOLBAR_BUTTONS_FROALA } from '@/constants/froala.const';
import { REGEX_PRODUCT_NAME } from '@/constants/regex.const';
import { useProductPageStore } from '@/stores/product-page.store';
import { formatNumberMultipleWithCommas } from '@/utils/currency.util';
import { initFullScreenEditor } from '@/utils/froala.util';
import { getHtmlTextByCompare } from '@/utils/string.util';

defineProps<{
  isDisabled?: boolean;
}>();

const WORD_COUNT_LIMIT = 700;
const PRODUCT_DESCRIPTION_WORD_COUNT_LIMIT = 70000;

const { t } = useI18n();

const productPageStore = useProductPageStore();
const { showOnlyRequiredFields, productDescription, defaultDescription, isClickedSave } =
  storeToRefs(productPageStore);

const { setProductDescription } = productPageStore;

const setValues = useSetFieldValue<string>('productDescriptions');

const isCodeView = ref<boolean>(false);
const textEditorRef = ref();
const productDes = ref<string>(productDescription.value);
const productDescriptionCharCount = ref<number>(0);

const editorConfig = {
  attribution: false,
  placeholderText: t('studio.prj_prod.this_prod.edit_summary_place_holder0'),
  wordCounterCount: false,
  charCounterCount: true,
  quickInsertTags: [''],
  height: '30em',
  events: {
    initialized: function() {
      const editor = this as any;
      initFullScreenEditor(editor);
    }
  }
};

const handleFullScreen = async () => {
  const { valueHtml, valueRaw } = await showDialog<{ valueRaw: string; valueHtml: string }>({
    component: shallowRef(FullScreen),
    props: {
      content: productDes.value,
      toolbarButtons: TOOLBAR_BUTTONS_FROALA,
      wordCountLimit: PRODUCT_DESCRIPTION_WORD_COUNT_LIMIT,
      isChooseOneFileFromStorage: false,
      title: t('studio.prj_prod.this_prod.edit_summary_title')
    },
    severity: 'info'
  });

  const content = getHtmlTextByCompare(valueHtml, valueRaw);
  productDes.value = content;
  setValues(content);
};

const onProductDescriptionCharCount = (count: number) => {
  productDescriptionCharCount.value = count;
};

onMounted(() => {
  const productPageEditorElement = document.getElementById('product-page-editor');

  productPageEditorElement?.addEventListener('wheel', () => {
    textEditorRef.value?.hideAllPopups();
  });

  if (!productDescription.value || productDescription.value === '') {
    setProductDescription(defaultDescription.value);
  }
});

watch(
  () => productDescription.value,
  () => {
    productDes.value = productDescription.value;
  }
);
</script>

<template>
  <div class="rounded-xl bg-surface-elevation-1 p-16 shadow-[0_.6rem_1.4rem_0_rgba(0,51,150,.07)]">
    <button type="button" class="flex w-full gap-12 text-left" @click="isOpenedBox = !isOpenedBox">
      <span class="flex-1 text-xl font-bold leading-xl text-on-surface-elevation-1">
        {{ productPageEditorTitle }}
      </span>
      <s-icon
        size="4xl"
        :icon="isOpenedBox ? 'ic-v2-control-arrow-up-line' : 'ic-v2-control-arrow-down-line'"
        class="mt-[.1rem] shrink-0 self-start text-on-surface-elevation-1"
      />
    </button>
    <div v-show="isOpenedBox">
      <slot></slot>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';

const isOpenedBox = ref(true);

defineProps<{
  productPageEditorTitle: string;
}>();
</script>

<template>
  <div class="space-y-20 divide-y divide-solid divide-inverse-elevation-5">
    <div v-show="!showOnlyRequiredFields" class="pt-20">
      <st-form-title
        :formTitle="$t('studio.prj_prod.this_prod.edit_additional_info_tags_title')"
        count
        :currentCount="productTags?.length ?? 0"
        :maxCount="MAX_TAGS"
      />
      <p class="text-placeholder text-xs leading-xs">
        <safe-html
          tag="span"
          :html="$t('studio.prj_prod.this_prod.edit_additional_info_tags_guide')"
        />
      </p>
      <div class="mt-8 flex flex-col gap-8">
        <input-text
          v-model="searchText"
          :placeholder="
            $t('studio.prj_prod.this_prod.edit_additional_info_tags_search_place_holder')
          "
          searchable
          size="sm"
          :disabled="isDisabled"
          @search="onSearch(searchText)"
          @clear="onSearch('')"
        />
        <selectable-tag-list
          v-show="searchResult.length"
          name="productTags"
          :tagList="searchResult"
          hasLimit
          :maxTag="MAX_TAGS"
          :disabled="isDisabled"
        />

        <p
          v-if="searchResult.length === 0"
          class="flex min-h-80 h-full items-center justify-center text-center text-sm leading-md text-on-surface-elevation-2"
        >
          {{ $t('studio.prj_prod.this_prod.edit_additional_info_tags_search_no_msg') }}
        </p>

        <selectable-tag-list
          v-show="productTags.length"
          class="mt-8 py-16"
          name="productTags"
          :tagList="searchResult"
          isDeletableTag
          draggable
          :disabled="isDisabled"
        />
        <p
          v-if="productTags.length"
          class="relative pl-16 text-placeholder text-xs leading-xs before:absolute before:top-[.7rem] before:left-[.6rem] before:w-[.3rem] before:h-[.3rem] before:rounded-full before:bg-[currentColor]"
        >
          {{ $t('studio.prj_prod.this_prod.edit_additional_info_tags_search_msg1') }}
        </p>
      </div>
    </div>
    <div v-show="!showOnlyRequiredFields" class="pt-20">
      <st-form-title
        :formTitle="$t('studio.prj_prod.this_prod.edit_additional_supporting_title')"
      />
      <div class="mt-8 flex flex-wrap gap-8 py-4">
        <selectable-tag-list
          name="supportedFeatures"
          :tagList="supportedFeatures"
          :disabled="isDisabled"
        />
      </div>

      <selectable-tag-list
        v-show="supFeatures.length"
        class="mt-8 py-16"
        name="supportedFeatures"
        :tagList="supportedFeatures"
        isDeletableTag
        draggable
        :disabled="isDisabled"
      />
    </div>

    <div class="pt-20">
      <st-form-title
        :formTitle="$t('studio.prj_prod.this_prod.edit_additional_supporting_languages_title')"
        required
      />
      <div class="mt-8 flex flex-wrap gap-8 py-4">
        <selectable-tag-list
          name="supportedLanguages"
          :tagList="supportedLanguages"
          :disabled="isDisabled"
        />
      </div>
      <support-language :isDisabled="isDisabled" />
      <st-error-message v-if="supportedLanguagesError" :errorMessage="supportedLanguagesError" />
    </div>

    <div v-show="!isCollection" class="pt-20">
      <st-form-title
        :formTitle="$t('studio.prj_prod.this_prod.edit_additional_requirements_title')"
        :required="currentProductDetailType !== PRODUCT_TYPE_DETAIL.GAME_DLC"
      >
        <s-switch
          id="chk-recommended"
          v-model="isEnableRecommendSettings"
          size="sm"
          :labelText="$t('studio.prj_prod.this_prod.edit_additional_requirements_toggle')"
          class="inline-flex shrink-0 [&>*>*]:text-on-surface-elevation-2"
          isLabelVisible
          :isDisabled="isDisabled"
        />
      </st-form-title>

      <input-setting-list
        :enableSpecifications="isEnableRecommendSettings"
        :isDisabled="isDisabled"
      />
    </div>

    <div v-show="!showOnlyRequiredFields" class="pt-20">
      <st-form-title
        :formTitle="$t('studio.prj_prod.this_prod.edit_additional_links_title')"
        count
        :currentCount="numberOfLinks"
        :maxCount="MAX_LINKS"
      />
      <input-link-list
        ref="inputLinksRef"
        :isDisabled="isDisabled"
        :numberOfLinks="numberOfLinks"
        :maxLinkNumber="MAX_LINKS"
      />
      <s-button
        size="sm"
        variant="outline"
        icon="ic-v2-control-add-line"
        iconClass="!mr-0 ml-2"
        class="flex-row-reverse w-full mt-8"
        :isDisabled="isDisabled || numberOfLinks === MAX_LINKS"
        @click="onAddLink"
      >
        {{ $t('studio.prj_prod.this_prod.edit_additional_links_add_btn') }}
      </s-button>
    </div>

    <div v-show="!showOnlyRequiredFields && !isCollection" class="pt-20">
      <st-form-title
        :formTitle="$t('studio.prj_prod.this_prod.edit_additional_awards_image_title')"
        count
        :currentCount="numberOfFiles"
        :maxCount="MAX_IMGS"
      />
      <input-product-file-list
        ref="inputProductAwardImagesRef"
        :numberOfFiles="numberOfFiles"
        :maxFileNumbers="MAX_IMGS"
        name="imageAward"
        :cropBox="{ width: 860, height: 483 }"
      />
      <s-button
        size="sm"
        variant="outline"
        icon="ic-v2-control-add-line"
        iconClass="!mr-0 ml-2"
        class="flex-row-reverse w-full mt-8 js-btn-add-file"
        :isDisabled="isDisabled || numberOfFiles === MAX_IMGS"
        @click="onAddFile"
      >
        {{ $t('studio.prj_prod.this_prod.edit_additional_awards_image_add_btn') }}
      </s-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useFieldError, useFieldValue } from 'vee-validate';
import { computed, ref, toRefs, watch } from 'vue';
import { useRoute } from 'vue-router';

import { fetchTagsApi } from '@/apis/tag.api';
import SafeHtml from '@/components/common/safe-html.vue';
import SelectableTagList from '@/components/common/selectable-tag-list.vue';
import StErrorMessage from '@/components/common/st-error-message.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import InputLinkList from '@/components/product-page/input-link-list.vue';
import InputProductFileList from '@/components/product-page/input-product-file-list.vue';
import InputSettingList from '@/components/product-page/input-setting-list.vue';
import SupportLanguage from '@/components/product-page/support-language.vue';
import InputText from '@/components/validation/input-text.vue';
import { SUPPORTED_FEATURES_LIST, SUPPORTED_LANGUAGES_LIST } from '@/constants/product-page.const';
import { PRODUCT_TYPE_DETAIL } from '@/constants/products.const';
import { TagType } from '@/enums/tag.enum';
import useProductStore from '@/stores/product.store';
import { useProductPageStore } from '@/stores/product-page.store';
import type { ImageLanguageType } from '@/types/common/file.type';
import type { FormOption } from '@/types/common/form.type';
import type { SystemSpecsOther } from '@/types/product-page/product-page-model.type';
import type { Tag } from '@/types/tags/tags-response.type';

defineProps<{ isDisabled: boolean }>();

const MAX_TAGS = 10;
const MAX_LINKS = 5;
const MAX_IMGS = 20;

const route = useRoute();
const { productNo: collectionId } = route.query;

const productPageStore = useProductPageStore();
const { productPageDetail, showOnlyRequiredFields, isEnableRecommendSettings } =
  storeToRefs(productPageStore);

const productStore = useProductStore();
const { currentProductDetailType } = toRefs(productStore);

const imageAward = useFieldValue<ImageLanguageType[]>('imageAward');
// const setImageAward = useSetFieldValue<ImageLanguageType[]>('imageAward');

const supFeatures = useFieldValue<FormOption[]>('supportedFeatures');
const supportedFeatures = ref<FormOption[]>(SUPPORTED_FEATURES_LIST);
const supportedLanguages = ref<FormOption[]>(SUPPORTED_LANGUAGES_LIST);
const supportedLanguagesError = useFieldError('supportedLanguages');

const inputProductAwardImagesRef = ref<InstanceType<typeof InputProductFileList>>();
const inputLinksRef = ref<InstanceType<typeof InputLinkList>>();

const searchText = ref<string>('');
const searchResult = ref<FormOption[]>([]);

const isCollection = computed(() => collectionId !== undefined);

const numberOfFiles = computed(() => {
  if (inputProductAwardImagesRef.value?.inputFiles.length === 0) {
    return 0;
  }
  return inputProductAwardImagesRef.value?.inputFiles.length || imageAward.value.length || 0;
});

const numberOfLinks = computed(() => {
  return inputLinksRef.value?.inputLinks.length || 0;
});

const productTags = useFieldValue<FormOption[]>('productTags');

const onSearch = async (searchText: string) => {
  const tags = await fetchTagsApi({ q: searchText, tagType: TagType.Feature });

  if (tags) {
    searchResult.value = tags.map((tag: Tag) => ({
      value: tag.tagNo.toString(),
      label: tag.tagName
    }));
  }
};

const onAddFile = () => {
  inputProductAwardImagesRef.value?.addMoreFile('award', {
    imgResolution: '860x483',
    dName: 'studio.prod_pg.img_edit_popup.img_type8_award_img'
  });

  // Add scroll logic
  setTimeout(() => {
    const button = document.querySelector('.js-btn-add-file');
    button?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, 100);
};

const onAddLink = () => {
  inputLinksRef.value?.addMoreLink();
};

const init = async () => {
  await onSearch('');
};

init();

watch(
  () => productPageDetail.value,
  () => {
    isEnableRecommendSettings.value = Object.values(
      productPageDetail.value?.systemSpecs?.recommended || {}
    ).some((it: string | SystemSpecsOther[]) => {
      if (typeof it === 'string') {
        return !!it;
      } else if (it !== null) {
        return it.some((item: SystemSpecsOther) => !!item.value);
      }
      return false;
    });
  }
);
</script>

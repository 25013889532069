<template>
  <div>
    <s-dialog :open="true" size="md" :to="`stds-dialog-all-countries`">
      <s-dialog-overlay />
      <s-dialog-panel class="h-auto">
        <st-dialog-header @clickClose="closeDialog">
          {{ $t('studio.prj_prod.this_prod.edit_display_countries_view_btn') }}
        </st-dialog-header>
        <s-dialog-content>
          <s-dialog-content-body>
            <div class="pb-40 pt-20">
              <ul class="flex flex-wrap gap-x-[1rem] gap-y-24">
                <li
                  v-for="country in COUNTRY_LIST"
                  :key="country.code"
                  class="w-[calc((100%-2rem)/3)] text-lg leading-lg text-on-surface-elevation-2"
                >
                  {{ $t(`studio.national_code.${country.code.toLowerCase()}`) }}
                </li>
              </ul>
            </div>
          </s-dialog-content-body>
          <s-dialog-footer />
        </s-dialog-content>
      </s-dialog-panel>
    </s-dialog>

    <s-portal-target :name="`stds-dialog-all-countries`" />
  </div>
</template>
<script setup lang="ts">
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import { COUNTRY_LIST } from '@/constants/national-list.const';

const emit = defineEmits<{
  close: [];
}>();

const closeDialog = () => {
  emit('close');
};
</script>

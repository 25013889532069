<template>
  <div class="mt-8 space-y-0 divide-y divide-solid divide-gray60">
    <input-setting
      v-for="(defSet, index) in settings"
      :key="index"
      type="default"
      :isRequired="defSet.isRequired"
      :enableSpecifications="enableSpecifications"
      :setting="defSet"
      :settingIndex="index"
      :isDisabled="isDisabled"
    />

    <input-setting
      v-for="(additional, index) in additionalSettings"
      :key="additional.id || index"
      type="additional"
      :enableSpecifications="enableSpecifications"
      :settingId="additional.id"
      :settingIndex="index"
      :setting="additional"
      :isRequired="additional.isRequired"
      :isDisabled="isDisabled"
      @removeAdditionalSetting="onRemoveAdditionalSetting"
    />
  </div>
  <s-button
    size="sm"
    variant="outline"
    icon="ic-v2-control-add-line"
    iconClass="!mr-0 ml-2"
    class="flex-row-reverse w-full mt-8"
    :isDisabled="isDisabled || additionalSettings.length >= 5"
    @click="onAddSetting"
  >
    {{ $t('studio.prj_prod.this_prod.edit_additional_requirements_add_btn') }}
  </s-button>
</template>

<script setup lang="ts">
import { useFieldValue, useSetFieldValue } from 'vee-validate';
import { ref, watch } from 'vue';

import InputSetting from '@/components/product-page/input-setting.vue';
import type { ProductSettingType } from '@/types/product-page.type';
import { generateUUID } from '@/utils/uuid.util';

defineProps<{
  enableSpecifications: boolean;
  isDisabled?: boolean;
}>();

const settings = useFieldValue<ProductSettingType[]>('settings');
const additionalSettingsField = useFieldValue<ProductSettingType[]>('additionalSettings');
const setAdditionalSettingsField = useSetFieldValue<ProductSettingType[]>('additionalSettings');

const additionalSettings = ref<ProductSettingType[]>([]);

const onAddSetting = () => {
  if (additionalSettings.value.length >= 5) {
    return;
  }

  const newSetting: ProductSettingType = {
    label: '',
    setting: '',
    minimumRequirement: '',
    isRequired: false,
    id: `additional_setting_${generateUUID()}`
  };

  additionalSettings.value.push(newSetting);
};

const onRemoveAdditionalSetting = (id: string) => {
  if (!id) {
    return;
  }

  const newAdditionalSettings = additionalSettings.value.filter(
    (setting: ProductSettingType) => setting.id !== id
  );
  additionalSettings.value = newAdditionalSettings;
  setAdditionalSettingsField(newAdditionalSettings);
};

watch(
  additionalSettingsField,
  (newValue: ProductSettingType[]) => {
    additionalSettings.value = newValue;
  },
  {
    once: true
  }
);
</script>

<template>
  <div
    class="grid h-72 shrink-0 grid-cols-[1fr_21.6rem_1fr] gap-20 bg-background-variant-2 pl-16 pr-20"
  >
    <div class="flex items-center">
      <button type="button" class="shrink-0" @click="onCloseForm">
        <s-icon
          size="4xl"
          icon="ic-v2-control-close-line"
          class="align-top text-on-surface-elevation-1"
          srOnlyText="Close"
        />
      </button>
      <h2 class="ml-12 text-2xl font-bold leading-xl">{{ name }}</h2>
      <span
        class="ml-12 flex h-[2.2rem] shrink-0 items-center rounded-full bg-[var(--stds-glob-color-gray60)] px-[.6rem] text-2xs leading-xs text-gray500"
      >
        {{ $t(language) ?? $t('studio.ai_translation.base_lang_ko') }}
      </span>
      <s-tooltip
        arrow
        :content="`<p class='text-center'>${$t(
          'studio.prj_prod.this_prod.edit_info_hover_msg'
        )}</p>`"
        :duration="0"
        useFlip
        flipOnUpdate
        placement="bottom"
        trigger="mouseenter focus"
        :theme="'studio-tooltip'"
        :zIndex="2500"
        :allowHTML="true"
        class="ml-4 shrink-0"
      >
        <template #target>
          <s-icon
            size="xl"
            icon="ic-v2-state-info-circle-line"
            class="text-on-surface-elevation-4"
          />
        </template>
      </s-tooltip>
    </div>
    <div class="flex items-center justify-center">
      <div class="flex rounded-full bg-disabled-variant-3 p-2">
        <button
          class="inline-flex h-32 w-[10.6rem] items-center justify-center rounded-full"
          :class="
            isSelectedMobile
              ? 'text-on-surface-elevation-1'
              : 'bg-neutral-variant-1 text-surface-elevation-1'
          "
          @click="$emit('onSelectedPc', (isSelectedMobile = false))"
        >
          <s-icon size="3xl" icon="ic-v2-hardware-desktop-line" />
        </button>
        <button
          class="inline-flex h-32 w-[10.6rem] items-center justify-center rounded-full"
          :class="
            isSelectedMobile
              ? 'bg-neutral-variant-1 text-surface-elevation-1'
              : 'text-on-surface-elevation-1'
          "
          @click="emits('onSelectedMobile', (isSelectedMobile = true))"
        >
          <s-icon size="3xl" icon="ic-v2-hardware-smartphone-line" />
        </button>
      </div>
    </div>
    <div class="flex items-center justify-end gap-8">
      <s-button variant="outline" size="md" class="min-w-[11.6rem]" @click="onPreviewNewTab">
        {{ $t('studio.prj_prod.this_prod.edit_preview_btn') }}
      </s-button>
      <s-button variant="secondary" size="md" class="min-w-[11.6rem]" @click="onMultilanguageSetting">
        {{ $t('studio.prj_prod.this_prod.edit_mls_btn') }}
      </s-button>
      <s-button variant="primary" size="md" class="min-w-[11.6rem]" :isDisabled="isDisabled === true" @click="onSubmit">
        {{ submitTitleButton }}
      </s-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useFieldValue, useSetFieldValue } from 'vee-validate';
import { computed, ref, shallowRef, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { generateProductPagePreviewKeyApi } from '@/apis/product-page.api';
import ProductPageMultilingualDialog from '@/components/product-page/product-page-multilingual-dialog.vue';
import { showAlert, showDialog } from '@/composables/useDialog';
import { TRANSLATE_LANGUAGES_SETTINGS } from '@/constants/common.const';
import { DEFAULT_LOCALE } from '@/constants/locale.const';
import { PLAN_STATUS, SALE_STATUS } from '@/constants/products.const';
import { useProductPageStore } from '@/stores/product-page.store';
import { useUserStore } from '@/stores/user.store';
import type { LanguageModel } from '@/types/common/common.type';
import type { ProductStatusResponse } from '@/types/product/product-response.type';
import type { ProductLinkType, ProductPageLanguage } from '@/types/product-page.type';
import { getConfigs, redirectTo } from '@/utils/common.util';

const props = defineProps<{
  isDisabled: boolean;
  productName: string;
  editingLanguage?: string;
  source: string;
  pageRequest: any;
  isOpenMultilanguage: boolean;
  collectionId?: number;
  collectionPageId?: string;
  collectionStatus?: ProductStatusResponse;
}>();

const emits = defineEmits<{
  onSelectedMobile: [v: boolean];
  onSelectedPc: [v: boolean];
  onSubmit: [v?: Event];
  close: [];
  validate: [];
  onUpdateIsOpenMultilanguage: [v: boolean];
}>();

const { t, locale } = useI18n();

const { productName } = toRefs(props);
const userStore = useUserStore();

const route = useRoute();
const { pageId } = route.query;
const { productId } = route.params;

const { selectedGroupInfo } = storeToRefs(userStore);

const name = computed(() => productName.value);
const storeHref = computed(() => {
  const { STORE_URL } = getConfigs();
  return `${STORE_URL}/${selectedGroupInfo.value?.languageCd || DEFAULT_LOCALE}`;
});

const submitTitleButton = computed(() => {
  if (props.collectionId) {
    if (
      (props.collectionStatus?.planStatus === PLAN_STATUS.PRE_PURCHASE ||
        props.collectionStatus?.planStatus === PLAN_STATUS.RELEASE) &&
      props.collectionStatus.saleStatus !== SALE_STATUS.END &&
      props.collectionStatus.saleStatus !== SALE_STATUS.STOP
    ) {
      return t('studio.common.live_apply_btn');
    }
  }

  return t('studio.common.popup_case_save_btn');
});

const language = computed(() => {
  return (
    TRANSLATE_LANGUAGES_SETTINGS.find(
      (lang: LanguageModel) => lang.langCode === selectedGroupInfo.value?.languageCd
    )?.langTitle || ''
  );
});

const productPageStore = useProductPageStore();
const { productDescription, isClickedSave } = storeToRefs(productPageStore);

const { setProductDescription } = productPageStore;

const isSelectedMobile = ref<boolean>(false);
const oneLineIntroduction = useFieldValue<string>('oneLineIntroduction');
const subtitle = useFieldValue<string>('subtitle');
const links = useFieldValue<ProductLinkType[]>('links');
const translatedContents =
  useFieldValue<ProductPageLanguage<ProductLinkType[]>[]>('translatedContents');

const setTranslatedContent =
  useSetFieldValue<ProductPageLanguage<ProductLinkType[]>[]>('translatedContents');

const languages = computed<ProductPageLanguage<ProductLinkType[]>[]>(() => {
  const defaultLanguageCd = selectedGroupInfo.value?.languageCd ?? 'ko';
  const allSupportedLanguages = ['ko', 'en', 'zh-cn', 'zh-tw', 'ja'];

  const allProductLanguages = allSupportedLanguages.map((lang: string) => {
    const defaultLanguageCode = defaultLanguageCd === lang;

    return {
      langCode: lang,
      default: defaultLanguageCode,
      oneLineIntroduction: defaultLanguageCode ? oneLineIntroduction.value : '',
      productDescriptions: defaultLanguageCode ? productDescription.value : '',
      links: defaultLanguageCode
        ? links.value
        : links.value.map((link: ProductLinkType) => {
          return {
            ...link,
            title: ''
          };
        }),
      subtitle: defaultLanguageCode ? subtitle.value : ''
    };
  });

  return allProductLanguages;
});

const onMultilanguageSetting = async () => {
  if (!oneLineIntroduction.value) {
    await showAlert({
      severity: 'info',
      content: t('studio.prj_prod.this_prod.text_missing_mls_n_popup'),
      confirmLabel: t('studio.common.popup_case_cf_btn')
    });
    return;
  }

  const res = await showDialog<ProductPageLanguage<ProductLinkType[]>[]>({
    component: shallowRef(ProductPageMultilingualDialog),
    props: {
      languages: languages.value,
      oneLineIntroduction: oneLineIntroduction.value,
      subtitle: subtitle.value,
      links: links.value,
      translatedContents: translatedContents.value,
      isDisabled: props.isDisabled
    }
  });

  if (res) {
    setTranslatedContent(res);
  }
};

const onSubmit = async () => {
  isClickedSave.value = true;
  emits('onSubmit');
};

const onPreviewNewTab = async () => {
  // if (!productId || !pageId) {
  //   return;
  // }
  const productNo = Number(productId) > 0 ? Number(productId) : props.collectionId || 0;
  const result = await generateProductPagePreviewKeyApi(
    productNo,
    (pageId as string) ?? props.collectionPageId,
    selectedGroupInfo.value?.languageCd || DEFAULT_LOCALE
  );
  if (result) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: `${storeHref.value}/games/${productNo}?token=${result.previewKey}&preview_type=studio`
    }).click();
  }
};

const onCloseForm = async () => {
  setProductDescription('');
  isClickedSave.value = false;
  if (!props.collectionId) {
    redirectTo(`/${locale.value}${props.source}`, { external: true });
  }
  emits('close');
};

watch(
  () => props.isOpenMultilanguage,
  (newValue: boolean) => {
    if (newValue) {
      onMultilanguageSetting();
      emits('onUpdateIsOpenMultilanguage', false);
    }
  }
);
</script>

<template>
  <div>
    <!-- TODO: V-if status=release -->
    <template
      v-if="
        (product?.planStatus === PLAN_STATUS.RELEASE || product?.planStatus === PLAN_STATUS.PAGE_OPEN) && !isCollection
      "
    >
      <div
        v-show="
          productPageDetail?.progressStatus === PROGRESS_STATUS.NONE ||
            productPageDetail?.progressStatus === PROGRESS_STATUS.REJECT ||
            productPageDetail?.progressStatus === PROGRESS_STATUS.SUSPEND
        "
        class="pt-20"
      >
        <product-page-editor-form-title
          :formTitle="
            $t('studio.prj_prod.this_proj.this_prod.edit_sidebar_display_start_date_title')
          "
          required
        />
        <radio-group
          :options="startDateOpts"
          :optionProps="{
            size: 'sm',
            align: 'middle'
          }"
          name="startDateType"
          class="flex gap-24 py-8"
          :disabled="isDisabled"
        />
        <div v-if="startDateType === START_DATE_OPTS.RESERVE" class="flex flex-col rounded-xl">
          <datetime-picker
            name="startDate"
            :rules="{
              required: {
                value: true,
                message:
                  'studio.prj_prod.this_proj.this_prod.edit_sidebar_display_start_date_req_msg'
              },
              min_date: {
                date: new Date(),
                message:
                  'studio.prj_prod.this_proj.this_prod.edit_sidebar_display_start_date_invalid_msg'
              }
            }"
            containerClass="!py-0 !bg-transparent !px-0"
            classHourAndMinutes="!w-[6.2rem] customItemSize"
            showUtc
          />
        </div>
      </div>
    </template>

    <div class="pt-20">
      <product-page-editor-form-title
        :formTitle="$t('studio.prj_prod.this_prod.edit_display_countries_msg1')"
        required
      >
        <s-button size="xs" variant="outline" :isDisabled="isDisabled" @click="onShowAllCountries">
          {{ $t('studio.prj_prod.this_prod.edit_display_countries_view_btn') }}
        </s-button>
      </product-page-editor-form-title>
      <radio-group
        :options="exposureOpts"
        name="exposureCountry"
        class="flex gap-x-24 py-8 flex-wrap gap-y-8"
        :optionProps="{
          size: 'sm',
          align: 'middle'
        }"
        :disabled="isDisabled"
      />
    </div>
    <div v-show="exposureCountry === COUNTRY_EXPOSURE.SELECT" class="pt-20">
      <product-page-editor-form-title
        :formTitle="$t('studio.prj_prod.this_prod.edit_display_countries_msg4')"
        required
      />
      <s-tabs
        v-model="currentTab"
        variant="line-fixed"
        size="sm"
        :defaultValue="EXPOSURE_TABS.REGION"
        class="stove-studio-tab-line"
      >
        <s-tab-list>
          <s-tab-item :value="EXPOSURE_TABS.REGION">
            {{ $t('studio.prj_prod.this_prod.edit_display_countries_regions') }}
          </s-tab-item>
          <s-tab-item :value="EXPOSURE_TABS.SEARCH">
            {{ $t('studio.prj_prod.this_prod.edit_display_countries_search_country') }}
          </s-tab-item>
        </s-tab-list>
        <s-tab-panels>
          <s-tab-panel :value="EXPOSURE_TABS.REGION">
            <div class="flex flex-col gap-4 pt-4">
              <select-countries-by-region :isDisabled="isDisabled" :currentTab="currentTab" />
            </div>
          </s-tab-panel>
          <s-tab-panel :value="EXPOSURE_TABS.SEARCH">
            <select-countries-by-search :isDisabled="isDisabled" />
          </s-tab-panel>
        </s-tab-panels>
      </s-tabs>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFieldValue } from 'vee-validate';
import { computed, ref, shallowRef, toRefs, watch } from 'vue';
import { useRoute } from 'vue-router';

import ProductPageEditorFormTitle from '@/components/common/st-form-title.vue';
import AllCountriesDialog from '@/components/product-page/all-countries-dialog.vue';
import SelectCountriesByRegion from '@/components/product-page/select-countries-by-region.vue';
import SelectCountriesBySearch from '@/components/product-page/select-countries-by-search.vue';
import DatetimePicker from '@/components/validation/datetime-picker.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import { showDialog } from '@/composables/useDialog';
import { EXPOSURE_TABS } from '@/constants/product-page.const';
import { PLAN_STATUS } from '@/constants/products.const';
import { COUNTRY_EXPOSURE, PROGRESS_STATUS, START_DATE_OPTS } from '@/enums/product-page.enum';
import useProductStore from '@/stores/product.store';
import { useProductPageStore } from '@/stores/product-page.store';
import type { FormOption } from '@/types/common/form.type';

defineProps<{
  isDisabled: boolean;
}>();

const emits = defineEmits<{
  onTabChange: [v: string];
}>();

const productStore = useProductStore();
const { product } = productStore;

const productPageStore = useProductPageStore();
const { productPageDetail } = toRefs(productPageStore);

const exposureCountry = useFieldValue<string>('exposureCountry');
const startDateType = useFieldValue<string>('startDateType');

const route = useRoute();
const { productNo: collectionId } = route.query;

const isCollection = computed(() => collectionId !== undefined);

const exposureOpts: FormOption[] = [
  {
    label: 'studio.prj_prod.this_prod.edit_display_countries_select_all',
    value: COUNTRY_EXPOSURE.ALL
  },
  {
    label: 'studio.prj_prod.this_prod.edit_display_countries_select_partial',
    value: COUNTRY_EXPOSURE.SELECT
  }
];

const startDateOpts: FormOption[] = [
  {
    label:
      'studio.prj_prod.this_proj.this_prod.edit_sidebar_display_start_date_immediate_radio_btn',
    value: START_DATE_OPTS.IMMEDIATE
  },
  {
    label:
      'studio.prj_prod.this_proj.this_prod.edit_sidebar_display_start_date_select_time_radio_btn',
    value: START_DATE_OPTS.RESERVE
  }
];

const currentTab = ref<string>(EXPOSURE_TABS.REGION);

const onShowAllCountries = async () => {
  await showDialog({
    component: shallowRef(AllCountriesDialog),
    props: {},
    severity: 'info'
  });
};

watch(
  () => currentTab.value,
  (value: string) => {
    emits('onTabChange', value);
  }
);
</script>

import type {
  ActionCallback,
  CreateIframeMessageBridgeOption,
  IframeMessage
} from '@/utils/iframe-message/iframe-message-bridge.type';

/**
 * Creates an iframe message bridge
 * @description
 *  - Establishes a one-to-one message bridge for a single iframe.
 * @param options Creation options
 * @returns Message bridge object
 */
export const createIframeMessageBridge = ({
  currentWindow,
  targetOrigin,
  targetWindow,
  debug = false
}: CreateIframeMessageBridgeOption) => {
  /** Map to store callback functions for each action */
  const actionMap = new Map<string, ActionCallback<any>[]>();

  /**
   * Validates the target window.
   * @param targetWindow The window to send messages to
   * @param currentWindow The window sending the messages
   * @returns True if valid, otherwise false
   */
  const isValidTargetWindow = (targetWindow: Window | null, currentWindow: Window) => {
    if (!targetWindow || !currentWindow) {
      return false;
    }
    return currentWindow !== targetWindow;
  };

  /**
   * Validates the target origin.
   * @param targetOrigin The origin to send messages to
   * @param currentOrigin The origin of the sender
   * @returns True if valid, otherwise false
   */
  const isValidTargetOrigin = (targetOrigin: string, currentOrigin: string) => {
    // If the target origin is the same as the current origin, it is not a valid target
    if (targetOrigin === currentOrigin) {
      return false;
    }
    return true;
  };

  /**
   * Validates the message format.
   * @param message The message object to validate
   * @returns True if valid, otherwise false
   */
  const isValidMessage = (message: IframeMessage): message is IframeMessage => {
    if (!message || typeof message !== 'object') {
      return false;
    }
    if (!('action' in message) || typeof message.action !== 'string') {
      return false;
    }
    if (!('data' in message)) {
      return false;
    }
    return true;
  };

  /**
   * Message event listener for the window.
   * @param messageEvent The received message event
   */
  const messageListener = (messageEvent: MessageEvent) => {
    if (messageEvent.origin !== targetOrigin) {
      return;
    }
    const message = messageEvent.data;
    if (!isValidMessage(message)) {
      return;
    }
    // Log received messages if debug mode is enabled
    if (debug) {
      const fromOrigin = messageEvent.origin;
      const toOrigin = location.origin;
      // eslint-disable-next-line no-console
      console.log(`[Debug] Received message: ${toOrigin} <- ${fromOrigin}`, message);
    }
    actionMap.forEach((callbackList: ActionCallback[], action: string) => {
      if (message.action !== action) {
        return;
      }
      callbackList.forEach((callback: ActionCallback) => {
        callback(message);
      });
    });
  };

  /**
   * Initializes the message bridge.
   */
  const init = () => {
    if (typeof currentWindow === 'undefined') {
      // eslint-disable-next-line no-console
      console.error('Initialization error: Cannot find the window object.');
      return;
    }
    currentWindow.removeEventListener('message', messageListener);
    currentWindow.addEventListener('message', messageListener);
  };

  /**
   * Destroys the message bridge.
   * @description
   *  Removes all registered callbacks and event listeners.
   */
  const destroy = () => {
    actionMap.clear();
    currentWindow.removeEventListener('message', messageListener);
  };

  /**
   * Returns the list of registered callbacks for a specific action.
   * @param action Action name
   * @returns An array of registered callback functions (empty if none exist)
   */
  const getAction = (action: string) => {
    return actionMap.get(action) ?? [];
  };

  /**
   * Returns all registered actions and their callbacks.
   * @returns An object containing action names and their corresponding callback function arrays
   */
  const getAllAction = () => {
    return Object.fromEntries(actionMap.entries());
  };

  /**
   * Registers a callback function for a specific action.
   * @description
   *  The registered callback function will be executed when a message with the specified action is received.
   * @param action Action name
   * @param fn Callback function to execute
   */
  const on = <T = unknown>(action: string, fn: ActionCallback<T>) => {
    if (!actionMap.has(action)) {
      actionMap.set(action, []);
    }
    actionMap.get(action)?.push(fn);
  };

  /**
   * Unregisters callback functions for a specific action.
   * @description
   *  Behavior depends on the parameters provided:
   *  - No arguments: Removes all callbacks for all actions.
   *  - Only action: Removes all callbacks for the specified action.
   *  - Both action and fn: Removes only the specified callback for the given action.
   * @param action Action name (optional)
   * @param fn Callback function to remove (optional)
   */
  const off = (action?: string, fn?: ActionCallback) => {
    // Remove all callbacks if action is falsy
    if (!action) {
      actionMap.clear();
      return;
    }
    // Remove all callbacks if no parameters are provided
    if (!action && !fn) {
      actionMap.clear();
      return;
    }
    // Remove all callbacks for a specific action
    if (action && !fn) {
      actionMap.delete(action);
      return;
    }
    // Remove a specific callback for a given action
    if (action && fn) {
      if (actionMap.has(action)) {
        const newCallbacks = (actionMap.get(action) ?? []).filter(
          (callback: ActionCallback) => callback !== fn
        );
        if (newCallbacks.length > 0) {
          actionMap.set(action, newCallbacks);
        } else {
          actionMap.delete(action);
        }
      }
    }
  };

  /**
   * Sends a message to the target window.
   * @param message Message object to send
   */
  const send = (message: IframeMessage) => {
    if (!isValidTargetWindow(targetWindow, currentWindow)) {
      return;
    }
    if (!isValidTargetOrigin(targetOrigin, currentWindow.origin)) {
      // eslint-disable-next-line no-console
      console.error('Invalid target origin:', targetOrigin);
      return;
    }
    if (!isValidMessage(message)) {
      // eslint-disable-next-line no-console
      console.error('Invalid message format:', message);
      return;
    }
    if (!targetWindow) {
      // eslint-disable-next-line no-console
      console.error('Cannot find the target window:', targetWindow);
      return;
    }
    // Log sent messages if debug mode is enabled
    if (debug) {
      const fromOrigin = location.origin;
      // eslint-disable-next-line no-console
      console.log(`[Debug] Sent message: ${fromOrigin} -> ${targetOrigin}`, message);
    }
    targetWindow.postMessage(message, targetOrigin);
  };

  return {
    init,
    destroy,
    getAction,
    getAllAction,
    on,
    off,
    send
  };
};

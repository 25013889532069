<template>
  <div class="mt-8 flex flex-col gap-8 py-4">
    <div
      v-for="(tag, index) in tagList"
      :key="`${tag}-${index}`"
      class="flex min-h-[3.4rem] items-center gap-8 bg-[#F2F6FF] rounded-xl py-4 px-16"
    >
      <p class="basis-[7.2rem] text-2xs leading-xs font-medium text-on-surface-elevation-2">
        {{ getTagLabel(tag) }}
      </p>
      <checkbox-group
        v-model="supLangSettings[index].settings"
        class="flex-1 flex flex-wrap gap-y-4 gap-x-12"
        :options="options"
        :optionProps="{ size: 'sm', align: 'middle' }"
        size="sm"
        :disabled="isDisabled"
        @update:modelValue="updateSetting"
      />
      <button v-if="!isDisabled" type="button" class="shrink-0">
        <s-icon icon="ic-v2-control-close-line" @click.self="onTagRemove(tag)" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFieldValue, useSetFieldValue } from 'vee-validate';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import CheckboxGroup from '@/components/validation/checkbox-group.vue';
import { SUPPORTED_LANGUAGES_LIST } from '@/constants/product-page.const';
import type { FormOption, FormOptionGroup } from '@/types/common/form.type';
import type { SupportedLanguageSettingType } from '@/types/product-page.type';

defineProps<{
  isDisabled?: boolean;
}>();

const { t } = useI18n();

const tagList = useFieldValue<string[]>('supportedLanguages');
const setTagList = useSetFieldValue<string[]>('supportedLanguages');
const supLangSettings = ref<SupportedLanguageSettingType[]>(
  tagList.value.map((tag: string) => ({ lang: tag, settings: ['interface'] }))
);

const supportedLanguageSettings = useFieldValue<SupportedLanguageSettingType[]>(
  'supportedLanguageSettings'
);
const setSupportedLanguageSettings = useSetFieldValue<SupportedLanguageSettingType[]>(
  'supportedLanguageSettings'
);

const searchResult = ref<string[]>([]);

const options = ref<FormOptionGroup[]>([
  {
    label: 'studio.prj_prod.this_prod.edit_additional_supporting_languages_msg1',
    value: 'interface',
    isDisabled: true
  },
  {
    label: 'studio.prj_prod.this_prod.edit_additional_supporting_languages_msg2',
    value: 'voice'
  },
  {
    label: 'studio.prj_prod.this_prod.edit_additional_supporting_languages_msg3',
    value: 'subtitle'
  }
]);

const onTagRemove = (tag: string) => {
  const foundTag = tagList.value.find((t: string) => t === tag);

  if (!foundTag) {
    return;
  }

  searchResult.value = tagList.value.filter((res: string) => res !== foundTag);
  setTagList(searchResult.value);
};

const updateSetting = () => {
  setSupportedLanguageSettings(supLangSettings.value);
};

const getTagLabel = (tag: string) => {
  if (tag === 'ZHTW') {
    return t(
      SUPPORTED_LANGUAGES_LIST.find((lang: FormOption) => lang.value === 'ZH_TW')?.label || ''
    );
  }

  if (tag === 'ZHCN') {
    return t(
      SUPPORTED_LANGUAGES_LIST.find((lang: FormOption) => lang.value === 'ZH_CN')?.label || ''
    );
  }

  const found = SUPPORTED_LANGUAGES_LIST.find((lang: FormOption) => lang.value === tag)?.label;

  if (found) {
    return t(found);
  }
  return '';
};

watch(tagList, () => {
  supLangSettings.value = tagList.value.map((tag: string, index: number) => ({
    lang: tag,
    settings: supportedLanguageSettings.value[index]?.settings || ['interface', 'voice', 'subtitle']
  }));

  setSupportedLanguageSettings(supLangSettings.value);
});
</script>

<template>
  <div class="relative">
    <div
      ref="listGroup"
      class="flex overflow-y-auto gap-4 no-scrollbar"
      @scroll="handleListScroll"
      @mousedown="handleBeginScroll"
      @mouseup="handleEndScroll"
      @mouseleave="handleEndScroll"
      @mousemove="handleScrollHorizontal"
    >
      <button
        v-for="(lang, index) in langList"
        :key="lang.value"
        class="inline-flex justify-center items-center h-32 !rounded-full shrink-0 px-12 py-8 text-sm bg-[#F7FAFD] text-on-surface-elevation-2"
        :class="{ 'bg-neutral-variant-1 !text-white': selectedLanguage === lang.value }"
        @click="
          () => {
            selectedLanguage = lang.value;
            $emit('updateLang', lang.value);
          }
        "
      >
        <s-text as="span" role="btn3">
          <span
            class="whitespace-nowrap"
            :class="{ '!text-white': selectedLanguage === lang.value }"
          >{{ $t(lang.label)
          }}{{ index === 0 ? `(${$t('studio.ai_translation.base_lang')})` : '' }}</span>
          <span class="ml-4">{{ currentCount(lang.value) }}</span>
        </s-text>

        <s-icon
          v-if="showError(lang.value)"
          size="xl"
          icon="ic-v2-state-warning-circle-fill"
          class="shrink-0 text-error ml-2"
        />
      </button>
    </div>
    <div
      v-if="!isHiddenArrow"
      class="h-32 flex absolute top-0 -left-[1.4rem] transition-opacity duration-300"
      :class="{ invisible: isAtLeft }"
    >
      <s-button
        icon="ic-v2-control-select-arrow-down-fill"
        class="!min-h-[2.4rem] !min-w-0 text-on-surface-elevation-2 !bg-white !border-none rotate-90 !rounded-none w-[3.4rem] m-0"
        @mouseenter="scrollToRight"
      /><span class="linear-gradient-1 w-[2.4rem] h-32 -ml-[0.1rem]"></span>
    </div>
    <div
      v-if="!isHiddenArrow"
      class="h-32 flex absolute top-0 -right-[1.4rem] transition-opacity duration-300"
      :class="{ invisible: isAtRight }"
    >
      <span class="linear-gradient-2 w-[2.4rem] h-32 -mr-[0.1rem]"></span>
      <s-button
        icon="ic-v2-control-select-arrow-up-fill"
        class="!min-h-[2.4rem] !min-w-0 text-on-surface-elevation-2 !bg-white !border-none rotate-90 !rounded-none w-[3.4rem] m-0"
        @mouseenter="scrollToLeft"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useFieldValue } from 'vee-validate';
import { onMounted, ref } from 'vue';

import { HTTPS_URL_REGEX } from '@/constants/regex.const';
import { useProductPageStore } from '@/stores/product-page.store';
import { useUserStore } from '@/stores/user.store';
import type { ImageLanguageType } from '@/types/common/file.type';
import type { FormOption } from '@/types/common/form.type';

defineProps<{
  langList: FormOption[];
}>();

defineEmits<{
  updateLang: [v: string];
}>();

const listGroup = ref<HTMLElement>();
const isAtLeft = ref(true);
const isAtRight = ref(false);
const listGroupWidth = ref<number>(0);
const isHiddenArrow = ref<boolean>(false);
const isScrolling = ref<boolean>(false);
const startX = ref<number>(0);
const scrollX = ref<number>(0);

const userStore = useUserStore();
const { selectedGroupInfo } = userStore;

const productPageStore = useProductPageStore();
const { isClickedSave } = storeToRefs(productPageStore);

const groupLang = selectedGroupInfo?.languageCd || 'ko';

const selectedLanguage = ref<string>(groupLang);

const mapped = useFieldValue<{ [key: string]: ImageLanguageType[] }>('mapped');

const showError = (lang: string) => {
  const defaultLang = Object.entries(mapped.value ?? []).find((img: any) => img[0] === groupLang);
  const currentLang = Object.entries(mapped.value ?? []).find((img: any) => img[0] === lang);
  if (defaultLang && groupLang === lang && defaultLang[1].length === 0 && isClickedSave.value) {
    return true;
  }
  if (
    currentLang &&
    Array.isArray(currentLang[1]) &&
    currentLang[1].some(
      (image: ImageLanguageType) =>
        image.type === 'link' &&
        (!image.movieUrl || !image.movieUrl.match(HTTPS_URL_REGEX) || image?.movieUrl.length > 2000)
    )
  ) {
    return true;
  }

  return false;
};

const currentCount = (lang: string) => {
  const currentLang = Object.entries(mapped.value ?? []).find((img: any) => img[0] === lang);

  if (currentLang) {
    const listFiles = currentLang[1];

    const validFiles = listFiles.filter((file: any) => {
      if (file.type === 'image') {
        return file.image || file.linkCdn;
      } else if (file.type === 'link') {
        return (
          (file.image || file.linkCdn) &&
          file.movieUrl &&
          HTTPS_URL_REGEX.test(file.movieUrl) &&
          file?.movieUrl.length <= 2000
        );
      }

      return true;
    });

    return validFiles.length;
  }

  return 0;
};

const handleListScroll = () => {
  if (!listGroup.value) {
    return;
  }

  const { scrollLeft, scrollWidth, clientWidth } = listGroup.value;
  isAtRight.value = scrollWidth - scrollLeft - clientWidth < 10;
  isAtLeft.value = scrollLeft < 10;
};

const scrollToLeft = () => {
  if (listGroup.value) {
    const { scrollWidth, clientWidth } = listGroup.value;
    listGroup.value.scrollTo({ left: scrollWidth - clientWidth, behavior: 'smooth' });
  }
};

const scrollToRight = () => {
  if (listGroup.value) {
    listGroup.value.scrollTo({ left: 0, behavior: 'smooth' });
  }
};

const checkHiddenArrow = () => {
  const observer = new ResizeObserver(() => {
    if (!listGroup.value) {
      return;
    }

    isHiddenArrow.value = listGroup.value.scrollWidth <= listGroup.value.clientWidth;
    if (!isHiddenArrow.value) {
      handleListScroll();
    }
  });

  observer.observe(listGroup.value as Element);
};

const handleBeginScroll = (e: any) => {
  if (listGroup.value) {
    isScrolling.value = true;
    startX.value = e.clientX;
    scrollX.value = listGroup.value.scrollLeft;
  }
};

const handleEndScroll = () => {
  isScrolling.value = false;
};

const handleScrollHorizontal = (e: any) => {
  if (isScrolling.value && listGroup.value) {
    const dx = e.clientX - startX.value;
    listGroup.value.scrollLeft = scrollX.value - dx;
  }
};

onMounted(() => {
  if (listGroup.value) {
    listGroupWidth.value = listGroup.value.scrollWidth;
    checkHiddenArrow();
  }
});
</script>

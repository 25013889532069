<template>
  <div class="space-y-20 divide-y divide-solid divide-inverse-elevation-5">
    <div class="pt-20">
      <st-form-title :formTitle="$t('studio.prj_prod.this_prod.edit_gamepreview_msg1')" required />
      <radio-group
        :options="screenTypeOpts"
        name="screenType"
        class="flex gap-24 py-8"
        :optionProps="{
          size: 'sm',
          align: 'middle'
        }"
        :disabled="isDisabled"
      />
    </div>

    <div v-if="screenType === SCREEN_TYPES.VIDEO" class="pt-20">
      <st-form-title
        :formTitle="$t('studio.prj_prod.this_prod.edit_gamepreview_radio2_msg1')"
        required
      />
      <p class="text-brand-primary text-xs leading-xs">
        {{ $t('studio.prj_prod.this_prod.edit_gamepreview_radio2_msg1_guide1') }}
      </p>
      <input-product-file
        resolution="1080P(1920X1080)"
        :extensions="['mp4']"
        :type="FILE_TYPE.VIDEO"
        class="mt-8"
        name="productPageBgVideo[0]"
        :maxSize="100 * SIZE_UNIT"
        :cropBox="{
          width: 1920,
          height: 1080
        }"
        :fileNameWidth="130"
        :expandedWidth="640"
        :showPixel="false"
        isZoomLimit
        :isDisabled="isDisabled"
        required
        :requiredMessage="$t('studio.prj_prod.this_prod.edit_gamepreview_radio2_required_n')"
      />
    </div>

    <div v-show="!showOnlyRequiredFields" class="pt-20">
      <st-form-title :formTitle="$t('studio.prj_prod.this_prod.edit_gamepreview_radio1_msg1')" />
      <p v-if="screenType === SCREEN_TYPES.VIDEO" class="text-brand-primary text-xs leading-xs">
        {{ $t('studio.prj_prod.this_prod.edit_gamepreview_radio2_msg2_guide1') }}
      </p>
      <input-product-file
        resolution="1920x520"
        :extensions="['jpg', 'png', 'gif', 'webp']"
        :type="FILE_TYPE.IMAGE"
        class="mt-8"
        name="productPageBgImage[0]"
        :cropBox="{
          width: 1920,
          height: 520
        }"
        :stencilSize="{
          width: 680,
          height: 184
        }"
        :fileNameWidth="130"
        :expandedWidth="640"
        isZoomLimit
        isHiddenAddLogo
        :isDisabled="isDisabled"
      />
    </div>

    <div class="pt-20">
      <st-form-title
        :formTitle="$t('studio.prj_prod.this_prod.edit_gamepreview_msg6')"
        required
      />
      <safe-html
        tag="p"
        class="stds-text text-cap1 leading-cap1 font-cap1 text-placeholder mb-12"
        :html="$t('studio.prj_prod.this_prod.edit_gamepreview_file_register_guide')"
      />
      <tab-nav
        :langList="supportedLanguageList"
        :selectedLanguage="selectLang"
        @updateLang="(l) => setSelectedLanguage(l)"
      />
      <input-product-file-list
        ref="inputProductFileListRef"
        v-model="exhVideosAndImages"
        :numberOfFiles="numberOfFiles"
        :maxFileNumbers="maxNumberOfFiles"
        :selectedLanguage="selectedLanguage"
        name="exhibitionVideosAndImages"
        required
        :isDisabled="isDisabled"
      />
      <s-menu-popup
        v-show="exhVideosAndImages.length < maxNumberOfFiles"
        :distance="0"
        :offset="[0, -32]"
        placement="bottom-end"
        :interactive="true"
        :useFlip="true"
        trigger="click"
        class="w-full mt-8"
      >
        <template #target>
          <button ref="menuRefButton" class="w-full">
            <s-button
              size="sm"
              variant="outline"
              icon="ic-v2-control-add-line"
              iconClass="!mr-0 ml-2"
              class="flex-row-reverse w-full"
              :isDisabled="isDisabled"
            >
              {{ $t('studio.prj_prod.this_prod.edit_gamepreview_spotlights_add_btn') }}
            </s-button>
          </button>
        </template>
        <template #menuItems>
          <s-menu-popup-item
            icon="ic-v2-community-link-line"
            value="link"
            @click="onAddFile('link')"
          >
            {{ $t('studio.prj_prod.this_prod.edit_gamepreview_spotlights_add_link_btn') }}
          </s-menu-popup-item>
          <!-- <s-menu-popup-item
            icon="ic-v2-community-attach-video-line"
            value="video"
            @click="onAddFile"
          >
            {{ $t('studio.prj_prod.this_prod.edit_gamepreview_spotlights_add_video_file_btn') }}
          </s-menu-popup-item> -->
          <s-menu-popup-item
            icon="ic-v2-community-attach-image-line"
            value="image"
            @click="onAddFile"
          >
            {{ $t('studio.prj_prod.this_prod.edit_gamepreview_spotlights_add_image_btn') }}
          </s-menu-popup-item>
        </template>
      </s-menu-popup>
      <st-error-message
        v-if="exhibitionVideosAndImagesError"
        :errorMessage="exhibitionVideosAndImagesError"
      />
    </div>

    <div class="pt-20">
      <st-form-title
        :formTitle="$t('studio.prj_prod.this_prod.edit_gamepreview_msg7')"
        useGuidePopup
        :useGuidePopupImage="ImageAssets.GuideTitleImage"
        tooltipPlacement="bottom"
        :useGuidePopupTitle="$t('studio.prj_prod.this_prod.edit_gamepreview_1to1_info_hover_msg')"
        required
      >
        <div class="relative">
          <s-button
            size="sm"
            variant="outline"
            :isDisabled="isDisabled"
            @click="onRegisterLang1x1Image"
          >
            {{ $t('studio.prj_prod.this_prod.edit_gamepreview_language_setting_btn') }}
          </s-button>
          <product-guide
            v-if="!isDisabled"
            storedName="product-page-image-guide"
            class="!z-[499]"
            topEnd
            noCaption
          >
            <s-text as="h4" role="text4" class="font-bold !text-white">
              {{ $t('studio.prod_page.once.language_sample_tooltip_box') }}
            </s-text>
          </product-guide>
        </div>
      </st-form-title>
      <p class="text-placeholder text-xs leading-xs">
        {{ $t('studio.prj_prod.this_prod.edit_gamepreview_msg7_1') }}
      </p>
      <input-product-file
        v-for="(img, index) in image1x1"
        :key="index"
        v-model="img.image"
        :tagValue="getTagValue(img.lang)"
        resolution="500x500"
        :extensions="['jpg', 'png', 'gif', 'webp']"
        :type="FILE_TYPE.IMAGE"
        class="mt-8"
        :isDisabled="isDisabled"
        :name="`image1x1[${index}]`"
        showErrorMsg
        required
        :cropBox="{
          width: 500,
          height: 500
        }"
        :stencilSize="{
          width: 400,
          height: 400
        }"
        :safeArea="{
          width: 320,
          height: 320
        }"
        :safeAreaLogo="{
          width: 296,
          height: 106,
          bottom: '0.8rem'
        }"
        :fileNameWidth="130"
        :expandedWidth="640"
        isGuideShown
        isZoomLimit
        :requiredMessage="$t('studio.prj_prod.this_prod.edit_gamepreview_msg8_attach_msg1')"
      />
    </div>

    <div class="pt-20">
      <st-form-title :formTitle="$t('studio.prod_pg.img_edit_popup.img_type5')" required>
        <div class="flex items-center gap-8">
          <s-button
            size="sm"
            variant="outline"
            :isDisabled="isDisabled"
            @click="onPreview16x9Image"
          >
            {{ $t('studio.prj_prod.this_prod.edit_gamepreview_16to9_preview_btn') }}
          </s-button>
          <s-button
            size="sm"
            variant="outline"
            :isDisabled="isDisabled"
            @click="onRegisterLang16x9Image"
          >
            {{ $t('studio.prj_prod.this_prod.edit_gamepreview_language_setting_btn') }}
          </s-button>
        </div>
      </st-form-title>
      <p class="text-placeholder text-xs leading-xs">
        {{ $t('studio.prj_prod.this_prod.edit_gamepreview_msg8_1') }}
      </p>
      <input-product-file
        v-for="(img, index) in image16x9"
        :key="index"
        v-model="img.image"
        :tagValue="getTagValue(img.lang)"
        resolution="757x426"
        :extensions="['jpg', 'png', 'gif', 'webp']"
        :type="FILE_TYPE.IMAGE"
        class="mt-8"
        :name="`image16x9[${index}]`"
        :cropBox="{
          width: 757,
          height: 426
        }"
        :stencilSize="{
          width: 676,
          height: 380
        }"
        :safeArea="{
          width: 268,
          height: 348
        }"
        :safeAreaLogo="{
          width: 248,
          height: 148
        }"
        :dialogName="$t('studio.prj_prod.this_prod.edit_gamepreview_msg8')"
        :fileNameWidth="130"
        :expandedWidth="640"
        required
        isGuideShown
        :requiredMessage="$t('studio.prj_prod.this_prod.edit_gamepreview_msg8_attach_msg1')"
        :isDisabled="isDisabled"
        isZoomLimit
      />
    </div>

    <div v-show="!showOnlyRequiredFields" class="pt-20">
      <st-form-title
        :formTitle="$t('studio.prj_prod.this_prod.edit_title_bg_img')"
        useGuidePopup
        :useGuidePopupTitle="$t('studio.prj_prod.this_prod.edit_gamepreview_1to1_info_hover_msg')"
        :useGuidePopupImage="ImageAssets.GuideTitleBackgroundImage"
      />
      <p class="text-placeholder text-xs leading-xs">
        {{ $t('studio.prj_prod.this_prod.edit_title_bg_img_guide') }}
      </p>
      <input-product-file
        resolution="1290x180"
        :extensions="['jpg', 'png', 'gif', 'webp']"
        :type="FILE_TYPE.IMAGE"
        class="mt-8"
        name="imageTitleBackground[0]"
        :cropBox="{
          width: 1290,
          height: 180
        }"
        :stencilSize="{
          width: 680,
          height: 95
        }"
        :fileNameWidth="130"
        :expandedWidth="640"
        :isDisabled="isDisabled"
        isZoomLimit
        isHiddenAddLogo
      />
    </div>

    <div v-show="!showOnlyRequiredFields && screenType !== SCREEN_TYPES.VIDEO" class="pt-20">
      <st-form-title
        :formTitle="$t('studio.prj_prod.this_prod.edit_gamepreview_msg9')"
        useGuidePopup
        :useGuidePopupImage="ImageAssets.GuideShortPieceImage"
        :useGuidePopupTitle="
          $t('studio.prj_prod.this_prod.edit_gamepreview_headline_info_hover_msg')
        "
      >
        <s-button
          size="sm"
          variant="outline"
          :isDisabled="isDisabled"
          @click="onRegisterLangOneLineIntro"
        >
          {{ $t('studio.prj_prod.this_prod.edit_gamepreview_language_setting_btn') }}
        </s-button>
      </st-form-title>
      <p class="text-placeholder text-xs leading-xs">
        {{
          screenType === SCREEN_TYPES.VIDEO
            ? $t('studio.prj_prod.this_prod.edit_gamepreview_msg9_2')
            : $t('studio.prj_prod.this_prod.edit_gamepreview_msg9_1')
        }}
      </p>
      <input-product-file
        v-for="(img, index) in imageOneLineIntro"
        :key="index"
        :tagValue="getTagValue(img.lang)"
        resolution="558x132"
        :extensions="['jpg', 'png', 'gif', 'webp']"
        :type="FILE_TYPE.IMAGE"
        class="mt-8"
        :name="`imageOneLineIntro[${index}]`"
        :cropBox="{
          width: 558,
          height: 132
        }"
        :stencilSize="{
          width: 558,
          height: 132
        }"
        :safeArea="{
          width: 315,
          height: 132
        }"
        :safeAreaLogo="{
          width: 199,
          height: 88
        }"
        :fileNameWidth="130"
        :expandedWidth="640"
        isGuideShown
        isZoomLimit
        :guideType="PRODUCT_PAGE_IMAGE_EDITOR_GUIDE_TYPE.CUSTOM"
        :isDisabled="isDisabled"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useFieldError, useFieldValue, useSetFieldError, useSetFieldValue } from 'vee-validate';
import { computed, ref, shallowRef, watch } from 'vue';

import * as ImageAssets from '@/assets/images';
import SafeHtml from '@/components/common/safe-html.vue';
import StErrorMessage from '@/components/common/st-error-message.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import ProductGuide from '@/components/product/product-guide.vue';
import InputProductFile from '@/components/product-page/input-product-file.vue';
import InputProductFileList from '@/components/product-page/input-product-file-list.vue';
import Preview169Dialog from '@/components/product-page/preview-16-9-dialog.vue';
import RegisterLanguageDialog from '@/components/product-page/register-language-dialog.vue';
import TabNav from '@/components/product-page/tab-nav.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import { showDialog } from '@/composables/useDialog';
import { SIZE_UNIT } from '@/constants/file.const';
import { DEFAULT_LOCALE, DEFAULT_LOCALES } from '@/constants/locale.const';
import { SCREEN_TYPES } from '@/constants/product-page.const';
import { HTTPS_URL_REGEX } from '@/constants/regex.const';
import { FILE_TYPE, PRODUCT_PAGE_IMAGE_EDITOR_GUIDE_TYPE } from '@/enums/product-page.enum';
import { RuleNames } from '@/enums/validation.enum';
import useProductStore from '@/stores/product.store';
import { useProductPageStore } from '@/stores/product-page.store';
import { useUserStore } from '@/stores/user.store';
import type { ImageLanguageType } from '@/types/common/file.type';
import type { FormOption, FormOptionGroup } from '@/types/common/form.type';
import { generateErrorMsg } from '@/utils/validation.util';

defineProps<{
  isDisabled?: boolean;
}>();

type ImageLanguageTypeForm = [string, ImageLanguageType[]];

const inputProductFileListRef = ref<InstanceType<typeof InputProductFileList>>();

const menuRefButton = ref<any>();

const productPageStore = useProductPageStore();
const { showOnlyRequiredFields, isClickedSave } = storeToRefs(productPageStore);

const userStore = useUserStore();
const { selectedGroupInfo } = storeToRefs(userStore);

const productStore = useProductStore();
const { productName } = productStore;

const language = computed(() => {
  return DEFAULT_LOCALES[selectedGroupInfo.value?.languageCd || DEFAULT_LOCALE].code;
});

const setExhError = useSetFieldError('exhibitionVideosAndImages');
const exhVideosAndImages = ref<string[]>([]);
const exhibitionVideosAndImages = useFieldValue<ImageLanguageType[]>('exhibitionVideosAndImages');
const screenType = useFieldValue<string>('screenType');

const image1x1 = useFieldValue<ImageLanguageType[]>('image1x1');
const setImage1x1 = useSetFieldValue<ImageLanguageType[]>('image1x1');

const image16x9 = useFieldValue<ImageLanguageType[]>('image16x9');
const setImage16x9 = useSetFieldValue<ImageLanguageType[]>('image16x9');

const imageOneLineIntro = useFieldValue<ImageLanguageType[]>('imageOneLineIntro');
const setImageOneLineIntro = useSetFieldValue<ImageLanguageType[]>('imageOneLineIntro');

const exhibitionVideosAndImagesError = useFieldError('exhibitionVideosAndImages');
const setExhibitionVideosAndImages = useSetFieldValue('exhibitionVideosAndImages');
const setExhibitionVideosAndImagesSorted = useSetFieldValue('exhibitionVideosAndImagesSorted');
const selectedLanguage = useFieldValue<string>('selectedLanguage');
const setSelectedLanguage = useSetFieldValue<string>('selectedLanguage');

const mappedSorted = useFieldValue<{
  [key: string]: ImageLanguageType[];
}>('mappedSorted');

const mapped = useFieldValue<{
  [key: string]: ImageLanguageType[];
}>('mapped');

const selectedLanguageList1x1 = ref<string[]>(
  image1x1.value.map((img: ImageLanguageType) => img.lang)
);

const selectedLanguageList16x9 = ref<string[]>(
  image16x9.value.map((img: ImageLanguageType) => img.lang)
);
const selectedLanguageListOneLineIntro = ref<string[]>(
  imageOneLineIntro.value.map((img: ImageLanguageType) => img.lang)
);
const selectLang = ref<string>(selectedLanguage.value);

const maxNumberOfFiles = 20;

const supportedLanguageList: FormOptionGroup<string>[] = [
  {
    label: 'studio.ai_translation.base_lang_ko',
    value: DEFAULT_LOCALES.ko.code,
    isDisabled: language.value === DEFAULT_LOCALES.ko.code
  },
  {
    label: 'studio.ai_translation.base_lang_en',
    value: DEFAULT_LOCALES.en.code,
    isDisabled: language.value === DEFAULT_LOCALES.en.code
  },
  {
    label: 'studio.ai_translation.base_lang_ja',
    value: DEFAULT_LOCALES.ja.code,
    isDisabled: language.value === DEFAULT_LOCALES.ja.code
  },
  {
    label: 'studio.ai_translation.base_lang_ch_tw',
    value: DEFAULT_LOCALES['zh-tw'].code,
    isDisabled: language.value === DEFAULT_LOCALES['zh-tw'].code
  },
  {
    label: 'studio.ai_translation.base_lang_ch_cn',
    value: DEFAULT_LOCALES['zh-cn'].code,
    isDisabled: language.value === DEFAULT_LOCALES['zh-cn'].code
  }
].sort(
  (a: FormOptionGroup<string>, b: FormOptionGroup<string>) =>
    (b.isDisabled ? 1 : 0) - (a.isDisabled ? 1 : 0)
);

const screenTypeOpts: FormOption[] = [
  { label: 'studio.prj_prod.this_prod.edit_gamepreview_radio1', value: SCREEN_TYPES.BASIC },
  { label: 'studio.prj_prod.this_prod.edit_gamepreview_radio2', value: SCREEN_TYPES.VIDEO }
];

const numberOfFiles = computed(() => {
  return exhibitionVideosAndImages.value.filter((img: ImageLanguageType) => {
    if (img.type === FILE_TYPE.LINK) {
      return img.linkCdn && img.movieUrl && HTTPS_URL_REGEX.test(img.movieUrl) && img?.movieUrl.length <= 2000;
    }
    return img.linkCdn;
  }).length || 0;
});

const setImageByLanguage = (
  languageList: string[],
  image: ImageLanguageType[],
  callback: (value: ImageLanguageType[], shouldValidate?: boolean) => void
) => {
  const newImage = languageList.map((lang: string) => {
    const existingImage = image.find((img: ImageLanguageType) => img.lang === lang);
    if (existingImage) {
      return existingImage;
    }
    return {
      lang,
      image: ''
    };
  });
  callback(newImage);
};

const onRegisterLang1x1Image = async () => {
  selectedLanguageList1x1.value = image1x1.value.map((img: ImageLanguageType) => img.lang);
  const result = await showDialog<string[]>({
    component: shallowRef(RegisterLanguageDialog),
    props: {
      supportedLanguageList,
      selectedLanguageList: selectedLanguageList1x1.value
    },
    severity: 'info'
  });
  setImageByLanguage(result, image1x1.value, setImage1x1);
};

const onPreview16x9Image = async () => {
  await showDialog({
    component: shallowRef(Preview169Dialog),
    props: {
      image16x9: image16x9.value,
      productName
    },
    severity: 'info'
  });
};

const onRegisterLang16x9Image = async () => {
  selectedLanguageList16x9.value = image16x9.value.map((img: ImageLanguageType) => img.lang);
  const result = await showDialog<string[]>({
    component: shallowRef(RegisterLanguageDialog),
    props: {
      supportedLanguageList,
      selectedLanguageList: selectedLanguageList16x9.value
    },
    severity: 'info'
  });
  setImageByLanguage(result, image16x9.value, setImage16x9);
};

const onRegisterLangOneLineIntro = async () => {
  selectedLanguageListOneLineIntro.value = imageOneLineIntro.value.map(
    (img: ImageLanguageType) => img.lang
  );
  const result = await showDialog<string[]>({
    component: shallowRef(RegisterLanguageDialog),
    props: {
      supportedLanguageList,
      selectedLanguageList: selectedLanguageListOneLineIntro.value
    },
    severity: 'info'
  });
  setImageByLanguage(result, imageOneLineIntro.value, setImageOneLineIntro);
};

const onAddFile = (value: 'link' | 'video' | 'image') => {
  inputProductFileListRef.value?.addMoreFile(value, {});
  menuRefButton.value.click();
};

const getTagValue = (lang: string) => {
  return (
    supportedLanguageList.find(
      (s: FormOptionGroup<string>) => s.value === lang && s.value !== language.value
    )?.label || ''
  );
};

const updateSelectedLanguage = () => {
  const selectedLang = Object.entries(mapped.value).find(
    (img: ImageLanguageTypeForm) => img[0] === selectedLanguage.value
  );
  if (selectedLang) {
    setExhibitionVideosAndImages(selectedLang[1]);
    setExhibitionVideosAndImagesSorted(selectedLang[1]);
  }
};

watch(
  () => exhibitionVideosAndImages.value,
  () => {
    exhVideosAndImages.value = exhibitionVideosAndImages.value.map(
      (img: ImageLanguageType) => img.image || ''
    );

    if (isClickedSave.value) {
      const selectedLang = Object.entries(mappedSorted.value ?? []).find(
        (img: any) => img[0] === language.value
      );
      if (exhibitionVideosAndImages.value.length > 0) {
        setExhError('');
      } else if (selectedLang && selectedLang[1].length === 0) {
        setExhError(
          generateErrorMsg(
            'studio.prj_prod.this_prod.edit_gamepreview_spotlights_add_btn_alert1',
            RuleNames.REQUIRED
          )
        );
      }
    }
  },
  { deep: true }
);

watch(exhVideosAndImages, () => {
  if (exhVideosAndImages.value.length > 0 && isClickedSave.value) {
    setExhError('');
  }
});
watch(
  () => selectedLanguage.value,
  () => {
    updateSelectedLanguage();
  }
);
</script>

<template>
  <div
    class="flex min-h-72 flex-col justify-center gap-8 rounded-xl border-1 border-solid border-border bg-[#FCFCFD] px-16 py-12 select-none focus-visible:none"
    :class="{ 'pointer-events-none': isDisabled }"
  >
    <div class="flex w-full items-center gap-8">
      <s-icon
        size="3xl"
        icon="ic-v2-navigation-menu-line"
        class="shrink-0 cursor-grab text-on-surface-elevation-3 active:cursor-grabbing"
      />
      <p v-if="isDefault" class="flex-1 text-sm leading-md text-on-surface-elevation-3">
        {{ linkTitle }}
      </p>
      <input-text
        v-else
        size="sm"
        :placeholder="$t('studio.prj_prod.this_prod.edit_additional_links_name_place_holder')"
        :name="`links[${linkIndex}].title`"
        :disabled="isDisabled"
        :rules="{
          required: {
            value: true,
            message: $t('studio.prj_prod.this_prod.edit_additional_links_name_val_msg1'),
            showError: false
          },
          max_length: {
            value: 35,
            showError: false,
            message: $t('studio.prj_prod.this_prod.edit_additional_links_name_length_val_msg1')
          }
        }"
      />
      <button type="button" :disabled="isDisabled" @click="onRemoveFromListLink()">
        <s-icon
          size="xl"
          icon="ic-v2-control-close-line"
          class="shrink-0"
          :class="isDisabled ? 'text-disabled-variant-1' : 'text-on-surface-elevation-2'"
        />
      </button>
    </div>
    <st-error-message v-if="errorTitleMsg" :errorMessage="errorTitleMsg" />

    <div class="flex items-center gap-12">
      <p
        v-if="isDefault"
        class="flex-1 text-sm leading-md"
        :class="isDisabled ? 'text-disabled-variant-1' : 'text-on-surface-elevation-2'"
      >
        {{ linkUrl }}
      </p>
      <input-text
        v-else
        size="sm"
        :placeholder="$t('studio.prj_prod.this_prod.edit_additional_links_url_place_holder')"
        :disabled="isDisabled"
        :name="`links[${linkIndex}].url`"
        :rules="{
          required: {
            value: true,
            message: $t('studio.prj_prod.this_prod.edit_additional_links_url_req_msg1'),
            showError: false
          },
          max_length: {
            value: 2000,
            showError: false,
            message: $t('studio.prj_prod.this_prod.edit_gamepreview_spotlights_add_btn_alert4')
          },
          regex: {
            regex: HTTPS_URL_REGEX,
            message: $t('studio.prj_prod.this_prod.edit_additional_links_url_val_msg1'),
            showError: false
          }
        }"
      />
    </div>
    <st-error-message v-if="errorUrlMsg" keepAlive :errorMessage="errorUrlMsg" />
  </div>
</template>
<script setup lang="ts">
import { useFieldError, useSetFieldError } from 'vee-validate';
import { watch } from 'vue';

import StErrorMessage from '@/components/common/st-error-message.vue';
import InputText from '@/components/validation/input-text.vue';
import { HTTPS_URL_REGEX } from '@/constants/regex.const';

const emits = defineEmits<{
  removeLink: [linkId: string];
  changeUrlError: [error: string, linkId: string];
  changeTitleError: [error: string, linkId: string];
}>();

const props = defineProps<{
  linkTitle?: string;
  linkUrl?: string;
  isDefault?: boolean;
  isDisabled?: boolean;
  linkId: string;
  errorTitleMsg?: string;
  errorUrlMsg?: string;
  linkIndex: number;
  linkTitleMaxLen?: number;
}>();

const errorTitle = useFieldError(`links[${props.linkIndex}].title`);
const errorUrl = useFieldError(`links[${props.linkIndex}].url`);
const setErrorTitle = useSetFieldError(`links[${props.linkIndex}].title`);
const setErrorUrl = useSetFieldError(`links[${props.linkIndex}].url`);

const onRemoveFromListLink = () => {
  if (props.linkId === undefined) {
    return;
  }

  emits('removeLink', props.linkId);
};

watch(
  () => errorUrl.value,
  (newVal: string) => {
    emits('changeUrlError', newVal, props.linkId);
  },
  {
    deep: true
  }
);

watch(
  () => errorTitle.value,
  (newVal: string) => {
    emits('changeTitleError', newVal, props.linkId);
  },
  {
    deep: true
  }
);

const init = () => {
  if (props.errorTitleMsg) {
    setErrorTitle(props.errorTitleMsg);
  }
  if (props.errorUrlMsg) {
    setErrorUrl(props.errorUrlMsg);
  }
};

init();
</script>

<template>
  <div class="min-h-[5.8rem]">
    <dl
      class="studio-spec-info flex items-center gap-12 py-[1.3rem]"
      :class="enableSpecifications ? 'pb-8' : ''"
    >
      <dt class="studio-spec-title-box basis-[16rem]">
        <p
          v-if="type === 'default'"
          class="text-on-surface-elevation-2 text-sm font-medium leading-xs"
        >
          {{ setting.label === SettingLabels.GRAPHIC ? 'Graphics' : setting.label }}
        </p>
        <input-text
          v-else
          :placeholder="$t('studio.prj_prod.this_prod.edit_additional_requirements_component')"
          size="sm"
          variant="outline"
          :name="`additionalSettings[${settingIndex}].label`"
          :disabled="isDisabled"
          :rules="{
            required: {
              value: true,
              message: $t(
                'studio.prj_prod.this_prod.edit_additional_requirements_component_val_msg'
              ),
              showError: false
            },
            max_length: {
              value: 15,
              showError: false
            }
          }"
          maxLength="15"
          :countable="false"
          :containerClass="
            showAddtionalLabelError ? 'border-2 pr-[1.1rem] pl-[1.1rem] border-error' : ''
          "
        />
      </dt>
      <dd class="studio-spec-input-box flex-1 flex gap-4">
        <div v-if="!enableSpecifications" class="flex-wrap w-full">
          <input-text
            :placeholder="$t(placeholderRender)"
            size="sm"
            variant="outline"
            :name="`${
              type === 'default' ? 'settings' : 'additionalSettings'
            }[${settingIndex}].minimumRequirement`"
            :rules="{
              max_length: {
                value: 80,
                showError: type === 'default'
              },
              ...(props.isRequired
                ? {
                  required: {
                    value: true,
                    message: getRequiredMessage,
                    showError: true
                  }
                }
                : {})
            }"
            :countable="false"
            maxLength="80"
            :disabled="isDisabled"
          />
        </div>
        <template v-else>
          <div class="flex-wrap" :class="`${isOpenedEditor ? 'w-1/2' : 'w-full'}`">
            <input-text
              :placeholder="$t(placeholderRender)"
              size="sm"
              variant="outline"
              :name="`${
                type === 'default' ? 'settings' : 'additionalSettings'
              }[${settingIndex}].minimumRequirement`"
              :rules="{
                max_length: {
                  value: 80,
                  showError: type === 'default'
                },
                ...(props.isRequired
                  ? {
                    required: {
                      value: true,
                      message: getRequiredMessage,
                      showError: true
                    }
                  }
                  : {})
              }"
              maxLength="80"
              :countable="false"
              :containerClass="
                showAddtionalMinimumError ? 'border-2 pr-[1.1rem] pl-[1.1rem] border-error' : ''
              "
              :disabled="isDisabled"
            />
          </div>

          <div class="flex-wrap" :class="`${isOpenedEditor ? 'w-1/2' : 'w-full'}`">
            <input-text
              :placeholder="
                $t(
                  'studio.prj_prod.this_prod.edit_additional_requirements_component_recommended_place_holder'
                )
              "
              size="sm"
              variant="outline"
              :name="`${
                type === 'default' ? 'settings' : 'additionalSettings'
              }[${settingIndex}].setting`"
              :rules="{
                max_length: {
                  value: 80,
                  showError: type === 'default'
                }
              }"
              maxLength="80"
              :countable="false"
              :containerClass="
                showAddtionalRecommendError
                  ? 'border-2 pr-[1.1rem] pl-[1.1rem] border-error mt-8'
                  : ''
              "
              :disabled="isDisabled"
            />
          </div>
        </template>
      </dd>
      <button
        type="button"
        class="shrink-0"
        :disabled="isDisabled"
        @click="removeAdditionalSetting"
      >
        <s-icon
          v-if="type === 'additional'"
          size="xl"
          icon="ic-v2-control-close-line"
          srOnlyText="Delete"
          :class="isDisabled ? 'text-disabled-variant-1' : 'text-on-surface-elevation-2'"
        />
      </button>
    </dl>
    <st-error-message v-if="showAddtionalLabelError" :errorMessage="additionalLabelErrorRender" />
    <st-error-message
      v-if="showAddtionalMinimumError"
      class="pt-2"
      :errorMessage="additionalMinimumErrorRender"
    />
    <st-error-message
      v-if="showAddtionalRecommendError"
      class="pt-2"
      :errorMessage="additionalRecommendErrorRender"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useFieldError } from 'vee-validate';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import StErrorMessage from '@/components/common/st-error-message.vue';
import InputText from '@/components/validation/input-text.vue';
import { LABEL_REQUIRED } from '@/constants/product-page.const';
import { PRODUCT_TYPE_DETAIL } from '@/constants/products.const';
import { SettingLabels } from '@/enums/product-page.enum';
import { RuleNames } from '@/enums/validation.enum';
import useProductStore from '@/stores/product.store';
import { useProductPageStore } from '@/stores/product-page.store';
import type { ProductSettingType } from '@/types/product-page.type';
import { generateErrorMsg } from '@/utils/validation.util';

interface InputSettingProps {
  defaultLabel?: string;
  type: 'default' | 'additional';
  enableSpecifications: boolean;
  setting: ProductSettingType;
  settingIndex: number;
  settingId?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
}

const { t } = useI18n();

const props = withDefaults(defineProps<InputSettingProps>(), {
  defaultLabel: '',
  settingId: '',
  isRequired: true
});

const emits = defineEmits<{
  removeAdditionalSetting: [v: string];
}>();

const productStore = useProductStore();
const { currentProductDetailType } = storeToRefs(productStore);

const productPageStore = useProductPageStore();
const { isOpenedEditor } = storeToRefs(productPageStore);

const additionalLabelError = useFieldError(`additionalSettings[${props.settingIndex}].label`);
const additionalMinimumError = useFieldError(
  `additionalSettings[${props.settingIndex}].minimumRequirement`
);
const additionalRecommendError = useFieldError(`additionalSettings[${props.settingIndex}].setting`);

const additionalLabelErrorRender = computed(() => {
  if (additionalLabelError.value) {
    if (JSON.parse(additionalLabelError.value).rule === RuleNames.MAX_LENGTH) {
      return generateErrorMsg(
        t('studio.common.def_key.exceed', { length: 15 }),
        RuleNames.MAX_LENGTH
      );
    }
    return additionalLabelError.value;
  }

  return '';
});
const showAddtionalLabelError = computed(() => {
  if (additionalLabelErrorRender.value && props.type === 'additional') {
    return true;
  }
  return false;
});

const additionalMinimumErrorRender = computed(() => {
  if (additionalMinimumError.value) {
    return generateErrorMsg(t('studio.common.def_key.exceed', { length: 80 }), RuleNames.REQUIRED);
  }
  return '';
});
const showAddtionalMinimumError = computed(() => {
  if (additionalMinimumError.value && props.type === 'additional') {
    return true;
  }
  return false;
});

const additionalRecommendErrorRender = computed(() => {
  if (additionalRecommendError.value) {
    return generateErrorMsg(t('studio.common.def_key.exceed', { length: 80 }), RuleNames.REQUIRED);
  }
  return '';
});
const showAddtionalRecommendError = computed(() => {
  if (additionalRecommendError.value && props.type === 'additional') {
    return true;
  }
  return false;
});

const PLACEHOLDER_NOT_REQUIRED = [
  PRODUCT_TYPE_DETAIL.GAME_DLC,
  PRODUCT_TYPE_DETAIL.CONTENT_BASIC,
  PRODUCT_TYPE_DETAIL.CONTENT_DEMO,
  PRODUCT_TYPE_DETAIL.CONTENT_DLC
];

const placeholderRender = computed(() => {
  if (PLACEHOLDER_NOT_REQUIRED.includes(currentProductDetailType.value)) {
    return 'studio.prj_prod.this_prod.edit_additional_requirements_component_min_place_holder';
  } else if (props.type === 'default') {
    if (!LABEL_REQUIRED.includes(props.setting.label)) {
      return 'studio.prj_prod.this_prod.edit_additional_requirements_component_min_place_holder';
    }
    return 'studio.prj_prod.this_prod.edit_additional_requirements_os_place_holder';
  }

  return 'studio.prj_prod.this_prod.edit_additional_requirements_component_min_place_holder';
});

const removeAdditionalSetting = () => {
  if (!props.settingId) {
    return;
  }
  emits('removeAdditionalSetting', props.settingId);
};

const getRequiredMessage = computed(() => {
  switch (props.setting.label) {
    case 'OS':
      return 'studio.prj_prod.this_prod.edit_additional_requirements_os_val_msg';
    case 'Processor':
      return 'studio.prj_prod.this_prod.edit_additional_requirements_processor_val_msg';
    case 'Memory':
      return 'studio.prj_prod.this_prod.edit_additional_requirements_memory_val_msg';
    case 'Graphic':
      return 'studio.prj_prod.this_prod.edit_additional_requirements_graphics_val_msg';
    case 'DirectX':
      return 'studio.prj_prod.this_prod.edit_additional_requirements_directx_val_msg';
    case 'Storage':
      return 'studio.prj_prod.this_prod.edit_additional_requirements_storage_val_msg';
    case 'Sound':
      return 'studio.prj_prod.this_prod.edit_additional_requirements_sound_val_msg';
    default:
      return '';
  }
});
</script>
